import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  IconButton,
  SelectField,
  CrossIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Switch,
} from 'evergreen-ui';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { useQueryBanks } from '../../services/api/banksService';
import { postEntry, useQueryEntries } from '../../services/api/entriesService';
import {
  getIntentColorByJournal,
  getNameByJournal,
} from '../../helpers/entriesHelper';
import styles from './EntryFormComponent.module.css';

import DividerComponent from '../../custom/DividerComponent';

import { toastNotification } from '../../shared/toastNotification';
import { formatWithLimitedDecimals } from '../../helpers/moneyFormatter';
import { inputTransferValidation } from '../../helpers/formHelper';
import { convertDate } from '../../helpers/dateConverter';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { useSelector } from 'react-redux';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';

function TransferFormComponent({ isShown, setIsShown, journalEntry = {} }) {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');
  const [isDisabled, setIsDisabled] = useState(true);

  /* API calls */

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      statusPayment: true,
    },
  });

  setConfiguration({
    containerWidths: '100%',
    gutterWidth: '12',
  });

  useEffect(() => {
    inputTransferValidation(
      watch('bankAccountId'),
      watch('bankAccountIdDestination'),
      watch('dateCompetencia'),
      watch('value'),
      setIsDisabled
    );
  }, [
    watch('bankAccountId'),
    watch('bankAccountIdDestination'),
    watch('dateCompetencia'),
    watch('value'),
  ]);

  const buildFormHeader = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='space-between'>
        <Button
          appearance='primary'
          intent={getIntentColorByJournal(journalEntry)}
          height={48}
          marginRight={16}
        >
          {getNameByJournal(journalEntry)}
        </Button>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane>
        <Button
          form='transfer-form'
          type='submit'
          width='fit-content'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (entry) => {
    console.log('onSubmit');
    let statusResponse = false;
    let toastContent = '';

    let entryToCreate = {
      companyId: companyId,
      journalEntryType: 5,
      dateCompetencia: entry.dateCompetencia,
      dateVencimento: entry.dateCompetencia,
      userUpdateId: !isLoadingVitaUser && vitaUser?.data?.id,
      userCreateId: !isLoadingVitaUser && vitaUser?.data?.id,
      description: entry.description,
      statusPayment: entry.statusPayment,
      paymentType: 1,
      value: Number(entry.value.replace(/\./g, '').replace(',', '.')),
      bankAccountId: entry.bankAccountId,
      bankAccountIdDestination: entry.bankAccountIdDestination,
    };

    postEntry(entryToCreate)
      .then((res) => {
        console.log('res post entry: ', res);
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
        statusResponse = 'success';
        toastContent = 'Lançamento salvo com sucesso.';
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
      });
  };

  const onClose = () => {
    reset();
    setIsShown(false);
  };

  const handleInput = (event) => {
    let inputVal = event.target.value.replace(/\D/g, '');
    if (inputVal === '') {
      setValue('value', '');
      return;
    }
    inputVal = inputVal.padStart(3, '0');
    const wholeNum = inputVal.slice(0, -2);
    const decimal = inputVal.slice(-2);
    const formattedWholeNum = Number(wholeNum).toLocaleString(['pt-BR']);
    const formattedValue = `${formattedWholeNum},${decimal}`;
    console.log('formattedValue', formattedValue); // Output the formatted value to the console

    // Convert the formatted value to a number with two decimal places
    const numericValue = Number(
      formattedValue.replace(/\./g, '').replace(',', '.').replace(' ', '')
    );
    setValue('value', formattedValue);
    console.log(numericValue); // Output the numeric value to the console
    console.log(watch('value')); // Output the numeric value to the console
  };

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={() => buildFormHeader()}
      footer={buildFormFooter}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div className={styles.dialogWrapper}>
        <form id='transfer-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField className={styles.formArea}>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('bankAccountId')}
                    label='Conta de Saída'
                    defaultValue={undefined}
                    onChange={(e) => setValue('bankAccountId', e.target.value)}
                  >
                    <option value={undefined}></option>
                    {!isLoadingBanks &&
                      banks.data.map((bank) => {
                        return (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        );
                      })}
                  </SelectField>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('bankAccountIdDestination')}
                    label='Conta de Entrada'
                    defaultValue={undefined}
                    onChange={(e) =>
                      setValue('bankAccountIdDestination', e.target.value)
                    }
                  >
                    <option value={undefined}></option>

                    {!isLoadingBanks &&
                      banks.data.map((bank) => {
                        return (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        );
                      })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('dateCompetencia')}
                    label='Data da Transferência'
                    id='dateCompetencia'
                    name='dateCompetencia'
                    type='date'
                    placeholder='--/--/--'
                    onChange={(e) => {
                      setValue('dateCompetencia', e.target.value);
                    }}
                    defaultValue={convertDate(new Date())}
                  />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('description')}
                    label='Descrição'
                    id='description'
                    name='description'
                    placeholder=''
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={10} sm={11} md={11} lg={12}>
                  <TextInputField
                    {...register('value', {
                      required: true,
                      // min: 0,
                      // valueAsNumber: true,
                    })}
                    defaultValue='0,00'
                    label='Valor (R$)'
                    type='text'
                    // min='0,00'
                    id='value'
                    name='value'
                    placeholder=''
                    // step='0.01'
                    onChange={handleInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={2} sm={1} md={1} lg={1}>
                  <Heading size={400} marginBottom={16}>
                    Pago
                  </Heading>
                  <Switch
                    {...register('statusPayment')}
                    // defaultChecked={true}
                    checked={watch('statusPayment')}
                    onChange={(e) => {
                      setValue('statusPayment', e.target.checked);
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default TransferFormComponent;
