import { React, useEffect, useState } from 'react';

import {
  Dialog,
  TextInputField,
  FormField,
  Button,
  Pane,
  Menu,
  Popover,
  ArrowTopRightIcon,
  ArrowBottomRightIcon,
  SelectField,
} from 'evergreen-ui';
import { Container, Row, Col } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { postAccountPlan } from '../../../services/api/accountPlansService';
import { useQueryDreGrouping } from '../../../services/api/dreGroupingService';
import { toastNotification } from '../../../shared/toastNotification';

import { inputAccPlanValidation } from '../../../helpers/formHelper';
import { useSelector } from 'react-redux';

function AccountPlanFormComponent({ isShown, setIsShown }) {
  const companyId = localStorage.getItem('companyId');
  const [isDisabled, setIsDisabled] = useState(true);

  /* Dialog settings  */

  /* API calls */

  const { data: dreGrouping = [], isLoading: isLoadingDreGrouping } =
    useQueryDreGrouping(
      // company.data.id,
      companyId,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    inputAccPlanValidation(
      watch('name'),
      watch('isInput'),
      watch('dreGroupingId'),
      setIsDisabled
    );
  }, [watch('isInput'), watch('name'), watch('dreGroupingId')]);

  const accountPlanFormFooter = () => {
    return (
      <Pane>
        <Button
          form='accountPlan-form'
          type='submit'
          width='fit-content'
          id='onSubmit'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (accountPlan) => {
    console.log('onSubmit');
    let statusResponse = false;
    let toastContent = '';

    let accountPlanToCreate = {
      companyId: companyId,
      isInput: accountPlan.isInput,
      name: accountPlan.name,
      dreGroupingId: accountPlan.dreGroupingId,
    };

    postAccountPlan(accountPlanToCreate)
      .then((res) => {
        console.log('res post accountPlan: ', res);
        mutate('accountPlans');
        statusResponse = 'success';
        toastContent = 'Categoria salva com sucesso.';
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };
  const onClose = () => {
    console.log('onClose function');
    reset();
    setIsShown(false);
  };

  return (
    <Dialog
      isShown={isShown}
      title='Novo Plano de Conta'
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      footer={accountPlanFormFooter}
      shouldCloseOnEscapePress={!formState.isDirty}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div>
        <form id='accountPlan-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('name')}
                    label='Plano de Conta'
                    id='name'
                    name='name'
                    type='text'
                  />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('dreGroupingId')}
                    label='Agrupamento'
                    onChange={(e) => {
                      console.log(e.target.value);
                      setValue('dreGroupingId', e.target.value);
                    }}
                  >
                    <option value=''></option>
                    {!isLoadingDreGrouping &&
                      dreGrouping.data
                        .filter((dre) => {
                          return dre.isInput === watch('isInput');
                        })
                        .map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Popover
                    content={
                      <Menu
                        {...register('isInput', {
                          value: undefined,
                        })}
                      >
                        <Menu.Group>
                          <Menu.Item
                            icon={ArrowTopRightIcon}
                            intent='selected'
                            onSelect={() => {
                              setValue('isInput', true);
                            }}
                          >
                            Entrada
                          </Menu.Item>
                          <Menu.Item
                            icon={ArrowBottomRightIcon}
                            intent='danger'
                            onSelect={() => {
                              setValue('isInput', false);
                            }}
                          >
                            Saída
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <Button marginRight={16} id='categoryType' type='button'>
                      {watch('isInput') === undefined
                        ? 'Classificação'
                        : watch('isInput')
                        ? 'Entrada'
                        : 'Saída'}
                    </Button>
                  </Popover>
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default AccountPlanFormComponent;
