import { Heading, Pane, Text } from 'evergreen-ui';
import React from 'react';
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const BanksChart = ({ banksChart, consolidated, pending }) => {
  const CustomTooltip = ({ props, payload }) => {
    return (
      <Pane
        backgroundColor='#9b9b9b'
        padding={10}
        display='flex'
        flexDirection='column'
        gap={10}
      >
        <Heading size={600} textAlign='center' fontWeight='bold'>
          {payload?.[0]?.payload?.name}
        </Heading>
        <Pane>
          <Text fontWeight='bold'>Consolidado: </Text>
          <Text fontWeight='bold' color='#028090'>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(payload[0]?.value)}
          </Text>
        </Pane>
        <Pane>
          <Text fontWeight='bold'>Pendente: </Text>
          <Text fontWeight='bold' color='#F3485B'>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(payload[1]?.value)}
          </Text>
        </Pane>
      </Pane>
    );
  };
  const renderCustomizedConsolidatedLabel = (props) => {
    return (
      <text
        {...props}
        x={props.width < 0 ? props.x + 5 : props.x + props.width + 5}
        y={props.y + props.height / 2 + 5}
        fill='#028090'
        fontSize={12}
        fontWeight={600}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(props.value)}
      </text>
    );
  };
  const renderCustomizedPendingLabel = (props) => {
    return (
      <text
        {...props}
        x={props.width < 0 ? props.x + 5 : props.x + props.width + 5}
        y={props.y + props.height / 2 + 5}
        fill='#F3485B'
        fontSize={12}
        // fontWeight={600}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(props.value)}
      </text>
    );
  };

  return (
    <ResponsiveContainer width='100%' aspect={1.3}>
      <BarChart
        layout='vertical'
        data={banksChart}
        margin={{
          top: 60,
          right: 100,
          bottom: 60,
          left: 40,
        }}
        stackOffset='wiggle'
      >
        <CartesianGrid
          stroke='#f5f5f5'
          strokeDasharray='1'
          horizontal=''
          vertical='true'
        />
        <XAxis type='number' />
        <YAxis dataKey='name' type='category' />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {consolidated && (
          <Bar
            dataKey='Consolidado'
            barSize={20}
            fill='#028090'
            animationEasing='ease-in-out'
            animationDuration={500}
          >
            <LabelList
              dataKey='Consolidado'
              formatter={(value) =>
                new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(value)
              }
              content={renderCustomizedConsolidatedLabel}
            />
          </Bar>
        )}
        {pending && (
          <Bar
            dataKey='Pendente'
            barSize={20}
            fill='#F3485B'
            animationEasing='ease-in-out'
            animationDuration={500}
          >
            <LabelList
              dataKey='Pendente'
              formatter={(value) =>
                new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(value)
              }
              content={renderCustomizedPendingLabel}
            />
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BanksChart;
