import { Button, Card, Heading, Pane } from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  signTerm,
  useQueryVitaUserByIdentityId,
} from '../../services/api/vitaUserService';
import { toastNotification } from '../../shared/toastNotification';

function TermsAndConditionsPage() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const userId = localStorage.getItem('userId');

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId && userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (vitaUser?.data?.signedTerm) {
      navigate('/empresas');
    }
  }, [vitaUser, userId]);

  const handleSignTerm = () => {
    let toastContent = '';
    let statusResponse = '';

    signTerm(userId)
      .then(() => {
        toastContent = 'Termo aceito com sucesso!';
        statusResponse = 'success';
        navigate('/empresas');
      })
      .catch((error) => {
        toastContent = error.data;
        statusResponse = 'warning';
        console.error(error);
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        setChecked(false);
      });
  };

  return (
    <Pane
      display='flex'
      alignItems='center'
      justifyContent='center'
      // flexWrap='wrap'
      gap={9}
      // height='fit-content'
      // width='fit-content'
      style={{
        background:
          'linear-gradient(257.63deg, rgba(9, 153, 157, 0.25) -0.36%, rgba(59, 128, 177, 0.25) 14.31%, rgba(0, 36, 164, 0.25) 40.45%, rgba(169, 107, 231, 0.25) 88.1%, rgba(243, 72, 91, 0.25) 120.83%)',
      }}
    >
      <Card
        elevation={2}
        backgroundColor='white'
        padding={16}
        justifyContent='center'
        margin={36}

        // maxHeight='80vh'
      >
        <Pane
          background='tint1'
          style={{
            border: '1px solid black',
            borderRadius: '5px',
            padding: '10px',
          }}
        >
          <Pane overflowY='auto' maxHeight='70vh' padding={18}>
            <Heading size={800} marginBottom={24}>
              Termos e Condições de Uso do Aplicativo VITA
            </Heading>
            <p>
              Bem-vindo ao VITA, seu sistema de gerenciamento de informações
              financeiras! Ao acessar ou usar o aplicativo VITA (o
              "Aplicativo"), você concorda em cumprir e ficar vinculado a estes
              Termos e Condições de Uso ("Termos"). Se você não concordar com
              estes Termos, por favor, não use o Aplicativo.
            </p>

            <h3>1. Uso do Aplicativo</h3>
            <p>
              1.1 O VITA é um sistema para gerenciamento de informações
              financeiras que permite que você substitua planilhas e cadernos de
              anotações. Você pode acessar seus dados online e visualizar
              históricos por meio de relatórios e Dashboards.
            </p>
            <p>
              1.2 Você pode fazer lançamentos manualmente ou importar extratos
              bancários para o Aplicativo.
            </p>
            <p>
              1.3 Durante a implantação, a empresa auxiliará você a cadastrar
              categorias e plano de contas, que serão usados para gerar
              relatórios de Demonstração do Resultado do Exercício (DRE) e
              Demonstração do Fluxo de Caixa (DFC).
            </p>

            <h3>2. Armazenamento e Segurança de Dados</h3>
            <p>
              2.1 Os dados inseridos no Aplicativo serão armazenados na nuvem em
              servidores confiáveis e protegidos pela Lei Geral de Proteção de
              Dados (LGPD).
            </p>
            <p>
              2.2 A responsabilidade pela precisão e segurança dos dados é
              inteiramente sua. O VITA não se responsabiliza por quaisquer
              perdas ou danos decorrentes de dados imprecisos ou não seguros.
            </p>

            <h3>3. Acesso ao Aplicativo</h3>
            <p>
              3.1 O acesso ao Aplicativo será protegido por login e senha
              criptografados. Você é responsável por manter a confidencialidade
              de suas credenciais de login.
            </p>

            <h3>4. Limitação de Responsabilidade</h3>
            <p>
              4.1 O Aplicativo VITA é fornecido "como está", sem garantias de
              qualquer tipo, expressas ou implícitas.
            </p>
            <p>
              4.2 Em nenhuma circunstância o VITA será responsável por quaisquer
              danos indiretos, incidentais, especiais ou consequenciais
              decorrentes do uso ou incapacidade de usar o Aplicativo.
            </p>

            <h3>5. Alterações nos Termos</h3>
            <p>
              5.1 O VITA reserva-se o direito de modificar estes Termos a
              qualquer momento. As alterações entrarão em vigor imediatamente
              após serem publicadas no Aplicativo. Ao usar o Aplicativo VITA
              após quaisquer alterações destes Termos, você concorda em ficar
              vinculado às versões atualizadas dos Termos.
            </p>
          </Pane>
          <Pane padding={26}>
            <div
              style={{
                display: 'flex',
                gap: 4,
                marginTop: 20,
                alignItems: 'center',
              }}
            >
              <input
                type='checkbox'
                id='agreeTerm'
                name='agreeTerm'
                onChange={(e) => setChecked(e.target.checked)}
                checked={checked}
              />
              <label
                style={{ fontWeight: 'bold', fontSize: '14px' }}
                htmlFor='agreeTerm'
              >
                Concordo com os termos e condições acima
              </label>
            </div>
            <Button
              appearance='primary'
              marginTop={20}
              width={120}
              disabled={!checked}
              onClick={handleSignTerm}
            >
              Continuar
            </Button>
          </Pane>
        </Pane>
      </Card>
    </Pane>
  );
}

export default TermsAndConditionsPage;
