import { CrossIcon, IconButton, Pane, SelectField } from 'evergreen-ui';
import React, { useMemo, useState } from 'react';
import { convertDate2, convertDate3 } from '../../helpers/dateConverter';
import DebouncedInput from './DebouncedInput';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { bankValueActions } from '../../store/bankValue';

const Filter = ({ column, table }) => {
  const dispatch = useDispatch();
  const banksList = useSelector((state) => state.bankValue.banksList);

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const [resetSelection, setResetSelection] = useState(false);

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues(), column, firstValue]
  );

  useEffect(() => {
    if (column.id === 'conta') {
      dispatch(bankValueActions.setSelectedIndex(banksList[columnFilterValue]));
    }
  }, [columnFilterValue]);

  return typeof firstValue === 'number' ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip title='Valor mínimo' arrow placement='bottom' enterDelay={500}>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[0] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`${
            column.getFacetedMinMaxValues()?.[0]
              ? `${column.getFacetedMinMaxValues()?.[0]}`
              : ''
          }`}
          style={{
            width: column.getSize() * 0.4,
            marginRight: '5px',
            // marginLeft: '25px',
          }}
        />
      </Tooltip>
      <Tooltip title='Valor máximo' arrow placement='bottom' enterDelay={500}>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[1] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`${
            column.getFacetedMinMaxValues()?.[1]
              ? `${column.getFacetedMinMaxValues()?.[1]}`
              : ''
          }`}
          style={{ width: column.getSize() * 0.4 }}
        />
      </Tooltip>
      {column.getFilterValue() && (
        <IconButton
          marginLeft={2}
          icon={CrossIcon}
          appearance='minimal'
          size='small'
          intent='danger'
          onClick={() => column.setFilterValue('')}
        />
      )}
    </div>
  ) : (
    <div>
      {column.id === 'vencimento' ||
      column.id === 'competencia' ||
      column.id === 'criadoEm' ||
      column.id === 'atualizadoEm' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Pane>
            <SelectField
              onChange={(e) => {
                setResetSelection(false);
                column.setFilterValue(e.target.value);
              }}
              width={
                column.id === 'vencimento' || column.id === 'competencia'
                  ? column.getSize() * 0.8
                  : column.id === 'criadoEm' || column.id === 'atualizadoEm'
                  ? column.getSize() * 0.9
                  : column.getSize() + 10
              }
              margin='auto'
              marginTop='-8px'
              style={{ background: 'white' }}
            >
              <option value='' selected={resetSelection}></option>
              {console.log(sortedUniqueValues)}
              {sortedUniqueValues.map((value) => {
                if (column.id === 'criadoEm' || column.id === 'atualizadoEm') {
                  return (
                    <option value={value} key={value}>
                      {convertDate3(value)}
                    </option>
                  );
                }
                return (
                  <option value={value} key={value}>
                    {convertDate2(value)}
                  </option>
                );
              })}
            </SelectField>
          </Pane>
          <Pane>
            {column.getFilterValue() && (
              <IconButton
                icon={CrossIcon}
                appearance='minimal'
                size='small'
                intent='danger'
                onClick={() => {
                  setResetSelection(true);
                  column.setFilterValue('');
                }}
              />
            )}
          </Pane>
        </div>
      ) : column.id === 'entryType' ||
        column.id === 'conta' ||
        column.id === 'Status' ||
        column.id === 'Conciliado' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Pane>
            <SelectField
              onChange={(e) => {
                setResetSelection(false);
                column.setFilterValue(e.target.value);
              }}
              margin='auto'
              marginTop='-8px'
              width={column.getSize() - 30}
              style={{ background: 'white' }}
            >
              <option value='' selected={resetSelection}></option>
              {sortedUniqueValues.map((value) => {
                {
                  return (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  );
                }
              })}
            </SelectField>
          </Pane>
          <Pane>
            {column.getFilterValue() && (
              <IconButton
                marginLeft={2}
                icon={CrossIcon}
                appearance='minimal'
                size='small'
                intent='danger'
                onClick={() => {
                  setResetSelection(true);
                  column.setFilterValue('');
                }}
              />
            )}
          </Pane>
        </div>
      ) : column.id === 'select' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Pane>
            <SelectField
              onChange={(e) => {
                setResetSelection(false);
                column.setFilterValue(e.target.value);
              }}
              margin='auto'
              marginTop='-8px'
              // paddingTop='4px'
              width={column.getSize() - 15}
              style={{ background: 'white' }}
            >
              <option value='' selected={resetSelection}></option>
              <option value='checked'>Selecionados</option>
              <option value='unchecked'>Não selecionados</option>
            </SelectField>
          </Pane>
          <Pane>
            {column.getFilterValue() && (
              <IconButton
                marginLeft={2}
                icon={CrossIcon}
                appearance='minimal'
                size='small'
                intent='danger'
                onClick={() => {
                  setResetSelection(true);
                  column.setFilterValue('');
                }}
              />
            )}
          </Pane>
        </div>
      ) : (
        <>
          <datalist id={column.id + 'list'}>
            {sortedUniqueValues.map((value) => {
              return <option value={value} key={value}></option>;
            })}
          </datalist>

          <DebouncedInput
            type='text'
            value={columnFilterValue ?? ''}
            onChange={(value) => {
              column.setFilterValue(value);
            }}
            placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
            style={{ width: '80%', marginLeft: '15px' }}
            list={column.id + 'list'}
          />
          {column.getFilterValue() && (
            <IconButton
              marginLeft={2}
              icon={CrossIcon}
              appearance='minimal'
              size='small'
              intent='danger'
              onClick={() => column.setFilterValue('')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Filter;
