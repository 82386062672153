import { Heading, Pane, Select } from 'evergreen-ui';
import React from 'react';
import { arrayDre } from '../../helpers/dreHelper';

const DreResultsOptions = ({ selectedDre, setSelectedDre }) => {
  return (
    <Pane
      display='flex'
      flexDirection='column'
      width='fit-content'
      marginY={10}
      marginX='auto'
      textAlign='center'
      gap={10}
    >
      <Heading color='white' size={600}>
        Resultado
      </Heading>
      <Pane>
        <Select
          value={selectedDre}
          onChange={(event) => setSelectedDre(event.target.value)}
          height={20}
          background='white'
          borderRadius={5}
        >
          {arrayDre.map((dre) => (
            <option key={dre} value={dre}>
              {dre}
            </option>
          ))}
        </Select>
      </Pane>
    </Pane>
  );
};

export default DreResultsOptions;
