import { createSlice } from '@reduxjs/toolkit';

const initialEntryValues = {
  transferEdit: {},
  transferCopy: {},
  entryEdit: {},
  entryCopy: {},
};

const entryValuesSlice = createSlice({
  name: 'entryValues',
  initialState: initialEntryValues,
  reducers: {
    setTransferEdit(state, action) {
      state.transferEdit = action.payload;
    },
    setTransferCopy(state, action) {
      state.transferCopy = action.payload;
    },
    setEntryEdit(state, action) {
      state.transferEdit = action.payload;
    },
    setEntryCopy(state, action) {
      state.entryCopy = action.payload;
    },
  },
});

export const entryValuesActions = entryValuesSlice.actions;

export default entryValuesSlice.reducer;
