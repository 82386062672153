import { React, useEffect, useState } from 'react';

import { Badge, Button, Pane, Table, TextInput, TrashIcon } from 'evergreen-ui';

import {
  deleteAccountPlan,
  useQueryAccountPlans,
} from '../../../services/api/accountPlansService';
import AccountPlanFormComponent from './AccountPlanFormComponent';
import AccountPlanEditFormComponent from './AccountPlanEditFormComponent';
import { mutate } from 'swr';
import { toastNotification } from '../../../shared/toastNotification';
import { useQueryDreGrouping } from '../../../services/api/dreGroupingService';
import LoadingOverlay from '../../../shared/LoadingOverlay';
import { useSelector } from 'react-redux';

function AccountPlansComponent() {
  const companyId = localStorage.getItem('companyId');
  /* API calls */

  const { data: accountPlans = [], isLoading: isLoadingAccountPlans } =
    useQueryAccountPlans(
      // company.data.id,
      companyId,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: dreGrouping = [], isLoading: isLoadingDreGRouping } =
    useQueryDreGrouping(
      // company.data.id,
      companyId,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const [isShownDialog, setIsShownDialog] = useState(false);
  const [isShownEditDialog, setIsShownEditDialog] = useState(false);
  const [accountPlanOnFocus, setAccountPlanOnFocus] = useState();

  const [localSearch, setLocalSearch] = useState('');

  const openDialog = () => {
    setIsShownDialog(true);
  };
  const openEditDialog = (accountPlan) => {
    setIsShownEditDialog(true);
    setAccountPlanOnFocus(accountPlan);
  };

  const onDeleteAccountPlan = (id) => {
    console.log('delete id: ', id);
    let statusResponse = false;
    let toastContent = '';
    deleteAccountPlan(id)
      .then(() => {
        mutate('accountPlans');
        statusResponse = 'success';
        toastContent = 'Plano de Conta deletado com sucesso.';
      })
      .catch(() => {
        statusResponse = 'warning';
        toastContent = `Erro. Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  useEffect(() => {
    mutate('accountPlans');
  }, []);

  if (isLoadingAccountPlans) {
    return <LoadingOverlay />;
  }

  return (
    <div>
      <AccountPlanFormComponent
        isShown={isShownDialog}
        setIsShown={setIsShownDialog}
      />
      <AccountPlanEditFormComponent
        isShown={isShownEditDialog}
        setIsShown={setIsShownEditDialog}
        accountPlan={accountPlanOnFocus}
      />
      <Pane display='flex' flexDirection='column' gap={6}>
        <Button
          appearance='primary'
          intent='info'
          marginBottom={8}
          maxWidth='fit-content'
          onClick={openDialog}
        >
          Criar Plano de Conta
        </Button>
        <TextInput
          placeholder='Filtrar'
          marginBottom={12}
          width='200px'
          onChange={(e) => setLocalSearch(e.target.value.toLowerCase())}
        />
      </Pane>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell
          // flexBasis={230}
          // flexShrink={1}
          // flexGrow={0}
          >
            Nome
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Agrupamento</Table.TextHeaderCell>
          <Table.TextHeaderCell>Tipo</Table.TextHeaderCell>
          <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {!isLoadingAccountPlans &&
            accountPlans.data
              .sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }

                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              })
              .map((accountPlan) => {
                if (accountPlan.isInput) {
                  accountPlan.entryType = 'Entrada';
                } else {
                  accountPlan.entryType = 'Saída';
                }
                if (
                  accountPlan.name.toLowerCase().includes(localSearch) ||
                  accountPlan.dreGrouping?.name
                    .toLowerCase()
                    .includes(localSearch) ||
                  accountPlan.entryType.toLowerCase().includes(localSearch)
                ) {
                  return (
                    <Table.Row
                      key={accountPlan.id}
                      isSelectable
                      onSelect={() => openEditDialog(accountPlan)}
                    >
                      <Table.TextCell
                      // flexBasis={230}
                      // flexShrink={0}
                      // flexGrow={0}
                      >
                        {accountPlan.name}
                      </Table.TextCell>

                      <Table.TextCell>
                        {accountPlan.dreGrouping?.name}
                      </Table.TextCell>
                      <Table.TextCell>
                        {accountPlan.isInput ? (
                          <Badge color='blue'>Entrada</Badge>
                        ) : (
                          <Badge color='red'>Saída</Badge>
                        )}
                      </Table.TextCell>
                      <Table.TextCell>
                        <TrashIcon
                          className='trash-icon'
                          onClick={(event) => {
                            console.log('accountPlan.id ', accountPlan.id);
                            event.stopPropagation();
                            onDeleteAccountPlan(accountPlan.id);
                          }}
                        />
                      </Table.TextCell>
                    </Table.Row>
                  );
                }
              })}
        </Table.Body>
      </Table>
    </div>
  );
}

export default AccountPlansComponent;
