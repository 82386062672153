import React from 'react';
import simboloVita from '../assets/simboloVitaResize.png';
import { Button } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        padding: '0 35px',
        gap: '40px',
      }}
    >
      <img src={simboloVita} alt='' width={250} />
      <h3>
        Página não encontrada. Verifique o endereço digitado e tente novamente.
      </h3>
      <Button onClick={() => navigate('/empresas')}>Ir para empresas</Button>
    </div>
  );
};

export default NotFoundPage;
