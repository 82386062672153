import {
  Button,
  CrossIcon,
  Dialog,
  FormField,
  Heading,
  Icon,
  IconButton,
  Pane,
  SelectField,
  ShopIcon,
  TextInputField,
} from 'evergreen-ui';
import React from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { putCompany } from '../../services/api/companiesService';
import { toastNotification } from '../../shared/toastNotification';

function EditCompanyForm({ isShown, setIsShown, companyToEdit }) {
  const { register, handleSubmit, getValues, setValue, reset } = useForm({
    defaultValues: {
      fieldActivity: companyToEdit.fieldActivity,
    },
  });

  const onClose = () => {
    reset();
    setIsShown(false);
  };

  useEffect(() => {
    reset(companyToEdit);
  }, [companyToEdit]);

  const buildFormHeader = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='space-between'>
        <Pane
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          gap={16}
        >
          <Icon icon={ShopIcon} color='selected' />
          <Heading size={700}>{`Editar ${companyToEdit.name}`}</Heading>
        </Pane>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='flex-end'>
        <Button
          form='company-form'
          type='submit'
          appearance='primary'
          marginY={8}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (company) => {
    let statusResponse = false;
    let toastContent = '';

    let companyToCreate = {
      name: company.name,
      fieldActivity: company.fieldActivity,
      id: companyToEdit.id,
    };

    console.log('submit company: ', companyToCreate);

    putCompany(companyToCreate)
      .then((res) => {
        console.log('res post user: ', res);
        statusResponse = 'success';
        toastContent = 'Empresa editada com sucesso.';
        mutate('vitaUserByIdentityId');
        mutate('company');
        localStorage.setItem('companyName', company.name);
        reset();
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={buildFormHeader}
      footer={buildFormFooter}
      width={400}
      backgroundColor='#f8f8f8'
    >
      <form id='company-form' onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <Container
            fluid={true}
            style={{
              width: '100%',
              margin: 0,
              padding: 0,
            }}
          >
            <Row>
              <Col sm={12} md={12} lg={12}>
                <TextInputField
                  padding={0}
                  {...register('name')}
                  label='Nome'
                  id='name'
                  name='name'
                  type='text'
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={12} lg={12}>
                <SelectField
                  label='Área de atuação'
                  {...register('fieldActivity')}
                  onChange={(e) => setValue('fieldActivity', e.target.value)}
                >
                  <option key={1} value=''></option>
                  <option key={2} value='Comércio'>
                    Comércio
                  </option>
                  <option key={3} value='Indústria'>
                    Indústria
                  </option>
                  <option key={4} value='Serviços'>
                    Serviços
                  </option>
                </SelectField>
              </Col>
            </Row>
          </Container>
        </FormField>
      </form>
    </Dialog>
  );
}

export default EditCompanyForm;
