import { React, useEffect, useState } from 'react';

import {
  Dialog,
  TextInputField,
  FormField,
  Button,
  Pane,
  Menu,
  Popover,
  ChevronDownIcon,
  ArrowTopRightIcon,
  ArrowBottomRightIcon,
  SelectField,
} from 'evergreen-ui';

import { Container, Row, Col } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { toastNotification } from '../../../shared/toastNotification';

import { postDreGroupingItem } from '../../../services/api/dreGroupingService';

import {
  dreClassificationInputArray,
  dreClassificationOutputArray,
  inputDreValidation,
} from '../../../helpers/formHelper';
import { useSelector } from 'react-redux';

function DreGroupingFormComponent({ isShown, setIsShown }) {
  const companyId = localStorage.getItem('companyId');
  const [isDisabled, setIsDisabled] = useState(true);

  /* Form */
  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm();

  useEffect(() => {
    inputDreValidation(
      watch('name'),
      watch('isInput'),
      watch('dreClassification'),
      setIsDisabled
    );
  }, [watch('isInput'), watch('name'), watch('dreClassification')]);

  const dreGroupingFormFooter = () => {
    return (
      <Pane>
        <Button
          form='dreGrouping-form'
          type='submit'
          width='fit-content'
          id='onSubmit'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (dreGroup) => {
    console.log('onSubmit', watch('dreClassification'));
    let statusResponse = false;
    let toastContent = '';

    let dreGroupToCreate = {
      name: dreGroup.name,
      isInput: dreGroup.isInput,
      companyId: companyId,
      dreClassification: dreGroup.dreClassification,
    };

    postDreGroupingItem(dreGroupToCreate)
      .then((res) => {
        console.log('res post category: ', res);
        mutate('dreGrouping');
        statusResponse = 'success';
        toastContent = 'Categoria salva com sucesso.';
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  const onClose = () => {
    console.log('onClose function');
    reset();
    setIsShown(false);
  };

  return (
    <Dialog
      isShown={isShown}
      title='Nova DRE'
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      footer={dreGroupingFormFooter}
      shouldCloseOnEscapePress={!formState.isDirty}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div>
        <form id='dreGrouping-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('name', { required: true })}
                    label='Agrupamento'
                    id='name'
                    name='name'
                    type='text'
                  />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('dreClassification')}
                    label='Classificação na DRE'
                    onSelect={(e) =>
                      setValue('dreClassification', e.target.value)
                    }
                    onChange={(e) =>
                      setValue('dreClassification', e.target.value)
                    }
                  >
                    {watch('isInput') === undefined ? (
                      <option value=''></option>
                    ) : watch('isInput') ? (
                      dreClassificationInputArray.map((item) => {
                        return <option value={item}>{item}</option>;
                      })
                    ) : (
                      dreClassificationOutputArray.map((item) => {
                        return <option value={item}>{item}</option>;
                      })
                    )}
                  </SelectField>
                  {/* <Combobox
                    label='Classificação'
                    openOnFocus={true}
                    {...register('dreClassification')}
                    items={
                      watch('isInput') === undefined
                        ? []
                        : watch('isInput')
                        ? dreClassificationInputArray
                        : dreClassificationOutputArray
                    }
                    onChange={(selected) => {
                      setValue('dreClassification', selected);
                    }}
                    marginBottom={24}
                    width='100%'
                  /> */}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Popover
                    content={
                      <Menu
                        {...register('isInput', {
                          value: undefined,
                        })}
                      >
                        <Menu.Group>
                          <Menu.Item
                            icon={ArrowTopRightIcon}
                            intent='selected'
                            onSelect={() => {
                              setValue('isInput', true);
                            }}
                          >
                            Entrada
                          </Menu.Item>
                          <Menu.Item
                            icon={ArrowBottomRightIcon}
                            intent='danger'
                            onSelect={() => {
                              setValue('isInput', false);
                            }}
                          >
                            Saída
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <Button
                      marginRight={15}
                      id='categoryType'
                      type='button'
                      iconAfter={ChevronDownIcon}
                    >
                      {watch('isInput') === undefined
                        ? 'Classificação'
                        : watch('isInput')
                        ? 'Entrada'
                        : 'Saída'}
                    </Button>
                  </Popover>
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default DreGroupingFormComponent;
