import useSWR from 'swr';
import request from './request';

/*
  Axios call methods
*/

export const getBanks = async (companyId) => {
  return request({
    url: `BankAccounts/Company/${companyId}`,
    method: 'GET',
  });
};

export const getBank = async (id) => {
  return request({
    url: `BankAccounts/${id}`,
    method: 'GET',
  });
};

export const getBankBalance = async (bankId) => {
  return request({
    url: `BankAccounts/${bankId}/Balance`,
    method: 'GET',
  });
};

export const getBankBalancesByCompanyId = async (companyId) => {
  return request({
    url: `BankAccounts/Company/${companyId}/BankBalances`,
    method: 'GET',
  });
};

export const postBank = async (bank) => {
  return request({
    url: `BankAccounts`,
    method: 'POST',
    data: bank,
  });
};

export const putBank = async (bank) => {
  return request({
    url: `BankAccounts/${bank.id}`,
    method: 'PUT',
    data: bank,
  });
};

export const deleteBank = async (bankId) => {
  return request({
    url: `BankAccounts?id=${bankId}`,
    method: 'DELETE',
  });
};

/*
  useQuery - SWR methods
*/

export const useQueryBanks = (companyId, options) => {
  const key = 'banks';
  const { data, error } = useSWR(
    () => key,
    companyId ? () => getBanks(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryBank = (id, options) => {
  const key = 'bank';
  const { data, error } = useSWR(
    () => key,
    id ? () => getBank(id) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryBankBalance = (bankId, options) => {
  const key = 'bankBalance';
  const { data, error } = useSWR(
    () => key,
    bankId ? () => getBankBalance(bankId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryBankBalancesByCompanyId = (
  companyId,
  options
) => {
  const key = 'bankBalances';
  const { data, error, isValidating } = useSWR(
    () => key,
    companyId
      ? () => getBankBalancesByCompanyId(companyId)
      : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isValidating,
    isError: error,
  };
};
