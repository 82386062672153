import React, { useState } from 'react';

import { mutate } from 'swr';

import { Badge, Button, Pane, Table, TextInput, TrashIcon } from 'evergreen-ui';

import {
  deleteDreGroupingItem,
  useQueryDreGrouping,
} from '../../../services/api/dreGroupingService';
import DreGroupingFormComponent from './DreGroupingFormComponent';
import DreGroupingEditFormComponent from './DreGroupingEditFormComponent';
import { toastNotification } from '../../../shared/toastNotification';
import LoadingOverlay from '../../../shared/LoadingOverlay';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function DreGroupingComponent() {
  const companyId = localStorage.getItem('companyId');
  /* API calls */

  const [isShownDialog, setIsShownDialog] = useState(false);
  const [isShownEditDialog, setIsShownEditDialog] = useState(false);
  const [dreGroupOnFocus, setDreGroupOnFocus] = useState();
  const [localSearch, setLocalSearch] = useState('');

  const { data: dreGrouping = [], isLoading: isLoadingDreGRouping } =
    useQueryDreGrouping(
      // company.data.id,
      companyId,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const openDialog = () => {
    setIsShownDialog(true);
  };
  const openEditDialog = (dreGroup) => {
    setIsShownEditDialog(true);
    setDreGroupOnFocus(dreGroup);
  };

  const onDeleteDreGroupingItem = (id) => {
    console.log('delete id: ', id);
    let statusResponse = false;
    let toastContent = '';
    deleteDreGroupingItem(id)
      .then(() => {
        mutate('dreGrouping');
        statusResponse = 'success';
        toastContent = 'DRE deletada com sucesso.';
      })
      .catch(() => {
        statusResponse = 'warning';
        toastContent = `Erro. Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  useEffect(() => {
    mutate('dreGrouping');
  }, []);

  if (isLoadingDreGRouping) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <DreGroupingFormComponent
        isShown={isShownDialog}
        setIsShown={setIsShownDialog}
      />
      <DreGroupingEditFormComponent
        isShown={isShownEditDialog}
        setIsShown={setIsShownEditDialog}
        dreGroup={dreGroupOnFocus}
      />
      <Pane display='flex' flexDirection='column' gap={6}>
        <Button
          appearance='primary'
          intent='info'
          marginBottom={8}
          maxWidth='fit-content'
          onClick={openDialog}
        >
          Criar DRE
        </Button>
        <TextInput
          placeholder='Filtrar'
          marginBottom={12}
          width='200px'
          onChange={(e) => setLocalSearch(e.target.value.toLowerCase())}
        />
      </Pane>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Nome</Table.TextHeaderCell>
          <Table.TextHeaderCell>Classificação</Table.TextHeaderCell>
          <Table.TextHeaderCell>Tipo</Table.TextHeaderCell>
          <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {!isLoadingDreGRouping &&
            dreGrouping.data
              ?.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }

                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              })
              .map((dreGroup) => {
                if (dreGroup.isInput) {
                  dreGroup.entryType = 'Entrada';
                } else {
                  dreGroup.entryType = 'Saída';
                }

                if (
                  dreGroup.name.toLowerCase().includes(localSearch) ||
                  (dreGroup.dreClassification !== null &&
                    dreGroup.dreClassification
                      .toLowerCase()
                      .includes(localSearch)) ||
                  dreGroup.entryType.toLowerCase().includes(localSearch)
                )
                  return (
                    <Table.Row
                      key={dreGroup.id}
                      isSelectable
                      onSelect={() => openEditDialog(dreGroup)}
                    >
                      <Table.TextCell>{dreGroup.name}</Table.TextCell>
                      <Table.TextCell>
                        {dreGroup.dreClassification}
                      </Table.TextCell>
                      <Table.TextCell>
                        {dreGroup.isInput ? (
                          <Badge color='blue'>Entrada</Badge>
                        ) : (
                          <Badge color='red'>Saída</Badge>
                        )}
                      </Table.TextCell>
                      <Table.TextCell
                        onClick={(event) => {
                          event.stopPropagation();
                          onDeleteDreGroupingItem(dreGroup.id);
                        }}
                      >
                        <TrashIcon className='trash-icon' />
                      </Table.TextCell>
                    </Table.Row>
                  );
              })}
        </Table.Body>
        <style scoped jsx='true'>{`
          .trash-icon:hover {
            color: red;
          }
        `}</style>
      </Table>
    </>
  );
}

export default DreGroupingComponent;
