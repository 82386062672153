import React, { useState } from 'react';
import {
  Badge,
  Button,
  Pane,
  Table,
  TextInput,
  TrashIcon,
  Heading,
  SelectField,
  SavedIcon,
  Dialog,
} from 'evergreen-ui';
import { deleteUserRoles } from '../../services/api/userRolesService';
import { mutate } from 'swr';
import { toastNotification } from '../../shared/toastNotification';
import { useSelector } from 'react-redux';

const UserRole = ({
  userRole,
  updateUser,
  removeUser,
  isShownDeleteDialog,
  setIsShownDeleteDialog,
  isShownUpdateDialog,
  setIsShownUpdateDialog,
}) => {
  const [selectedRole, setSelectedRole] = useState(userRole.userRole);

  return (
    <>
      <Dialog
        isShown={isShownDeleteDialog}
        title='Remover usuário'
        intent='danger'
        onCloseComplete={() => setIsShownDeleteDialog(false)}
        confirmLabel='Deletar'
        cancelLabel='Cancelar'
        onConfirm={() => removeUser(userRole)}
        preventBodyScrolling
      >
        Tem certeza que deseja remover este usuário da empresa?
      </Dialog>
      <Dialog
        isShown={isShownUpdateDialog}
        title='Alterar acesso'
        intent='danger'
        onCloseComplete={() => {
          setIsShownUpdateDialog(false);
          mutate('userRolesCurrentRole');
          mutate('userRolesWithUserId');
        }}
        confirmLabel='Alterar'
        cancelLabel='Cancelar'
        onConfirm={() => updateUser({ ...userRole, role: selectedRole })}
        preventBodyScrolling
      >
        Tem certeza que deseja alterar o nível de acesso deste usuário?
        <Pane marginTop={26}>
          <Heading marginY={16}>{`Acesso atual: ${userRole.userRole}`}</Heading>
          <Heading>{`Alterar para: ${selectedRole}`}</Heading>
        </Pane>
      </Dialog>
      <Table.Row>
        <Table.TextCell>{userRole.name}</Table.TextCell>
        <Table.TextCell>{userRole.lastName}</Table.TextCell>
        <Table.TextCell>{userRole.email}</Table.TextCell>
        <Table.TextCell>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <SelectField
              label=''
              value={userRole.userRole}
              onChange={(e) => {
                if (userRole.userRole !== e.target.value) {
                  setSelectedRole(e.target.value);
                  setIsShownUpdateDialog(true);
                }
              }}
              width='50%'
            >
              <option value='ADMIN'>ADMIN</option>
              <option value='ANALISTA'>ANALISTA</option>
              <option value='CONSULTOR'>CONSULTOR</option>
              <option value='LANÇADOR'>LANÇADOR</option>
            </SelectField>
          </div>
        </Table.TextCell>
        <Table.TextCell>
          <TrashIcon
            className='trash-icon'
            size={20}
            padding={2}
            marginRight={2}
            onClick={() => setIsShownDeleteDialog(true)}
          />
        </Table.TextCell>
      </Table.Row>
      <style scoped jsx='true'>{`
        .trash-icon:hover {
          color: red;
          cursor: pointer;
          transform: scale(1.2); /* Add this line to make it bigger */
        }

        .save-icon:hover {
          color: green;
          cursor: pointer;
          transform: scale(1.2); /* Add this line to make it bigger */
        }
      `}</style>
    </>
  );
};

export default UserRole;
