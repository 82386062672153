import {
  Avatar,
  Button,
  ChevronDownIcon,
  CogIcon,
  EditIcon,
  Heading,
  LogOutIcon,
  Menu,
  MugshotIcon,
  Pane,
  Popover,
  Position,
  ProjectsIcon,
} from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import { useQueryCompany } from '../../services/api/companiesService';
import DrawerComponent from './DrawerComponent';
import HamburgerComponent from './HamburgerComponent';
import { useSWRConfig } from 'swr';
import EditCompanyForm from './EditCompanyForm';
import { useDispatch, useSelector } from 'react-redux';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { useQueryUserRolesCurrentRole } from '../../services/api/userRolesService';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { toastNotification } from '../../shared/toastNotification';

function Navbar() {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');
  const companyName = localStorage.getItem('companyName');
  const [isShown, setIsShown] = useState(false);
  const [isShownDialog, setIsShownDialog] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState({});

  const navigate = useNavigate();

  const { mutate } = useSWRConfig();

  const dispatch = useDispatch();

  useEffect(() => {
    mutate('userRolesCurrentRole');
    mutate('companies');
  }, [companyId, userId]);

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    data: company,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
  } = useQueryCompany(companyId, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  const {
    data: currentRole,
    isLoading: isLoadingCurrentRole,
    isValidating: isValidatingCurrentRole,
  } = useQueryUserRolesCurrentRole(
    { companyId: companyId, vitaUserId: userId },
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const openDialog = (companyToEdit) => {
    setIsShownDialog(true);
    setCompanyToEdit(companyToEdit);
  };

  const logoutHandler = () => {
    let statusResponse = false;
    let toastContent = '';

    mutate(
      (key) => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: false } // do not revalidate
    );
    dispatch(reconciliationValuesActions.resetFilter());
    signOut(auth)
      .then(() => {
        localStorage.clear();
        toastContent = 'Logout efetuado com sucesso.';
        statusResponse = 'success';
        toastNotification(statusResponse, toastContent);
        // Sign-out successful.
      })
      .catch((error) => {
        console.log('error', error);
        // An error happened.
      });
  };

  if (isLoadingVitaUser || isLoadingCompany || isLoadingCurrentRole) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Pane
        minHeight={64}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        paddingY={12}
        paddingX={15}
        width='100%'
        borderBottom='1px solid #8591bc40'
      >
        <EditCompanyForm
          isShown={isShownDialog}
          setIsShown={setIsShownDialog}
          companyToEdit={companyToEdit}
        />
        <HamburgerComponent setIsShown={setIsShown} />
        {!isLoadingCompany && (
          <Pane style={{ textAlign: 'center' }}>
            <Popover
              position={Position.BOTTOM}
              animationDuration={500}
              content={({ close }) => (
                <Menu>
                  {!isLoadingCurrentRole &&
                    !isValidatingCurrentRole &&
                    currentRole?.data?.userRole !==
                      ('LANÇADOR' || 'ANALISTA') && (
                      <>
                        <Menu.Group>
                          <Menu.Item
                            icon={EditIcon}
                            intent='red700'
                            onClick={() => {
                              openDialog(company.data);
                              close();
                            }}
                          >
                            {`Editar ${companyName}`}
                          </Menu.Item>
                        </Menu.Group>
                        <Menu.Divider />
                      </>
                    )}
                  <Menu.Group>
                    <Menu.Item
                      icon={ProjectsIcon}
                      intent='selected'
                      onClick={() => {
                        navigate('/empresas');
                        close();
                      }}
                    >
                      Gerenciar Empresas
                    </Menu.Item>
                  </Menu.Group>
                  {!isLoadingCurrentRole &&
                    !isValidatingCurrentRole &&
                    currentRole?.data?.userRole !==
                      ('LANÇADOR' || 'ANALISTA') && (
                      <>
                        <Menu.Divider />
                        <Menu.Group>
                          <Menu.Item
                            icon={CogIcon}
                            intent='green600'
                            onClick={() => {
                              navigate('/minha-empresa');
                              close();
                            }}
                          >
                            Menu da Empresa
                          </Menu.Item>
                        </Menu.Group>
                      </>
                    )}
                </Menu>
              )}
            >
              <Button
                display='flex'
                alignItems='center'
                textAlign='center'
                color='darkgray'
                gap={10}
                cursor='pointer'
                appearance='minimal'
                size='large'
              >
                <ChevronDownIcon size={16} />
                <Heading size={700} color='#474d66'>
                  {companyName}
                </Heading>
              </Button>
            </Popover>
          </Pane>
        )}
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Menu.Group>
                <Menu.Item
                  icon={MugshotIcon}
                  intent='selected'
                  onClick={() => navigate('/minha-conta')}
                >
                  Minha Conta
                </Menu.Item>
                <Menu.Item
                  icon={LogOutIcon}
                  intent='danger'
                  onClick={logoutHandler}
                >
                  Logout
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <Pane display='flex' alignItems='center' color='darkgray' gap={10}>
            <Pane display='flex' alignItems='center' gap={10} cursor='pointer'>
              <ChevronDownIcon size={16} />
              <Heading>{!isLoadingVitaUser && vitaUser?.data.name}</Heading>
            </Pane>
            <Avatar
              name={!isLoadingVitaUser && vitaUser?.data.name}
              size={40}
            />
          </Pane>
        </Popover>
      </Pane>
      <DrawerComponent isShown={isShown} setIsShown={setIsShown} />
    </>
  );
}

export default Navbar;
