import React from 'react';
import HeaderSignComponent from './HeaderSignComponent';
import ResetPasswordCard from './ResetPasswordCard';

const ResetPasswordPage = () => {
  return (
    <div className='container'>
      <HeaderSignComponent />
      <ResetPasswordCard />
      <style jsx='true'>
        {`
          .container {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        `}
      </style>
    </div>
  );
};

export default ResetPasswordPage;
