import { Overlay, Pane, Spinner } from "evergreen-ui";
import React from "react";

function LoadingOverlay() {
  return (
    <Overlay isShown={true}>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Spinner />
      </Pane>
    </Overlay>
  );
}

export default LoadingOverlay;
