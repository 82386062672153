import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Button,
  Card,
  Checkbox,
  ChevronLeftIcon,
  ChevronRightIcon,
  Heading,
  IconButton,
  Menu,
  Pane,
  Popover,
  Position,
  RadioGroup,
  StatusIndicator,
} from 'evergreen-ui';
import { firstDay, lastDay } from '../../helpers/dateConverter';
import { useDispatch, useSelector } from 'react-redux';
import { dateFilterActions } from '../../store/dateFilter';
import { mutate } from 'swr';
import { dreValuesActions } from '../../store/dreValues';
import { monthsArray } from '../../helpers/dreHelper';

function DreFilterBar({ tableProps, entries }) {
  const companyName = localStorage.getItem('companyName');
  const dispatch = useDispatch();

  const realValuesCheckbox = useSelector(
    (state) => state.dreValues.realValuesCheckbox
  );
  const percentValuesCheckbox = useSelector(
    (state) => state.dreValues.percentValuesCheckbox
  );
  const entryStatus = useSelector((state) => state.dreValues.entryStatus);
  const filterType = useSelector((state) => state.dreValues.filterType);

  const dreYear = useSelector((state) => state.dateFilter.dreYear);

  const [allMonths, setAllMonths] = useState(true);
  const [janeiro, setJaneiro] = useState(true);
  const [fevereiro, setFevereiro] = useState(true);
  const [marco, setMarco] = useState(true);
  const [abril, setAbril] = useState(true);
  const [maio, setMaio] = useState(true);
  const [junho, setJunho] = useState(true);
  const [julho, setJulho] = useState(true);
  const [agosto, setAgosto] = useState(true);
  const [setembro, setSetembro] = useState(true);
  const [outubro, setOutubro] = useState(true);
  const [novembro, setNovembro] = useState(true);
  const [dezembro, setDezembro] = useState(true);

  const nextYear = () => {
    let tempYear = dreYear;
    tempYear++;
    dispatch(dateFilterActions.setDreStartDate(firstDay(0, tempYear)));
    dispatch(dateFilterActions.setDreEndDate(lastDay(11, tempYear)));
    dispatch(dateFilterActions.increaseDreYear());
  };
  const previousYear = () => {
    let tempYear = dreYear;
    tempYear--;
    dispatch(dateFilterActions.setDreStartDate(firstDay(0, tempYear)));
    dispatch(dateFilterActions.setDreEndDate(lastDay(11, tempYear)));
    dispatch(dateFilterActions.decreaseDreYear());
  };

  // função chamada ao clicar em um seletor de mês
  const handleCheckMonth = (month) => {
    // verifica qual mês foi clicado
    switch (month) {
      case 'janeiro':
        if (!janeiro) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);
            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setJaneiro(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setJaneiro(false);
          // handleMonthsArray();
          break;
        }
      case 'fevereiro':
        if (!fevereiro) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setFevereiro(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setFevereiro(false);
          // handleMonthsArray();
          break;
        }
      case 'marco':
        if (!marco) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setMarco(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setMarco(false);
          // handleMonthsArray();
          break;
        }
      case 'abril':
        if (!abril) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setAbril(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setAbril(false);
          // handleMonthsArray();
          break;
        }
      case 'maio':
        if (!maio) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setMaio(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setMaio(false);
          // handleMonthsArray();
          break;
        }
      case 'junho':
        if (!junho) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setJunho(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setJunho(false);
          // handleMonthsArray();
          break;
        }
      case 'julho':
        if (!julho) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setJulho(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setJulho(false);
          // handleMonthsArray();
          break;
        }
      case 'agosto':
        if (!agosto) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setAgosto(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setAgosto(false);
          // handleMonthsArray();
          break;
        }
      case 'setembro':
        if (!setembro) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setSetembro(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setSetembro(false);
          // handleMonthsArray();
          break;
        }
      case 'outubro':
        if (!outubro) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setOutubro(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setOutubro(false);
          // handleMonthsArray();
          break;
        }
      case 'novembro':
        if (!novembro) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setNovembro(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setNovembro(false);
          // handleMonthsArray();
          break;
        }
      case 'dezembro':
        if (!dezembro) {
          // se nenhum valor estiver marcado, nenhuma coluna deve ser mostrada, independente dos meses marcados
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            // se somente 'valores reais' estiver marcado, ativar apenas a coluna de meses reais do mes selecionado
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            tableProps.getColumn(month).toggleVisibility(true);

            // se somente 'valores percentuais' estiver marcado, ativar apenas a coluna de meses percentuais do mes selecionado
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          } else {
            tableProps.getColumn(month).toggleVisibility(true);
            tableProps.getColumn(`${month}Percent`).toggleVisibility(true);
          }
          setDezembro(true);
          // handleMonthsArray();
          break;
        } else {
          tableProps.getAllLeafColumns().forEach((column) => {
            if (column.id === month || column.id === `${month}Percent`) {
              column.toggleVisibility(false);
            }
          });
          setDezembro(false);
          // handleMonthsArray();
          break;
        }
      case 'todos':
        if (allMonths) {
          monthsArray.forEach((item) => {
            tableProps.getColumn(item).toggleVisibility(false);
            tableProps.getColumn(`${item}Percent`).toggleVisibility(false);
          });
          setJaneiro(false);
          setFevereiro(false);
          setMarco(false);
          setAbril(false);
          setMaio(false);
          setJunho(false);
          setJulho(false);
          setAgosto(false);
          setSetembro(false);
          setOutubro(false);
          setNovembro(false);
          setDezembro(false);
          setAllMonths(false);
          // handleMonthsArray();
        } else {
          if (!realValuesCheckbox && !percentValuesCheckbox) {
            console.log('nenhum filtro de valores');
          } else if (realValuesCheckbox && !percentValuesCheckbox) {
            monthsArray.forEach((item) => {
              tableProps.getColumn(item).toggleVisibility(true);
            });
          } else if (!realValuesCheckbox && percentValuesCheckbox) {
            monthsArray.forEach((item) => {
              tableProps.getColumn(`${item}Percent`).toggleVisibility(true);
            });
          } else {
            monthsArray.forEach((item) => {
              tableProps.getColumn(item).toggleVisibility(true);
              tableProps.getColumn(`${item}Percent`).toggleVisibility(true);
            });
          }
          setJaneiro(true);
          setFevereiro(true);
          setMarco(true);
          setAbril(true);
          setMaio(true);
          setJunho(true);
          setJulho(true);
          setAgosto(true);
          setSetembro(true);
          setOutubro(true);
          setNovembro(true);
          setDezembro(true);
          setAllMonths(true);
          // handleMonthsArray();
        }
        break;
    }
  };

  const handleRealValues = (checked) => {
    if (checked === false) {
      monthsArray.forEach((month) => {
        tableProps.getColumn(month).toggleVisibility(false);
      });
      dispatch(dreValuesActions.setRealValuesCheckbox(false));
    } else {
      if (janeiro) {
        tableProps.getColumn('janeiro').toggleVisibility(true);
      }
      if (fevereiro) {
        tableProps.getColumn('fevereiro').toggleVisibility(true);
      }
      if (marco) {
        tableProps.getColumn('marco').toggleVisibility(true);
      }
      if (abril) {
        tableProps.getColumn('abril').toggleVisibility(true);
      }
      if (maio) {
        tableProps.getColumn('maio').toggleVisibility(true);
      }
      if (junho) {
        tableProps.getColumn('junho').toggleVisibility(true);
      }
      if (julho) {
        tableProps.getColumn('julho').toggleVisibility(true);
      }
      if (agosto) {
        tableProps.getColumn('agosto').toggleVisibility(true);
      }
      if (setembro) {
        tableProps.getColumn('setembro').toggleVisibility(true);
      }
      if (outubro) {
        tableProps.getColumn('outubro').toggleVisibility(true);
      }
      if (novembro) {
        tableProps.getColumn('novembro').toggleVisibility(true);
      }
      if (dezembro) {
        tableProps.getColumn('dezembro').toggleVisibility(true);
      }
      dispatch(dreValuesActions.setRealValuesCheckbox(true));
    }
  };
  const handlePercentValues = (checked) => {
    // console.log(checked);
    if (checked === false) {
      monthsArray.forEach((month) => {
        tableProps.getColumn(`${month}Percent`).toggleVisibility(false);
      });
      dispatch(dreValuesActions.setPercentValuesCheckbox(false));
    } else {
      if (janeiro) {
        tableProps.getColumn('janeiroPercent').toggleVisibility(true);
      }
      if (fevereiro) {
        tableProps.getColumn('fevereiroPercent').toggleVisibility(true);
      }
      if (marco) {
        tableProps.getColumn('marcoPercent').toggleVisibility(true);
      }
      if (abril) {
        tableProps.getColumn('abrilPercent').toggleVisibility(true);
      }
      if (maio) {
        tableProps.getColumn('maioPercent').toggleVisibility(true);
      }
      if (junho) {
        tableProps.getColumn('junhoPercent').toggleVisibility(true);
      }
      if (julho) {
        tableProps.getColumn('julhoPercent').toggleVisibility(true);
      }
      if (agosto) {
        tableProps.getColumn('agostoPercent').toggleVisibility(true);
      }
      if (setembro) {
        tableProps.getColumn('setembroPercent').toggleVisibility(true);
      }
      if (outubro) {
        tableProps.getColumn('outubroPercent').toggleVisibility(true);
      }
      if (novembro) {
        tableProps.getColumn('novembroPercent').toggleVisibility(true);
      }
      if (dezembro) {
        tableProps.getColumn('dezembroPercent').toggleVisibility(true);
      }
      dispatch(dreValuesActions.setPercentValuesCheckbox(true));
    }
  };

  const handleAllChecked = () => {
    if (
      !janeiro ||
      !fevereiro ||
      !marco ||
      !abril ||
      !maio ||
      !junho ||
      !julho ||
      !agosto ||
      !setembro ||
      !outubro ||
      !novembro ||
      !dezembro
    ) {
      setAllMonths(false);
    } else if (
      janeiro &&
      fevereiro &&
      marco &&
      abril &&
      maio &&
      junho &&
      julho &&
      agosto &&
      setembro &&
      outubro &&
      novembro &&
      dezembro
    ) {
      setAllMonths(true);
    }
  };

  const handleMonthsArray = () => {
    const array = [];
    !janeiro && array.push('janeiro');
    !fevereiro && array.push('fevereiro');
    !marco && array.push('marco');
    !abril && array.push('abril');
    !maio && array.push('maio');
    !junho && array.push('junho');
    !julho && array.push('julho');
    !agosto && array.push('agosto');
    !setembro && array.push('setembro');
    !outubro && array.push('outubro');
    !novembro && array.push('novembro');
    !dezembro && array.push('dezembro');
    // console.log('Desmarcados: ', array);
    dispatch(dreValuesActions.setUncheckedMonths(array));
  };

  const handleExport = () => {
    const entriesToExport = [];
    entries.map((entry) => {
      entriesToExport.push({
        DRE: entry.description,
        // Competência: new Date(entry.dateCompetencia),
        // Vencimento: new Date(entry.dateVencimento),
        Janeiro: entry.janeiro,
        'Janeiro %': entry.janeiroPercent,
        Fevereiro: entry.fevereiro,
        'Fevereiro %': entry.fevereiroPercent,
        Março: entry.marco,
        'Março %': entry.marcoPercent,
        Abril: entry.abril,
        'Abril %': entry.abrilPercent,
        Maio: entry.maio,
        'Maio %': entry.maioPercent,
        Junho: entry.junho,
        'Junho %': entry.junhoPercent,
        Julho: entry.julho,
        'Julho %': entry.julhoPercent,
        Agosto: entry.agosto,
        'Agosto %': entry.agostoPercent,
        Setembro: entry.setembro,
        'Setembro %': entry.setembroPercent,
        Outubro: entry.outubro,
        'Outubro %': entry.outubroPercent,
        Novembro: entry.novembro,
        'Novembro %': entry.novembroPercent,
        Dezembro: entry.dezembro,
        'Dezembro %': entry.dezembroPercent,
        Média: entry.media,
        'Média %': entry.mediaPercent,
        Acumulado: entry.acumulado,
      });
    });
    console.log('export', entriesToExport);
    console.log('entries', entries);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert the JSON data to a worksheet
    const ws = XLSX.utils.json_to_sheet(entriesToExport);
    // XLSX.utils.sheet_add_aoa(
    //   ws,
    //   [
    //     [
    //       'Classificação na DRE',
    //       'Agrupamento',
    //       'Plano de Conta',
    //     ],
    //   ],
    //   {
    //     origin: 'G1',
    //   }
    // );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'DRE');

    // Generate a download link and click it
    XLSX.writeFile(wb, `DRE_${companyName}_${dreYear}.xlsx`, {
      cellDates: true,
    });
  };

  useEffect(() => {
    handleAllChecked();
    handleMonthsArray();
    handleRealValues(realValuesCheckbox);
    handlePercentValues(percentValuesCheckbox);
  }, [
    janeiro,
    fevereiro,
    marco,
    abril,
    maio,
    junho,
    julho,
    agosto,
    setembro,
    outubro,
    novembro,
    dezembro,
    allMonths,
    realValuesCheckbox,
    percentValuesCheckbox,
  ]);

  return (
    <Card
      className='cardApiFilter'
      minHeight={50}
      display='flex'
      position='relative'
      alignItems='center'
      gap={6}
      padding={6}
      flexDirection='row'
      flexWrap='wrap'
      width='100vw'
      backgroundColor='#f4f5f9'
      justifyContent='space-evenly'
      margin='auto'
    >
      <Pane
        display='flex'
        alignItems='center'
        justifySelf='center'
        justifyContent='center'
        width='fit-content'
      >
        <IconButton
          icon={ChevronLeftIcon}
          appearance='minimal'
          onClick={previousYear}
          size='large'
        />

        <Heading>{dreYear}</Heading>

        <IconButton
          icon={ChevronRightIcon}
          appearance='minimal'
          onClick={nextYear}
          size='large'
        />
      </Pane>
      <Pane>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Pane paddingX={15} overflowY='auto' maxHeight={400}>
                <Checkbox
                  type='checkbox'
                  key='mostrar-tudo'
                  label='Mostrar tudo'
                  checked={allMonths}
                  onChange={() => handleCheckMonth('todos')}
                />
                <Menu.Divider />
                <Checkbox
                  type='checkbox'
                  label='Janeiro'
                  checked={janeiro}
                  onChange={() => handleCheckMonth('janeiro')}
                />
                <Checkbox
                  type='checkbox'
                  label='Fevereiro'
                  checked={fevereiro}
                  onChange={() => handleCheckMonth('fevereiro')}
                />
                <Checkbox
                  type='checkbox'
                  label='Marco'
                  checked={marco}
                  onChange={() => handleCheckMonth('marco')}
                />
                <Checkbox
                  type='checkbox'
                  label='Abril'
                  checked={abril}
                  onChange={() => handleCheckMonth('abril')}
                />
                <Checkbox
                  type='checkbox'
                  label='Maio'
                  checked={maio}
                  onChange={() => handleCheckMonth('maio')}
                />
                <Checkbox
                  type='checkbox'
                  label='Junho'
                  checked={junho}
                  onChange={() => handleCheckMonth('junho')}
                />
                <Checkbox
                  type='checkbox'
                  label='Julho'
                  checked={julho}
                  onChange={() => handleCheckMonth('julho')}
                />
                <Checkbox
                  type='checkbox'
                  label='Agosto'
                  checked={agosto}
                  onChange={() => handleCheckMonth('agosto')}
                />
                <Checkbox
                  type='checkbox'
                  label='Setembro'
                  checked={setembro}
                  onChange={() => handleCheckMonth('setembro')}
                />
                <Checkbox
                  type='checkbox'
                  label='Outubro'
                  checked={outubro}
                  onChange={() => handleCheckMonth('outubro')}
                />
                <Checkbox
                  type='checkbox'
                  label='Novembro'
                  checked={novembro}
                  onChange={() => handleCheckMonth('novembro')}
                />
                <Checkbox
                  type='checkbox'
                  label='Dezembro'
                  checked={dezembro}
                  onChange={() => handleCheckMonth('dezembro')}
                />
              </Pane>
            </Menu>
          }
        >
          <Button className='filterButton' flexShrink='inherit'>
            Meses
            {!allMonths && (
              <StatusIndicator
                color='warning'
                marginLeft={8}
                marginBottom={6}
                marginRight='-8px'
                padding={0}
              />
            )}
          </Button>
        </Popover>
      </Pane>
      <Pane>
        <Checkbox
          type='checkbox'
          label='Valores reais'
          checked={realValuesCheckbox}
          value='real'
          onChange={(e) => handleRealValues(e.target.checked)}
        />
        <Checkbox
          type='checkbox'
          label='Valores percentuais'
          checked={percentValuesCheckbox}
          value='percent'
          onChange={(e) => handlePercentValues(e.target.checked)}
        />
      </Pane>

      <Pane paddingLeft={16} display='flex' flexDirection='row'>
        <RadioGroup
          value={entryStatus}
          size={12}
          options={[
            {
              label: 'Todos',
              value: 'Todos',
            },
            {
              label: 'Pagos',
              value: 'Pago',
            },
            {
              label: 'Pendentes',
              value: 'Pendente',
            },
          ]}
          onChange={(e) => {
            dispatch(dreValuesActions.setEntryStatus(e.target.value));
            // console.log(e.target.value);
            // mutate('entriesFilteredByDate');
          }}
        />
      </Pane>
      <Pane
        display='flex'
        alignItems='center'
        flexWrap='wrap'
        justifyContent='center'
      >
        <Pane paddingLeft={16}>
          <RadioGroup
            value={filterType}
            options={[
              {
                label: 'Competência',
                value: 'dateCompetencia',
              },
              {
                label: 'Vencimento',
                value: 'dateVencimento',
              },
            ]}
            onChange={(e) => {
              dispatch(dreValuesActions.setFilterType(e.target.value));
              // mutate('entriesFilteredByDate');
            }}
          />
        </Pane>
      </Pane>
      <Pane>
        <Button
          appearance='primary'
          intent='success'
          // isLoading={isLoadingJournalEntriesFilteredByDate}
          onClick={handleExport}
        >
          Exportar
        </Button>
      </Pane>
    </Card>
  );
}
export default DreFilterBar;
