import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { contrastedColors, contrastedColors2 } from './dashboardHelper';
import { Heading, Pane, Text } from 'evergreen-ui';

const PieCharts = ({ pieChart, totalValue }) => {
  const CustomTooltip = ({ props, payload }) => {
    return (
      <Pane
        backgroundColor='#9b9b9b'
        padding={10}
        display='flex'
        flexDirection='column'
        gap={10}
      >
        <Heading fontWeight='bold'>{payload?.[0]?.payload?.name}</Heading>
        <Pane>
          <Text fontWeight='bold' color='#028090'>
            {`${new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(payload[0]?.value)} `}
          </Text>
          <Text fontWeight='bold'>{`(${(
            (payload[0]?.value / totalValue) *
            100
          ).toFixed(2)}%)`}</Text>
        </Pane>
        <Text fontWeight='bold'>
          {payload?.[0]?.payload?.entriesCount} lançamentos
        </Text>
      </Pane>
    );
  };
  return (
    <ResponsiveContainer width='100%'>
      <PieChart>
        <Pie
          data={pieChart}
          cx='50%'
          cy='50%'
          labelLine={true}
          label={(props) => {
            return (
              <text {...props} fontSize={12}>
                {`${new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(props.value)} (${(props.percent * 100).toFixed(2)}%)`}
              </text>
            );
          }}
          outerRadius='68%'
          dataKey='value'
          minAngle={3}
          // paddingAngle={8}
        >
          {pieChart.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={contrastedColors2[index]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieCharts;
