import { createSlice } from '@reduxjs/toolkit';
import { firstDay, lastDay } from '../helpers/dateConverter';

const initialReconciliationValuesState = {
  filterType: 'dateVencimento',
  startReconciliationDate: '',
  endReconciliationDate: '',
  entryList: [],
  statementList: [],
  entryReconciliatedList: [],
  statementReconciliatedList: [],
  myStatementList: [],
  currentStatement: null,
  selectedBank: null,
  selectedFilter: 'entrada',
  grossValue: '',
  showAllCheckbox: false,
  uploadedStatement: null,
};

const reconciliationValuesSlice = createSlice({
  name: 'reconciliationValues',
  initialState: initialReconciliationValuesState,
  reducers: {
    setReconciliationStartDate(state, action) {
      state.startReconciliationDate = action.payload;
    },
    setReconciliationEndDate(state, action) {
      state.endReconciliationDate = action.payload;
    },
    setEntryList(state, action) {
      state.entryList = action.payload;
    },
    setEntryReconciliatedList(state, action) {
      state.entryReconciliatedList = action.payload;
    },
    setStatementReconciliatedList(state, action) {
      state.statementReconciliatedList = action.payload;
    },
    setStatementList(state, action) {
      state.statementList = action.payload;
    },
    setMyStatementList(state, action) {
      state.myStatementList = action.payload;
    },
    setCurrentStatement(state, action) {
      state.currentStatement = action.payload;
    },
    setSelectedBank(state, action) {
      state.selectedBank = action.payload;
    },
    setSelectedFilter(state, action) {
      state.selectedFilter = action.payload;
    },
    setGrossValue(state, action) {
      state.grossValue = action.payload;
    },
    setShowAllCheckbox(state, action) {
      state.showAllCheckbox = action.payload;
    },
    setUploadedStatement(state, action) {
      state.uploadedStatement = action.payload;
    },
    resetFilter(state) {
      state.startReconciliationDate = '';
      state.endReconciliationDate = '';
      state.filterType = 'dateVencimento';
      state.entryList = [];
      state.statementList = [];
      state.entryReconciliatedList = [];
      state.statementReconciliatedList = [];
      state.currentStatement = null;
      state.selectedBank = null;
      state.selectedFilter = 'entrada';
      state.grossValue = '';
      state.showAllCheckbox = false;
      state.uploadedStatement = null;
    },
  },
});

export const reconciliationValuesActions = reconciliationValuesSlice.actions;

export default reconciliationValuesSlice.reducer;
