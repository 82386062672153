import React from 'react';
import {
  Avatar,
  Button,
  Card,
  Heading,
  Pane,
  Table,
  TextInput,
} from 'evergreen-ui';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { useState } from 'react';
import EditUserForm from './EditUserForm';
import { useQueryCompaniesByUserRoles } from '../../services/api/userRolesService';
import CompanyByUser from './CompanyByUser';

const MyAccountPage = () => {
  const userId = localStorage.getItem('userId');

  const [isShownDialog, setIsShownDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [localSearch, setLocalSearch] = useState('');

  const openDialog = (user) => {
    setIsShownDialog(true);
    setCurrentUser(user);
  };

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isErrorCompanies,
  } = useQueryCompaniesByUserRoles(userId, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  if (isLoadingVitaUser) {
    return <LoadingOverlay />;
  }
  return (
    <Pane
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      overflowY='auto'
      padding={16}
    >
      <EditUserForm
        isShown={isShownDialog}
        setIsShown={setIsShownDialog}
        user={currentUser}
      />
      <Card
        // className={styles.accountCard}
        display='flex'
        flexDirection='column'
        elevation={2}
        backgroundColor='white'
        margin={12}
        padding={16}
        width='300px'
        height='150px'
        gap={26}
        cursor='pointer'
        onClick={() => openDialog(vitaUser.data)}
        // flexGrow={1}
        // justifyContent='center'
      >
        <Pane>
          <Heading color='gray' fontWeight='lighter'>
            Usuário
          </Heading>
        </Pane>
        <Pane display='flex' gap={16}>
          <Avatar size={50} name={`${vitaUser.data.name}`} />
          <Pane display='flex' flexDirection='column'>
            <Heading
              size={700}
            >{`${vitaUser.data.name} ${vitaUser.data.lastName}`}</Heading>
            <Heading color='gray' fontWeight='lighter'>
              {vitaUser.data.email}
            </Heading>
          </Pane>
        </Pane>
      </Card>
      <Pane
        display='flex'
        flexDirection='column'
        background='tint1'
        padding={16}
        borderRadius={5}
        marginTop={24}
        width='100%'
        height='fit-content'
      >
        {/* <Heading alignSelf='center' size={700} margin={12}>
        Lista de Usuários
      </Heading> */}
        <TextInput
          placeholder='Filtrar'
          marginBottom={12}
          width='200px'
          onChange={(e) => setLocalSearch(e.target.value.toLowerCase())}
        />
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>Nome</Table.TextHeaderCell>
            <Table.TextHeaderCell>Nível de Acesso</Table.TextHeaderCell>
            <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body maxHeight={250}>
            {!isLoadingCompanies && console.log('companies: ', companies)}
            {!isLoadingCompanies &&
              companies.data
                ?.sort((a, b) => {
                  const nameA = a.companyName.toUpperCase();
                  const nameB = b.companyName.toUpperCase();

                  if (nameA < nameB) {
                    return -1;
                  }

                  if (nameA > nameB) {
                    return 1;
                  }

                  return 0;
                })
                .map((company) => {
                  if (
                    company.companyName.toLowerCase().includes(localSearch) ||
                    company.userRole.toLowerCase().includes(localSearch)
                  )
                    return (
                      <CompanyByUser
                        companyByUser={company}
                        key={company.companyId}
                      />
                    );
                })}
          </Table.Body>
        </Table>
      </Pane>
    </Pane>
  );
};

export default MyAccountPage;
