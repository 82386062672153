import React, { useState } from 'react';
import { Pane, Tab, Tablist } from 'evergreen-ui';

import LoadingOverlay from '../../shared/LoadingOverlay';

import {
  useQueryUserRoles,
  useQueryUserRolesWithUserId,
} from '../../services/api/userRolesService';

import UserRoleList from './UserRoleList';
import CreateEmployeeForm from './CreateEmployeeForm';
import InviteUserForm from './InviteUserForm';
import { useSelector } from 'react-redux';

const MyCompanyPage = () => {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const [selectedIndex, setSelectedIndex] = useState(null);

  const [tabs] = useState(['Criar Usuário', 'Convidar Usuário']);

  const { data: userRolesList, isLoading: isLoadingUserRolesList } =
    useQueryUserRolesWithUserId(
      { companyId: companyId, vitaUserId: userId },
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  if (isLoadingUserRolesList) {
    return <LoadingOverlay />;
  }
  return (
    <Pane
      className='CategoriesPage'
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      overflowY='auto'
      padding={16}
    >
      <Pane marginBottom={22}>
        <Tablist className='tabList'>
          {tabs.map((tab, index) => (
            <Tab
              className='individualTabs'
              key={tab}
              id={tab}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
              aria-controls={`panel-${tab}`}
              style={{ backgroundColor: 'white' }}
            >
              {tab}
            </Tab>
          ))}
        </Tablist>
      </Pane>
      <Pane width='fit-content'>
        <Pane
          key='create-employee'
          id={`panel-create-employee`}
          role='tabpanel'
          aria-labelledby='create-employee'
          aria-hidden={0 !== selectedIndex}
          display={0 === selectedIndex ? 'block' : 'none'}
          background='tint1'
          padding={16}
        >
          <CreateEmployeeForm />
        </Pane>
        <Pane
          key='invite-user'
          id={`panel-invite-user`}
          role='tabpanel'
          aria-labelledby='invite-user'
          aria-hidden={1 !== selectedIndex}
          display={1 === selectedIndex ? 'block' : 'none'}
          background='tint1'
          padding={16}
        >
          <InviteUserForm />
        </Pane>
      </Pane>
      <Pane>
        <UserRoleList />
      </Pane>
    </Pane>
  );
};

export default MyCompanyPage;
