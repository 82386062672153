import { React, useEffect } from 'react';

import { Heading, Pane, Radio, RadioGroup, Select, Text } from 'evergreen-ui';

import { useQueryEntriesFilteredByYear } from '../../services/api/entriesService';
import NetProfit from './NetProfit';
import AccumulatedBalance from './AccumulatedBalance';
import { useSelector } from 'react-redux';
import LoadingOverlay from '../../shared/LoadingOverlay';

import DashboardFilterBar from './DashboardFilterBar';
import { mutate } from 'swr';
import {
  contrastedColors,
  createAccumulatedBalance,
  createBanksChart,
  createDreResults,
  createNetProfit,
  createPieChart,
} from './dashboardHelper';
import { useState } from 'react';
import PieCharts from './PieCharts';
import BanksChart from './BanksChart';
import { useQueryBankBalancesByCompanyId } from '../../services/api/banksService';
import PieChartsOptions from './PieChartsOptions';
import BanksChartOptions from './BanksChartOptions';
import { fi } from 'date-fns/locale';
import DreResults from './DreResults';
import {
  arrayDre,
  populateNamesArrays,
  resetData,
} from '../../helpers/dreHelper';
import DreResultsOptions from './DreResultsOptions';

function DashboardPage() {
  const {
    dashboardYear,
    startDashboardDate,
    endDashboardDate,
    entryStatus,
    filterType,
    selectedMonths,
  } = useSelector((state) => state.dashboardValues);

  const companyId = localStorage.getItem('companyId');

  const [netProfit, setNetProfit] = useState([]);
  const [accumulatedBalance, setAccumulatedBalance] = useState([]);
  const [pieChart, setPieChart] = useState([]);
  const [banksChart, setBanksChart] = useState([]);
  const [dreResults, setDreResults] = useState([]);
  const [selectedDre, setSelectedDre] = useState('Receita Bruta');
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [resultValue, setResultValue] = useState('Entrada');
  const [dataTypeValue, setDataTypeValue] = useState('DRE');
  const [selectedSet, setSelectedSet] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [consolidated, setConsolidated] = useState(true);
  const [pending, setPending] = useState(true);

  const {
    data: entries = [],
    isLoading: isLoadingJournalEntriesFilteredByYear,
    isValidating: isValidatingJournalEntriesFilteredByYear,
  } = useQueryEntriesFilteredByYear(
    // company.data.id,
    companyId,
    startDashboardDate,
    endDashboardDate,
    filterType,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const {
    data: bankBalances = [],
    isLoading: isLoadingBankBalances,
    isValidating: isValidatingBankBalances,
  } = useQueryBankBalancesByCompanyId(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    !isValidatingJournalEntriesFilteredByYear &&
      !isLoadingJournalEntriesFilteredByYear &&
      createNetProfit(entries, entryStatus, filterType, setNetProfit);
  }, [
    isValidatingJournalEntriesFilteredByYear,
    isLoadingJournalEntriesFilteredByYear,
    entryStatus,
  ]);

  useEffect(() => {
    !isValidatingJournalEntriesFilteredByYear &&
      !isLoadingJournalEntriesFilteredByYear &&
      createAccumulatedBalance(
        entries,
        entryStatus,
        filterType,
        setAccumulatedBalance
      );
  }, [
    isValidatingJournalEntriesFilteredByYear,
    isLoadingJournalEntriesFilteredByYear,
    entryStatus,
  ]);

  useEffect(() => {
    !isValidatingJournalEntriesFilteredByYear &&
      !isLoadingJournalEntriesFilteredByYear &&
      createPieChart(
        entries,
        entryStatus,
        resultValue,
        dataTypeValue,
        setPieChart,
        setSelectedSet,
        setTotalValue,
        filterType,
        selectedMonths
      );
  }, [
    isValidatingJournalEntriesFilteredByYear,
    isLoadingJournalEntriesFilteredByYear,
    entryStatus,
    resultValue,
    dataTypeValue,
    selectedMonths,
  ]);

  useEffect(() => {
    !isValidatingBankBalances &&
      !isLoadingBankBalances &&
      setSelectedBanks(bankBalances.data.map((bank) => bank.name));
  }, [isLoadingBankBalances]);

  useEffect(() => {
    !isValidatingBankBalances &&
      !isLoadingBankBalances &&
      createBanksChart(bankBalances, selectedBanks, setBanksChart);
  }, [isValidatingBankBalances, isLoadingBankBalances, selectedBanks]);

  useEffect(() => {
    const tempFunction = (entries) => {
      return entries;
    };
    let dreEntries;
    if (
      !isValidatingJournalEntriesFilteredByYear &&
      !isLoadingJournalEntriesFilteredByYear
    ) {
      dreEntries = populateNamesArrays(
        entries.data,
        filterType,
        entryStatus,
        [],
        tempFunction
      );
      createDreResults(dreEntries, selectedDre, setDreResults);
    }
    return () => {
      resetData();
    };
  }, [
    isValidatingJournalEntriesFilteredByYear,
    isLoadingJournalEntriesFilteredByYear,
    entryStatus,
    selectedDre,
  ]);

  useEffect(() => {
    mutate('entriesFilteredByYear');
  }, [dashboardYear, filterType]);

  return (
    <Pane width='100%'>
      <Pane>
        <Pane
          display='flex'
          marginTop={22}
          marginBottom={22}
          justifyContent='center'
        >
          <Heading
            fontSize='3.5vmin'
            fontWeight='bold'
            color='rgba(81, 81, 81,0.8)'
          >
            Dashboard
          </Heading>
        </Pane>
        <DashboardFilterBar />
      </Pane>
      <Pane
        display='flex'
        flexDirection='column'
        alignItems='center'
        overflowY='scroll'
        height='85%'
      >
        {isValidatingJournalEntriesFilteredByYear ||
        isValidatingBankBalances ? (
          <LoadingOverlay />
        ) : (
          <Pane
            className='dashboardPane'
            display='flex'
            width='60%'
            gap={40}
            marginY={40}
            flexDirection='column'
          >
            <Heading textAlign='center' size={600}>
              Lucro líquido em {dashboardYear}
            </Heading>
            <Pane background='#333' width='100%'>
              <NetProfit netProfit={netProfit} />
            </Pane>
            <Heading textAlign='center' size={600}>
              Saldo acumulado em {dashboardYear}
            </Heading>
            <Pane background='#333' width='100%'>
              <AccumulatedBalance accumulatedBalance={accumulatedBalance} />
            </Pane>
            <Heading textAlign='center' size={600}>
              Saldo por conta em {dashboardYear}
            </Heading>
            <Pane background='#333' width='100%' display='flex'>
              <BanksChartOptions
                bankBalances={bankBalances}
                selectedBanks={selectedBanks}
                setSelectedBanks={setSelectedBanks}
                consolidated={consolidated}
                setConsolidated={setConsolidated}
                pending={pending}
                setPending={setPending}
              />
              <Pane width='75%'>
                <BanksChart
                  banksChart={banksChart}
                  consolidated={consolidated}
                  pending={pending}
                />
              </Pane>
            </Pane>
            <Heading textAlign='center' size={600}>
              {`${resultValue}s por ${dataTypeValue} em ${dashboardYear}`}
            </Heading>
            <Pane background='#333' width='100%' display='flex' height={600}>
              <Pane width='74%'>
                <PieCharts pieChart={pieChart} totalValue={totalValue} />
              </Pane>
              <PieChartsOptions
                resultValue={resultValue}
                dataTypeValue={dataTypeValue}
                setDataTypeValue={setDataTypeValue}
                setResultValue={setResultValue}
                selectedSet={selectedSet}
                setSelectedSet={setSelectedSet}
              />
            </Pane>
            <Heading textAlign='center' size={600}>
              {selectedDre} em {dashboardYear}
            </Heading>
            <Pane background='#333' width='100%' gap={20}>
              <DreResultsOptions
                selectedDre={selectedDre}
                setSelectedDre={setSelectedDre}
              />
              <DreResults dreResults={dreResults} selectedDre={selectedDre} />
            </Pane>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}

export default DashboardPage;
