import request from './request';
import useSWR from 'swr';

export const getCompanies = async (userId) => {
  return request({
    url: `Companies`,
    method: 'GET',
  });
};

export const getCompaniesByUser = async (userId) => {
  return request({
    url: `Companies/User/${userId}`,
    method: 'GET',
  });
};

export const getCompany = async (companyId) => {
  return request({
    url: `Companies/${companyId}`,
    method: 'GET',
  });
};

export const getCompanyBalance = async (companyId) => {
  return request({
    url: `Companies/${companyId}/Balance`,
    method: 'GET',
  });
};

export const postCompany = async (companyObject) => {
  return request({
    url: 'Companies',
    timeout: 10000,
    method: 'POST',
    data: companyObject,
  });
};

export const putCompany = async (companyObject) => {
  return request({
    url: `Companies/${companyObject.id}`,
    timeout: 10000,
    method: 'PUT',
    data: companyObject,
  });
};

/*
 * Use queries
 */

export const useQueryCompanies = (userId, options) => {
  const key = 'companies';
  const { data, error } = useSWR(
    key,
    userId ? () => getCompanies(userId) : null,
    options ?? null
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryCompaniesByUser = (userId, options) => {
  const key = 'companiesByUser';
  const { data, error } = useSWR(
    key,
    userId ? () => getCompaniesByUser(userId) : null,
    options ?? null
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryCompany = (companyId, options) => {
  const key = 'company';
  const { data, error, isLoading } = useSWR(
    key,
    companyId ? () => getCompany(companyId) : null,
    options ?? null
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryCompanyBalance = (companyId, options) => {
  const key = 'balance';
  const { data, error } = useSWR(
    () => key,
    companyId ? () => getCompanyBalance(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
