import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Badge,
  Button,
  CaretDownIcon,
  CaretUpIcon,
  DoubleCaretVerticalIcon,
  DuplicateIcon,
  EditIcon,
  GroupObjectsIcon,
  Heading,
  Icon,
  IconButton,
  Menu,
  MinusIcon,
  MoreIcon,
  Pane,
  Popover,
  Position,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  TickCircleIcon,
  TrashIcon,
  UngroupObjectsIcon,
  Pill,
  ChevronUpIcon,
  ChevronDownIcon,
  BanCircleIcon,
  CornerDialog,
  Text,
  StatusIndicator,
  Tooltip,
  Dialog,
  Checkbox,
} from 'evergreen-ui';

import { Tooltip as MatTooltip } from '@mui/material';

import {
  convertDate,
  convertDate2,
  convertDate3,
} from '../../helpers/dateConverter';
import { getNameAndColorEntryType } from '../../helpers/entriesHelper';
import {
  deleteEntry,
  deleteManyEntries,
  putEntry,
  putManyEntry,
  useQueryEntriesFilteredByDate,
} from '../../services/api/entriesService';

import Filter from './Filter';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import EntryJournalFilterBar from './EntryJournalFilterBar';
import { toastNotification } from '../../shared/toastNotification';
import { formatToMoneyWithSymbol } from '../../helpers/moneyFormatter';
import EntryEditFormComponent from './EntryEditFormComponent';
import TransferEditFormComponent from './TransferEditFormComponent';
import { mutate } from 'swr';
import EntryCopyFormComponent from './EntryCopyFormComponent';
import TransferCopyFormComponent from './TransferCopyFormComponent';
import EntryJournalApiFilterBar from './EntryJournalApiFilterBar';
import { useDispatch, useSelector } from 'react-redux';
import { dateFilterActions } from '../../store/dateFilter';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { balanceValuesActions } from '../../store/balanceValues';
import { entryValuesActions } from '../../store/entryValues';
import InitialBalanceEditFormComponent from './InitialBalanceEditFormComponent';
import { useQueryUserRolesCurrentRole } from '../../services/api/userRolesService';

function EntriesTableComponent() {
  const dispatch = useDispatch();
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const startDate = useSelector((state) => state.dateFilter.startDate);
  const endDate = useSelector((state) => state.dateFilter.endDate);
  const filterType = useSelector((state) => state.dateFilter.filterType);
  const filteredEntries = useSelector(
    (state) => state.dateFilter.filteredEntries
  );
  const { entryEdit, transferEdit } = useSelector((state) => state.entryValues);

  const tableContainerRef = useRef(null);

  /* API calls */

  const {
    data: journalEntriesFilteredByDate,
    isLoading: isLoadingJournalEntriesFilteredByDate,
    isValidating: isValidatingJournalEntriesFilteredByDate,
    isError: isErrorJournalEntriesFilteredByDate,
  } = useQueryEntriesFilteredByDate(
    // company.data.id,
    companyId,
    startDate,
    endDate,
    filterType,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
    }
  );

  const { data: currentRole, isLoading: isLoadingCurrentRole } =
    useQueryUserRolesCurrentRole(
      { companyId: companyId, vitaUserId: userId },
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  //Table states
  const [columnVisibility, setColumnVisibility] = useState({
    criadoEm: false,
    atualizadoEm: false,
    idUsuario: false,
    Agrupamento: false,
    DRE: false,
    Plano: false,
    Juros: false,
    Multa: false,
    Desconto: false,
    userCreateId: false,
    userUpdateId: false,
    Conciliado: false,
    Valor: false,
    select: false,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([{ id: 'criadoEm', desc: true }]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [grouping, setGrouping] = useState([]);
  const [columnResizeMode, setColumnResizeMode] = useState('onChange');
  const [rowSelection, setRowSelection] = useState({});

  // Entries states
  const [isShownDeleteDialog, setIsShownDeleteDialog] = useState(false);
  const [isShownDeleteManyDialog, setIsShowDeleteManyDialog] = useState(false);
  const [isShownDialog, setIsShownDialog] = useState(false);
  const [isShownCopyDialog, setIsShownCopyDialog] = useState(false);
  const [isShownTransferDialog, setIsShownTransferDialog] = useState(false);
  const [isShownInitialBalanceDialog, setIsShownInitialBalanceDialog] =
    useState(false);
  const [isShownTransferCopyDialog, setIsShownTransferCopyDialog] =
    useState(false);
  const [journalEntryOnFocus, setEntryJournalOnFocus] = useState();

  // Notification states
  const [expiredEntries, setExpiredEntries] = useState([]);
  const [isShownCorner, setIsShownCorner] = useState(false);

  const getExpiredEntries = () => {
    let counter = [];
    if (!isLoadingJournalEntriesFilteredByDate) {
      journalEntriesFilteredByDate.data.map((entry) => {
        entry.dateVencimento < convertDate(new Date()) &&
          !entry.statusPayment &&
          counter.push(entry);
      });

      setExpiredEntries(counter);
    }
  };

  useEffect(() => {
    getExpiredEntries();
  }, [
    // isLoadingJournalEntriesBankBalance,
    // journalEntriesBankBalance,
    isLoadingJournalEntriesFilteredByDate,
    journalEntriesFilteredByDate,
  ]);

  // Form functions

  const openDialog = (journalEntry) => {
    setIsShownDialog(true);
    dispatch(entryValuesActions.setEntryEdit(journalEntry));
    setEntryJournalOnFocus(journalEntry);
  };

  const openCopyDialog = (journalEntry) => {
    setIsShownCopyDialog(true);
    setEntryJournalOnFocus(journalEntry);
  };

  const openTransferDialog = (journalEntry) => {
    dispatch(entryValuesActions.setTransferEdit(journalEntry));
    setIsShownTransferDialog(true);
    setEntryJournalOnFocus(journalEntry);
  };

  const openInitialBalanceDialog = (journalEntry) => {
    setIsShownInitialBalanceDialog(true);
    setEntryJournalOnFocus(journalEntry);
  };

  const openTransferCopyDialog = (journalEntry) => {
    if (
      !isLoadingJournalEntriesFilteredByDate &&
      journalEntry.isInput === true
    ) {
      journalEntriesFilteredByDate?.data.map((entry) => {
        if (journalEntry.journalEntryRefId === entry.id) {
          dispatch(entryValuesActions.setTransferCopy(journalEntry));
          setIsShownTransferCopyDialog(true);
          setEntryJournalOnFocus(entry);
        }
      });
    } else {
      dispatch(entryValuesActions.setTransferCopy(journalEntry));
      setIsShownTransferCopyDialog(true);
      setEntryJournalOnFocus(journalEntry);
    }
  };

  const openDeleteDialog = (journalEntry) => {
    setIsShownDeleteDialog(true);
    setEntryJournalOnFocus(journalEntry);
  };
  const openDeleteManyDialog = (entryList) => {
    setIsShowDeleteManyDialog(true);
    setEntryJournalOnFocus(entryList);
  };

  const deleteMutation = (entry) => {
    console.log('entry', entry);
    let statusResponse = false;
    let toastContent = '';
    if (entry.fitId) {
      statusResponse = 'warning';
      toastContent = `Não é possível excluir lançamentos conciliados.`;
      toastNotification(statusResponse, toastContent);
      setIsShownDeleteDialog(false);
      return;
    }
    if (!entry.fitId && entry.journalEntryType === 5) {
      if (
        (entry.isInput && entry.journalEntryRef?.fitId) ||
        (!entry.isInput && entry.journalEntryFromRef?.fitId)
      ) {
        statusResponse = 'warning';
        toastContent = `Não é possível excluir lançamentos conciliados.`;
        toastNotification(statusResponse, toastContent);
        setIsShownDeleteDialog(false);
        return;
      }
    }
    deleteEntry(entry.id)
      .then((res) => {
        console.log('res delete entry: ', res);
        statusResponse = 'success';
        toastContent = 'Lançamento excluído com sucesso.';
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('delete err', err);
        toastContent = `Erro ao excluir lançamento.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
        mutate('banks');
        setIsShownDeleteDialog(false);
      });
    // deleteEntryOptions(entry)
  };
  const deleteMany = (entryList) => {
    console.log('entry', entryList);
    let statusResponse = false;
    let toastContent = '';
    let hasReconciledEntries = false;
    if (entryList.some((entry) => entry.journalEntryType === 2)) {
      statusResponse = 'warning';
      toastContent = `Não é possível excluir lançamentos de saldo inicial.`;
      toastNotification(statusResponse, toastContent);
      setIsShowDeleteManyDialog(false);
      return;
    }
    entryList.map((entry) => {
      if (entry.fitId) {
        hasReconciledEntries = true;
        return;
      }
      if (!entry.fitId && entry.journalEntryType === 5) {
        if (
          (entry.isInput && entry.journalEntryRef?.fitId) ||
          (!entry.isInput && entry.journalEntryFromRef?.fitId)
        ) {
          hasReconciledEntries = true;
          return;
        }
      }
    });
    if (hasReconciledEntries) {
      statusResponse = 'warning';
      toastContent = `Não é possível excluir lançamentos conciliados.`;
      toastNotification(statusResponse, toastContent);
      setIsShowDeleteManyDialog(false);
      return;
    }

    const entryListIds = entryList.map((entry) => entry.id);
    deleteManyEntries(entryListIds)
      .then((res) => {
        console.log('res delete entry: ', res);
        statusResponse = 'success';
        toastContent = 'Lançamentos excluídos com sucesso.';
        table.resetRowSelection();
        console.log('reset row selection should work');
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('delete err', err);
        toastContent = `Erro ao excluir lançamentos.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
        mutate('banks');
        setIsShowDeleteManyDialog(false);
      });
  };
  const statusPaymentMutation = (entry) => {
    let statusResponse = false;
    let toastContent = '';
    console.log('entry teste 1', entry);

    if (entry.fitId !== null) {
      statusResponse = 'warning';
      toastContent = `Não é possível alterar o status de lançamentos conciliados.`;
      toastNotification(statusResponse, toastContent);
      return;
    }

    putEntry(entry)
      .then((res) => {
        console.log('res delete entry: ', res);
        statusResponse = 'success';
        toastContent = 'Lançamento atualizado com sucesso.';
        getExpiredEntries();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('delete err', err);
        toastContent = `Erro ao atualizar lançamento.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
      });
    // deleteEntryOptions(entry)
  };

  const statusPaymentManyMutation = (entryList) => {
    let statusResponse = false;
    let toastContent = '';

    if (entryList.some((entry) => entry.fitId !== null)) {
      statusResponse = 'warning';
      toastContent = `Não é possível alterar o status de lançamentos conciliados.`;
      toastNotification(statusResponse, toastContent);
      return;
    }

    putManyEntry(entryList)
      .then((res) => {
        console.log('res delete entry: ', res);
        statusResponse = 'success';
        toastContent = 'Lançamentos atualizados com sucesso.';
        getExpiredEntries();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('delete err', err);
        toastContent = `Erro ao atualizar lançamentos.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
      });
    // deleteEntryOptions(entry)
  };

  const checkEntries = (table) => {
    const hasPaidEntries = table
      .getFilteredSelectedRowModel()
      .flatRows.some((item) => item.original.statusPayment === true);
    const hasPendingEntries = table
      .getFilteredSelectedRowModel()
      .flatRows.some((item) => item.original.statusPayment === false);
    const hasInitialBalance = table
      .getFilteredSelectedRowModel()
      .flatRows.some(
        (item) =>
          item.original.journalEntryType === 2 ||
          item.original.journalEntryType === 5
      );
    return hasInitialBalance
      ? 'hasInitialBalance'
      : hasPaidEntries && hasPendingEntries
      ? 'mixed'
      : hasPaidEntries
      ? 'paid'
      : 'pending';
  };

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('entryType', {
        enableHiding: false,
        header: 'Tipo',
        id: 'entryType',
        enableSorting: false,
        size: 140,
        cell: (info) => {
          let val = getNameAndColorEntryType(info.cell.row.original);
          return <Badge color={`${val[1]}`}>{val[0]}</Badge>;
        },
      }),
      columnHelper.accessor('dateCreate', {
        header: 'Criado em',
        id: 'criadoEm',
        cell: (info) => {
          return convertDate3(info.cell.getValue());
        },
      }),
      columnHelper.accessor('dateUpdate', {
        header: 'Atualizado em',
        id: 'atualizadoEm',
        cell: (info) => {
          return convertDate3(info.cell.getValue());
        },
      }),
      columnHelper.accessor('dateCompetencia', {
        header: 'Competência',
        id: 'competencia',
        cell: (info) => {
          return convertDate2(info.cell.getValue());
        },
        sortingFn: 'datetime',
      }),
      columnHelper.accessor('dateVencimento', {
        header: 'Vencimento',
        id: 'vencimento',
        cell: (info) => {
          return convertDate2(info.cell.getValue());
        },
        sortingFn: 'datetime',
      }),
      {
        header: 'Conta',
        id: 'conta',
        accessorFn: (row) => row.bankAccountName,
        enableSorting: false,
        filterFn: 'equalsString',
      },
      {
        header: 'Classificação DRE',
        id: 'DRE',
        accessorFn: (row) => row.dreClassification,
        enableSorting: false,
        filterFn: 'includesString',
      },
      {
        header: 'Agrupamento',
        id: 'Agrupamento',
        accessorFn: (row) => row.dreGroupingName,
        enableSorting: false,
        filterFn: 'includesString',
      },
      {
        header: 'Plano de Contas',
        id: 'Plano',
        accessorFn: (row) => row.accountPlanName,
        enableSorting: false,
        filterFn: 'includesString',
      },
      {
        header: 'Categoria',
        id: 'categoria',
        accessorFn: (row) => row.categoryName,
        enableSorting: false,
        filterFn: 'includesString',
      },
      {
        header: 'Descrição',
        id: 'descricao',
        accessorFn: (row) => row.description,
        cell: (info) => {
          return (
            <Tooltip content={info.cell.getValue()} showDelay={300}>
              <Text>{info.cell.getValue()}</Text>
            </Tooltip>
          );
        },
      },
      {
        header: 'Criador',
        id: 'userCreateId',
        accessorFn: (row) => row.userCreateId,
        enableSorting: false,
        filterFn: 'includesString',
      },
      {
        header: 'Atualizado por',
        id: 'userUpdateId',
        accessorFn: (row) => row.userUpdateId,
        enableSorting: false,
        filterFn: 'includesString',
      },
      columnHelper.accessor('statusString', {
        header: 'Status',
        id: 'Status',
        enableSorting: false,
        aggregationFn: 'percentPaid',
        filterFn: 'equalsString',
        // aggregatedCell: ({ getValue }) => {
        //   return getValue();
        // },
        cell: (info) => {
          if (info.cell.getValue() === 'Pago') {
            return <TickCircleIcon color='success' marginRight={16} />;
          } else if (info.cell.getValue() === 'Vencido') {
            return <BanCircleIcon color='danger' marginRight={16} />;
          } else {
            return <BanCircleIcon color='warning' marginRight={16} />;
          }
        },
      }),
      columnHelper.accessor('isReconciliated', {
        header: 'Conciliado',
        id: 'Conciliado',
        enableSorting: false,

        filterFn: 'equalsString',
        // aggregatedCell: ({ getValue }) => {
        //   return getValue();
        // },
        cell: (info) => {
          if (info.cell.getValue() === 'Sim') {
            return <TickCircleIcon color='info' marginRight={16} />;
          } else {
            return <BanCircleIcon color='muted' marginRight={16} />;
          }
        },
      }),
      columnHelper.accessor('interestValue', {
        header: 'Juros',
        id: 'Juros',
        aggregationFn: 'sum',
        aggregatedCell: ({ getValue }) => {
          return formatToMoneyWithSymbol(getValue());
        },
        cell: (info) => {
          return formatToMoneyWithSymbol(info.cell.getValue());
        },
      }),
      columnHelper.accessor('fineValue', {
        header: 'Multa',
        id: 'Multa',
        aggregationFn: 'sum',
        aggregatedCell: ({ getValue }) => {
          return formatToMoneyWithSymbol(getValue());
        },
        cell: (info) => {
          return formatToMoneyWithSymbol(info.cell.getValue());
        },
      }),
      columnHelper.accessor('discountValue', {
        header: 'Desconto',
        id: 'Desconto',
        aggregationFn: 'sum',
        aggregatedCell: ({ getValue }) => {
          return formatToMoneyWithSymbol(getValue());
        },
        cell: (info) => {
          return formatToMoneyWithSymbol(info.cell.getValue());
        },
      }),
      columnHelper.accessor('value', {
        enableHiding: true,
        header: 'Valor',
        id: 'Valor',
        aggregationFn: 'sum',
        aggregatedCell: ({ getValue }) => {
          return formatToMoneyWithSymbol(getValue());
        },
        cell: (info) => {
          return info.row.original.entryType === 'Entrada' ? (
            <Text color='#3366FF'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          ) : info.row.original.entryType === 'Saída' ? (
            <Text color='#D14343'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          ) : info.row.original.entryType === 'Transferência' ? (
            <Text color='#429777'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          ) : (
            formatToMoneyWithSymbol(info.cell.getValue())
          );
        },
      }),
      columnHelper.accessor('valueFinal', {
        enableHiding: false,
        header: 'Valor Final',
        id: 'valorFinal',
        aggregationFn: 'sum',
        aggregatedCell: ({ getValue }) => {
          return formatToMoneyWithSymbol(getValue());
        },
        cell: (info) => {
          return info.row.original.entryType === 'Entrada' ? (
            <Text color='#3366FF'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          ) : info.row.original.entryType === 'Saída' ? (
            <Text color='#D14343'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          ) : info.row.original.entryType === 'Transferência' ? (
            <Text color='#429777'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          ) : info.row.original.entryType === 'Saldo Inicial' &&
            info.row.original.isInput === true ? (
            <Text color='#3366FF'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          ) : (
            <Text color='#D14343'>
              {formatToMoneyWithSymbol(info.cell.getValue())}
            </Text>
          );
        },
      }),
      columnHelper.accessor('select', {
        // enableHiding: false,
        enableSorting: false,
        // enableColumnFilter: false,
        enableGrouping: false,
        size: 150,
        // minSize: 50,
        // maxSize: 50,
        filterFn: 'checkedRows',
        header: 'Selecionar',
        id: 'select',
        cell: ({ row }) => (
          <Pane>
            <Checkbox
              checked={row.getIsSelected()}
              onChange={(e) => selectRowHandler(e, row)}
              indeterminate={row.getIsSomeSelected()}
              value={row.getIsSelected() ? 'checked' : 'unchecked'}
            />
          </Pane>
        ),
      }),
      columnHelper.accessor('actions', {
        enableHiding: false,
        header: 'Ações',
        id: 'actions',
        enableColumnFilter: false,
        enableSorting: false,
        enableGrouping: false,
        cell: (info) => (
          <Pane display='flex' justifyContent='center'>
            <Popover
              position={Position.BOTTOM_LEFT}
              animationDuration={1}
              content={
                <Menu>
                  <Menu.Group>
                    {info.row.original.journalEntryType !== 2 && (
                      <Menu.Item
                        icon={DuplicateIcon}
                        intent='info'
                        onClick={() => {
                          info.row.original.journalEntryType === 5
                            ? openTransferCopyDialog(info.row.original)
                            : openCopyDialog(info.row.original);
                        }}
                      >
                        Criar cópia
                      </Menu.Item>
                    )}
                    <Menu.Item
                      icon={EditIcon}
                      intent='muted'
                      onClick={() => {
                        info.row.original.journalEntryType === 5
                          ? openTransferDialog(info.row.original)
                          : info.row.original.journalEntryType === 2
                          ? openInitialBalanceDialog(info.row.original)
                          : openDialog(info.row.original);
                      }}
                    >
                      Editar
                    </Menu.Item>
                    {info.row.original.journalEntryType !== 2 && (
                      <Menu.Item
                        icon={TrashIcon}
                        intent='danger'
                        onClick={() => {
                          openDeleteDialog(info.row.original);
                        }}
                      >
                        Deletar
                      </Menu.Item>
                    )}
                    {info.row.original.journalEntryType === 1 && (
                      <Menu.Item
                        icon={
                          info.row.original.statusPayment
                            ? MinusIcon
                            : TickCircleIcon
                        }
                        intent={
                          info.row.original.statusPayment
                            ? 'warning'
                            : 'success'
                        }
                        onClick={() => {
                          const paid = {
                            ...info.row.original,
                            statusPayment: true,
                            statusString: 'Pago',
                          };
                          const pending = {
                            ...info.row.original,
                            statusPayment: false,
                            statusString: 'Pendente',
                          };

                          info.row.original.statusPayment
                            ? statusPaymentMutation(pending)
                            : statusPaymentMutation(paid);
                        }}
                      >
                        {info.row.original.statusPayment
                          ? 'Marcar como Pendente'
                          : 'Marcar como Pago'}
                      </Menu.Item>
                    )}
                  </Menu.Group>
                </Menu>
              }
            >
              <IconButton
                appearance='minimal'
                intent='info'
                icon={MoreIcon}
                disabled={currentRole.data.userRole === 'ANALISTA'}
              />
            </Popover>
          </Pane>
        ),
      }),
    ],
    []
  );

  const balanceCardHandler = (tableObj) => {
    let input = 0;
    let output = 0;
    let transferIn = 0;
    let transferOut = 0;

    dispatch(balanceValuesActions.resetValues());
    // console.log(table.getFilteredSelectedRowModel());

    if (table.getColumn('select').getFilterValue() === 'unchecked') {
      tableObj.getFilteredRowModel().flatRows.map((item) => {
        if (item.original.isInput === true) {
          if (item.original.journalEntryType === 5) {
            transferIn += item.original.value;
          } else {
            input += item.original.valueFinal;
          }
        } else if (item.original.isInput === false) {
          if (item.original.journalEntryType === 5) {
            transferOut += item.original.value;
          } else {
            output += item.original.valueFinal;
          }
        }

        dispatch(balanceValuesActions.setInput(input));
        dispatch(balanceValuesActions.setOutput(output));
        dispatch(balanceValuesActions.setTransferInput(transferIn));
        dispatch(balanceValuesActions.setTransferOutput(transferOut));
      });
      return;
    }

    if (tableObj.getFilteredSelectedRowModel().flatRows.length > 0) {
      tableObj.getFilteredSelectedRowModel().flatRows.map((item) => {
        if (item.original.isInput === true) {
          if (item.original.journalEntryType === 5) {
            transferIn += item.original.value;
          } else {
            input += item.original.valueFinal;
          }
        } else if (item.original.isInput === false) {
          if (item.original.journalEntryType === 5) {
            transferOut += item.original.value;
          } else {
            output += item.original.valueFinal;
          }
        }

        dispatch(balanceValuesActions.setInput(input));
        dispatch(balanceValuesActions.setOutput(output));
        dispatch(balanceValuesActions.setTransferInput(transferIn));
        dispatch(balanceValuesActions.setTransferOutput(transferOut));
      });
      return;
    }

    tableObj.getFilteredRowModel().flatRows.map((item) => {
      if (item.original.isInput === true) {
        if (item.original.journalEntryType === 5) {
          transferIn += item.original.value;
        } else {
          input += item.original.valueFinal;
        }
      } else if (item.original.isInput === false) {
        if (item.original.journalEntryType === 5) {
          transferOut += item.original.value;
        } else {
          output += item.original.valueFinal;
        }
      }

      dispatch(balanceValuesActions.setInput(input));
      dispatch(balanceValuesActions.setOutput(output));
      dispatch(balanceValuesActions.setTransferInput(transferIn));
      dispatch(balanceValuesActions.setTransferOutput(transferOut));
    });
  };

  const selectRowHandler = (e, row) => {
    // console.log('row', row);

    row.toggleSelected(e.target.checked);

    const selectColumn = table.getColumn('select');
    const selectedFilter = selectColumn.getFilterValue();
    selectedFilter !== undefined && selectColumn.setFilterValue(selectedFilter);
  };

  const selectAllHandler = (e, table) => {
    table.toggleAllRowsSelected(e.target.checked);
    const selectColumn = table.getColumn('select');
    const selectedFilter = selectColumn.getFilterValue();
    selectedFilter !== undefined && selectColumn.setFilterValue(selectedFilter);
  };

  const getPercentPaid = (_columnId, leafRows) => {
    let totalPaid = 0;
    leafRows.map((row) => {
      row.original.statusPayment && totalPaid++;
    });
    const total = leafRows.length;
    return `${totalPaid}/${total} Pago`;
  };

  const getCheckedRows = (rows, columnIds, filterValue) => {
    if (filterValue === 'checked') {
      // console.log('checked');
      // console.log(rows);
      // console.log(columnIds);
      return rows.getIsSelected() && rows.original;
    }

    if (filterValue === 'unchecked') {
      return !rows.getIsSelected() && rows.original;
    }
  };

  const table = useReactTable({
    columns,
    data:
      !isLoadingJournalEntriesFilteredByDate &&
      journalEntriesFilteredByDate.data,
    // filterFns: {
    //   fuzzy: fuzzyFilter,
    // },
    aggregationFns: {
      percentPaid: getPercentPaid,
    },
    filterFns: {
      checkedRows: getCheckedRows,
    },
    state: {
      columnFilters,
      sorting,
      globalFilter,
      columnVisibility,
      columnResizeMode,
      rowSelection,
      grouping,
    },
    initialState: {
      pagination: {
        pageSize: 500,
        pageIndex: 0,
      },
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: fuzzyFilter,
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  if (isLoadingJournalEntriesFilteredByDate) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Pane
        display='flex'
        flexDirection='column'
        gap={10}
        width='100%'
        padding={16}
        elevation={0}
        boxShadow='none'
      >
        <EntryEditFormComponent
          isShown={isShownDialog}
          setIsShown={setIsShownDialog}
          journalEntry={journalEntryOnFocus}
        />
        <EntryCopyFormComponent
          isShown={isShownCopyDialog}
          setIsShown={setIsShownCopyDialog}
          journalEntry={journalEntryOnFocus}
        />
        <InitialBalanceEditFormComponent
          isShown={isShownInitialBalanceDialog}
          setIsShown={setIsShownInitialBalanceDialog}
          journalEntry={journalEntryOnFocus}
        />
        <TransferEditFormComponent
          isShown={isShownTransferDialog}
          setIsShown={setIsShownTransferDialog}
          journalEntry={journalEntryOnFocus}
          entry={journalEntriesFilteredByDate}
          setEntryJournalOnFocus={setEntryJournalOnFocus}
        />
        <TransferCopyFormComponent
          isShown={isShownTransferCopyDialog}
          setIsShown={setIsShownTransferCopyDialog}
          journalEntry={journalEntryOnFocus}
        />
        <EntryJournalApiFilterBar
          tableProps={table}
          journalEntries={journalEntriesFilteredByDate}
          currentRole={currentRole}
        />
        <EntryJournalFilterBar
          tableProps={table}
          journalEntries={journalEntriesFilteredByDate}
          currentRole={currentRole}
        />
        <Dialog
          isShown={isShownDeleteDialog}
          title='Deletar lançamento'
          intent='danger'
          onCloseComplete={() => setIsShownDeleteDialog(false)}
          confirmLabel='Deletar'
          cancelLabel='Cancelar'
          onConfirm={() => deleteMutation(journalEntryOnFocus)}
          preventBodyScrolling
        >
          Tem certeza que deseja deletar este lançamento?
        </Dialog>
        <Dialog
          isShown={isShownDeleteManyDialog}
          title='Deletar lançamentos'
          intent='danger'
          onCloseComplete={() => setIsShowDeleteManyDialog(false)}
          confirmLabel='Deletar'
          cancelLabel='Cancelar'
          onConfirm={() => deleteMany(journalEntryOnFocus)}
          preventBodyScrolling
        >
          Tem certeza que deseja deletar estes lançamentos?
        </Dialog>

        <CornerDialog
          className='cornerDialog'
          title='Lançamentos vencidos:'
          isShown={isShownCorner}
          onCloseComplete={() => setIsShownCorner(false)}
          hasFooter={false}
          width='290px'
        >
          <Pane className='cornerDialogPane'>
            <Pane overflowY='auto'>
              {expiredEntries
                .sort((a, b) => {
                  const nameA = a.dateVencimento.toUpperCase();
                  const nameB = b.dateVencimento.toUpperCase();

                  if (nameA > nameB) {
                    return -1;
                  }

                  if (nameA < nameB) {
                    return 1;
                  }

                  return 0;
                })
                .map((entry) => {
                  return (
                    <Pane
                      display='flex'
                      gap={12}
                      margin={4}
                      alignItems='center'
                    >
                      <Badge color={entry.isInput ? 'blue' : 'red'}>
                        {entry.isInput ? 'Entrada' : 'Saída'}
                      </Badge>
                      <MatTooltip
                        title='Filtrar'
                        arrow
                        placement='right'
                        enterDelay={500}
                      >
                        <Heading
                          className='expiredDate'
                          size={400}
                          onClick={() => {
                            dispatch(
                              dateFilterActions.setStartDate(
                                entry.dateVencimento.substr(0, 10)
                              )
                            );
                            dispatch(
                              dateFilterActions.setStartDateTemp(
                                entry.dateVencimento.substr(0, 10)
                              )
                            );
                            dispatch(
                              dateFilterActions.setEndDate(
                                entry.dateVencimento.substr(0, 10)
                              )
                            );
                            dispatch(
                              dateFilterActions.setEndDateTemp(
                                entry.dateVencimento.substr(0, 10)
                              )
                            );
                          }}
                          cursor='pointer'
                        >
                          {convertDate2(entry.dateVencimento)}
                        </Heading>
                      </MatTooltip>
                    </Pane>
                  );
                })}
            </Pane>
          </Pane>
        </CornerDialog>
        <Heading
          display='flex'
          size={400}
          margin='auto'
          textAlign='center'
          color='red'
          onClick={() => setIsShownCorner(true)}
          cursor='pointer'
          width='fit-content'
        >
          {expiredEntries.length > 1 ? (
            <Pane display='flex' position='relative'>
              <StatusIndicator
                color='danger'
                marginRight={8}
              >{`Você possui ${expiredEntries.length} lançamentos vencidos neste período`}</StatusIndicator>
            </Pane>
          ) : expiredEntries.length === 1 ? (
            <Pane display='flex' position='relative'>
              <StatusIndicator color='danger' marginRight={8}>
                Você possui 1 lançamento vencido neste período
              </StatusIndicator>
            </Pane>
          ) : null}
        </Heading>

        {isValidatingJournalEntriesFilteredByDate ? (
          <LoadingOverlay />
        ) : (
          <Pane className='flipped' overflowY='auto'>
            <Table
              display='block'
              boxSizing='border-box'
              // overflow='auto'
              className='entryTable'
              padding={0}
              border='none'
              elevation={1}
              boxShadow='none'
              ref={tableContainerRef}
            >
              <table>
                <thead>
                  {
                    // Loop over the header rows
                    table.getHeaderGroups().map((headerGroup) => (
                      // Apply the header row props
                      <TableRow
                        key={headerGroup.id}
                        backgroundColor='#f4f5f9'
                        marginBottom={3}
                        border='none'
                        height='130px'
                        paddingY={12}
                      >
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((header) => {
                            return (
                              <TableHeaderCell
                                key={header.id}
                                padding={0}
                                role='columnheader'
                                style={{
                                  width: '150px',
                                }}
                              >
                                <Heading
                                  fontWeight='normal'
                                  display='flex'
                                  justifyContent='center'
                                  size={400}
                                  lineHeight={1.5}
                                >
                                  {header.isPlaceholder ? null : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '24.5px',
                                      }}
                                    >
                                      {header.column.getCanGroup() ? (
                                        // If the header can be grouped, let's add a toggle
                                        <div>
                                          <MatTooltip
                                            title={
                                              header.column.getIsGrouped()
                                                ? 'Desagrupar'
                                                : 'Agrupar'
                                            }
                                            placement='top'
                                            arrow={true}
                                          >
                                            <IconButton
                                              isActive={true}
                                              appearance='minimal'
                                              icon={
                                                header.column.getIsGrouped()
                                                  ? UngroupObjectsIcon
                                                  : GroupObjectsIcon
                                              }
                                              height={24}
                                              marginRight={4}
                                              onClick={header.column.getToggleGroupingHandler()}
                                              intent={
                                                header.column.getIsGrouped()
                                                  ? 'danger'
                                                  : 'success'
                                              }
                                            />
                                          </MatTooltip>
                                          {header.column.getIsGrouped()
                                            ? `(${header.column.getGroupedIndex()})`
                                            : ``}
                                        </div>
                                      ) : header.column.columnDef
                                          .accessorKey === 'select' ? (
                                        <Pane
                                          display='flex'
                                          padding={4}
                                          marginRight={4}
                                        >
                                          <Checkbox
                                            checked={table.getIsAllRowsSelected()}
                                            indeterminate={table.getIsSomeRowsSelected()}
                                            onChange={(e) =>
                                              selectAllHandler(e, table)
                                            }
                                            style={{ margin: 'auto' }}
                                          />
                                        </Pane>
                                      ) : null}
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {table.getFilteredSelectedRowModel()
                                        .flatRows.length > 1 &&
                                        header.column.columnDef.accessorKey ===
                                          'select' && (
                                          <Pane
                                            display='flex'
                                            justifyContent='center'
                                          >
                                            <Popover
                                              position={Position.BOTTOM_LEFT}
                                              animationDuration={1}
                                              content={
                                                <Menu>
                                                  <Menu.Group>
                                                    <Menu.Item
                                                      icon={TrashIcon}
                                                      intent='danger'
                                                      onClick={() => {
                                                        const selectedEntries =
                                                          table
                                                            .getFilteredSelectedRowModel()
                                                            .flatRows.map(
                                                              (item) =>
                                                                item.original
                                                            );
                                                        console.log(
                                                          'selectedEntries',
                                                          selectedEntries
                                                        );
                                                        openDeleteManyDialog(
                                                          selectedEntries
                                                        );
                                                      }}
                                                    >
                                                      Deletar selecionados
                                                    </Menu.Item>

                                                    {checkEntries(table) !==
                                                      'mixed' &&
                                                      checkEntries(table) !==
                                                        'hasInitialBalance' && (
                                                        <Menu.Item
                                                          icon={
                                                            checkEntries(
                                                              table
                                                            ) === 'paid'
                                                              ? MinusIcon
                                                              : TickCircleIcon
                                                          }
                                                          intent={
                                                            checkEntries(
                                                              table
                                                            ) === 'paid'
                                                              ? 'warning'
                                                              : 'success'
                                                          }
                                                          onClick={() => {
                                                            const selectedEntries =
                                                              table
                                                                .getFilteredSelectedRowModel()
                                                                .flatRows.map(
                                                                  (item) =>
                                                                    item.original
                                                                );
                                                            checkEntries(
                                                              table
                                                            ) === 'paid'
                                                              ? statusPaymentManyMutation(
                                                                  selectedEntries.map(
                                                                    (
                                                                      entry
                                                                    ) => ({
                                                                      ...entry,
                                                                      statusPayment: false,
                                                                    })
                                                                  )
                                                                )
                                                              : statusPaymentManyMutation(
                                                                  selectedEntries.map(
                                                                    (
                                                                      entry
                                                                    ) => ({
                                                                      ...entry,
                                                                      statusPayment: true,
                                                                    })
                                                                  )
                                                                );
                                                          }}
                                                        >
                                                          {checkEntries(
                                                            table
                                                          ) === 'paid'
                                                            ? 'Marcar como Pendente'
                                                            : 'Marcar como Pago'}
                                                        </Menu.Item>
                                                      )}
                                                  </Menu.Group>
                                                </Menu>
                                              }
                                            >
                                              <IconButton
                                                appearance='minimal'
                                                intent='info'
                                                icon={MoreIcon}
                                                marginLeft={4}
                                              />
                                            </Popover>
                                          </Pane>
                                        )}
                                      {header.column.getCanSort() && (
                                        <Button
                                          onClick={header.column.getToggleSortingHandler()}
                                          appearance='minimal'
                                          height={24}
                                          width={12}
                                          marginLeft={4}
                                        >
                                          {{
                                            asc: (
                                              <MatTooltip
                                                title='Crescente'
                                                placement='top'
                                                arrow={true}
                                              >
                                                <Icon
                                                  icon={CaretUpIcon}
                                                  color='selected'
                                                />
                                              </MatTooltip>
                                            ),
                                            desc: (
                                              <MatTooltip
                                                title='Decrescente'
                                                placement='top'
                                                arrow={true}
                                              >
                                                <Icon
                                                  icon={CaretDownIcon}
                                                  color='selected'
                                                />
                                              </MatTooltip>
                                            ),
                                          }[header.column.getIsSorted()] ?? (
                                            <MatTooltip
                                              title='Normal'
                                              placement='top'
                                              arrow={true}
                                            >
                                              <Icon
                                                icon={DoubleCaretVerticalIcon}
                                                color='selected'
                                              />
                                            </MatTooltip>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </Heading>
                                {header.column.getCanFilter() ? (
                                  <div
                                    style={{
                                      marginTop: '8px',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Filter
                                      column={header.column}
                                      table={table}
                                    />
                                  </div>
                                ) : null}
                              </TableHeaderCell>
                            );
                          })
                        }
                      </TableRow>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}

                <tbody>
                  {balanceCardHandler(table)}

                  {table.getRowModel().rows?.map((row) => {
                    return (
                      <TableRow
                        key={row.id}
                        marginTop={1}
                        borderRadius={0}
                        role='row'
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <>
                              <TableCell
                                key={cell.id}
                                style={{
                                  display: 'flex',
                                  role: 'cell',
                                  justifyContent: 'center',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  padding: 0,
                                  fontSize: 'smaller',
                                  background: cell.getIsGrouped()
                                    ? '#dadee9'
                                    : cell.getIsAggregated()
                                    ? '#e3e6ee'
                                    : cell.getIsPlaceholder()
                                    ? '#dadee9'
                                    : 'white',
                                }}
                              >
                                {cell.getIsGrouped() ? (
                                  // If it's a grouped cell, add an expander and row count
                                  <>
                                    <Pane
                                      onClick={(e) => e.stopPropagation()}
                                      display='flex'
                                      flex={1}
                                      marginX={16}
                                    >
                                      <Button
                                        onClick={row.getToggleExpandedHandler()}
                                        cursor={
                                          row.getCanExpand()
                                            ? 'pointer'
                                            : 'normal'
                                        }
                                        display='flex'
                                        flexDirection='row'
                                        flexWrap='nowrap'
                                        gap={8}
                                        width='100%'
                                        justifyContent='space-between'
                                        appearance='minimal'
                                        backgroundColor='white'
                                        padding={3}
                                      >
                                        <Icon
                                          icon={
                                            row.getIsExpanded()
                                              ? ChevronUpIcon
                                              : ChevronDownIcon
                                          }
                                        />
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}

                                        <Pill color='#e9e9e9'>
                                          {row.subRows.length}
                                        </Pill>
                                      </Button>
                                    </Pane>
                                  </>
                                ) : cell.getIsAggregated() ? (
                                  // If the cell is aggregated, use the Aggregated
                                  // renderer for cell
                                  flexRender(
                                    cell.column.columnDef.aggregatedCell ??
                                      cell.column.columnDef.cell,
                                    cell.getContext()
                                  )
                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                  // Otherwise, just render the regular cell
                                  flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )
                                )}
                              </TableCell>
                            </>
                            // </Tooltip>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </tbody>
              </table>
            </Table>
          </Pane>
        )}

        <style scoped jsx='true'>{`
          div[role='cell'] {
            display: inline-block;
            justify-content: center
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0;
            margin: auto 8px;
          }
          div[role='columnheader'] {
            display: inline-block;
            width: fit-content;
            white-space: nowrap;
            overflow: hidden;
            
            margin: auto 0px;
          }
          div[role='row'] {
            border-radius: 0;
          }
        `}</style>
      </Pane>
      {/* <PaginationBarComponent tableProps={table} /> */}
    </>
  );
}

export default EntriesTableComponent;
