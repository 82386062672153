import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  Pane,
  Button,
  TextInputField,
  FormField,
  Checkbox,
  SelectField,
} from 'evergreen-ui';
import { Container, Row, Col } from 'react-grid-system';
import { postBank } from '../../services/api/banksService';
import { toastNotification } from '../../shared/toastNotification';
import { mutate } from 'swr';
import {
  bankAccountTypeArray,
  inputBankValidation,
} from '../../helpers/formHelper';
import { useSelector } from 'react-redux';

function BankAccountsFormComponent({ isShown, setIsShown }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);

  const companyId = localStorage.getItem('companyId');

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({
    defaultValues: {
      name: '',
      agencyNumber: '',
      accountNumber: '',
      description: '',
      accountType: '',
    },
  });

  const onClose = () => {
    reset();
    // setChecked(false);
    setIsShown(false);
  };

  useEffect(() => {
    inputBankValidation(
      watch('agencyNumber'),
      watch('name'),
      watch('accountNumber'),
      watch('description'),
      watch('bankAccountType'),
      setIsDisabled
    );
  }, [
    watch('agencyNumber'),
    watch('name'),
    watch('accountNumber'),
    watch('description'),
    watch('bankAccountType'),
  ]);

  const onSubmit = (bankAccount) => {
    let statusResponse = false;
    let toastContent = '';

    console.log('onSubmit', bankAccount);

    let newBankAccount = {
      companyId: companyId,
      // isPrincipal: bankAccount.mainAccount,
      name: bankAccount.name,
      isInput: !checked,
      accountType: bankAccount.bankAccountType,
      description: bankAccount.description,
      agencyNumber: bankAccount.agencyNumber,
      accountBankNumber: bankAccount.accountNumber,
      initialBalance: Number(
        bankAccount.initialBalance.replace(/\./g, '').replace(',', '.')
      ),
    };

    console.log('newBankAccount', newBankAccount);

    postBank(newBankAccount)
      .then((res) => {
        console.log('res post bank: ', res);
        statusResponse = 'success';
        toastContent = 'Conta cadastrada com sucesso.';
        onClose();
        mutate('banks');
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  const handleInput = (event) => {
    let inputVal = event.target.value.replace(/\D/g, '');
    if (inputVal === '') {
      setValue('initialBalance', '');
      return;
    }
    inputVal = inputVal.padStart(3, '0');
    const wholeNum = inputVal.slice(0, -2);
    const decimal = inputVal.slice(-2);
    const formattedWholeNum = Number(wholeNum).toLocaleString();
    const formattedValue = `${formattedWholeNum},${decimal}`;

    // Convert the formatted value to a number with two decimal places
    const numericValue = Number(
      formattedValue.replace(/\./g, '').replace(',', '.')
    );
    setValue('initialBalance', formattedValue);
    console.log(numericValue); // Output the numeric value to the console
    console.log(watch('initialBalance')); // Output the numeric value to the console
  };

  const bankAccountFormFooter = () => {
    return (
      <Pane>
        <Button
          form='bankAccount-form'
          type='submit'
          width='fit-content'
          id='onSubmit'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  return (
    <Dialog
      isShown={isShown}
      title={'Cadastrar Conta'}
      onCloseComplete={onClose}
      onConfirm={() => {}}
      confirmLabel='Cadastrar'
      hasFooter={true}
      footer={bankAccountFormFooter}
      shouldCloseOnEscapePress={!formState.isDirty}
      width={600}
      backgroundColor='#f8f8f8'
    >
      <div>
        <form id='bankAccount-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('name', { required: true })}
                    label='Nome'
                    id='name'
                    name='name'
                    type='text'
                  />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('bankAccountType')}
                    label='Tipo de Conta'
                    onChange={(e) =>
                      setValue('bankAccountType', e.target.value)
                    }
                    defaultValue={undefined}
                  >
                    <option value={undefined}></option>
                    {bankAccountTypeArray.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('agencyNumber', {
                      required: true,
                    })}
                    label='Agência'
                    id='agencyNumber'
                    name='agencyNumber'
                    type='text'
                  />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('accountNumber', {
                      required: true,
                    })}
                    label='Número da Conta'
                    id='accountNumber'
                    name='accountNumber'
                    type='text'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInputField
                    {...register('description')}
                    label='Descrição'
                    id='description'
                    name='description'
                    type='text'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInputField
                    {...register('initialBalance', {
                      required: true,
                      // min: 0,
                      // valueAsNumber: true,
                    })}
                    defaultValue='0,00'
                    label='Valor (R$)'
                    type='text'
                    // min='0,00'
                    id='initialBalance'
                    name='initialBalance'
                    placeholder=''
                    // step='0.01'
                    onChange={handleInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    {...register('isNegative')}
                    label='Saldo negativo'
                    id='isNegative'
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default BankAccountsFormComponent;
