import React from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  IconButton,
  SelectField,
  CrossIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Switch,
} from 'evergreen-ui';

import { mutate } from 'swr';
import { toastNotification } from '../../shared/toastNotification';

import { Container, Row, Col } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { postInviteUserRoles } from '../../services/api/userRolesService';
import { useSelector } from 'react-redux';

const InviteUserForm = () => {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({});

  const inviteUser = () => {
    let statusResponse = '';
    let toastContent = '';
    const user = {
      adminId: userId,
      companyId: companyId,
      email: getValues('email'),
      userRole: getValues('role'),
    };
    postInviteUserRoles(user)
      .then((res) => {
        console.log('res post invited user: ', res);
        mutate('userRolesWithUserId');
        statusResponse = 'success';
        toastContent = 'Usuário convidado com sucesso.';
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = err.data;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
      });
  };

  return (
    <form id='invite-form' onSubmit={handleSubmit(inviteUser)}>
      <FormField>
        <Container
          fluid={true}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '300px',
          }}
        >
          <TextInputField
            {...register('email')}
            label='E-mail'
            id='invite-email'
            name='email'
            type='email'
            onChange={(e) => {
              setValue('email', e.target.value);
            }}
          />

          <SelectField
            {...register('role')}
            label='Nível de Acesso'
            defaultValue={undefined}
            onChange={(e) => setValue('role', e.target.value)}
          >
            <option value={undefined}></option>
            <option value='ADMIN'>ADMIN</option>
            <option value='ANALISTA'>ANALISTA</option>
            <option value='CONSULTOR'>CONSULTOR</option>
            <option value='LANÇADOR'>LANÇADOR</option>
          </SelectField>

          <Button
            type='submit'
            appearance='primary'
            intent='success'
            alignSelf='center'
          >
            Convidar
          </Button>
        </Container>
      </FormField>
    </form>
  );
};

export default InviteUserForm;
