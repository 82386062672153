import { createSlice } from '@reduxjs/toolkit';
import { firstDay, lastDay } from '../helpers/dateConverter';

const initialDateState = {
  startDate: firstDay(
    new Date().getMonth(),
    new Date().getFullYear()
  ),
  endDate: lastDay(
    new Date().getMonth(),
    new Date().getFullYear()
  ),
  startDateTemp: firstDay(
    new Date().getMonth(),
    new Date().getFullYear()
  ),
  endDateTemp: lastDay(
    new Date().getMonth(),
    new Date().getFullYear()
  ),
  filterType: 'dateVencimento',
  filterYear: new Date().getFullYear(),
  filteredEntries: [],
  startDreDate: firstDay(0, new Date().getFullYear()),
  endDreDate: lastDay(11, new Date().getFullYear()),
  dreYear: new Date().getFullYear(),
  dreArray: [],
};

const dateFilterSlice = createSlice({
  name: 'dateFilter',
  initialState: initialDateState,
  reducers: {
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
    setStartDateTemp(state, action) {
      state.startDateTemp = action.payload;
    },
    setEndDateTemp(state, action) {
      state.endDateTemp = action.payload;
    },
    setDreStartDate(state, action) {
      state.startDreDate = action.payload;
    },
    setDreEndDate(state, action) {
      state.endDreDate = action.payload;
    },
    setDreArray(state, action) {
      state.dreArray = action.payload;
    },

    resetFilter(state) {
      state.startDate = firstDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.endDate = lastDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.startDateTemp = firstDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.endDateTemp = lastDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.filterYear = new Date().getFullYear();
      state.filterType = 'dateVencimento';
    },
    setFilterType(state, action) {
      state.filterType = action.payload;
    },
    setFilteredEntries(state, action) {
      state.filteredEntries = action.payload;
    },
    increaseYear(state, action) {
      state.filterYear = state.filterYear + 1;
    },
    decreaseYear(state, action) {
      state.filterYear = state.filterYear - 1;
    },
    increaseDreYear(state, action) {
      state.dreYear = state.dreYear + 1;
    },
    decreaseDreYear(state, action) {
      state.dreYear = state.dreYear - 1;
    },
  },
});

export const dateFilterActions = dateFilterSlice.actions;

export default dateFilterSlice.reducer;
