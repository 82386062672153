import { convertDate } from '../../helpers/dateConverter';
import apiClient from './apiClient';

const request = async function (options, store) {
  // success handler
  const onSuccess = function (response) {
    if (
      response.config.url.includes('ByDate') ||
      response.config.url.includes('Reconciliation')
    ) {
      response.data?.map((entry) => {
        // entry.dateVencimento = entry.dateVencimento.substr(0, 10);
        // entry.dateCompetencia = entry.dateCompetencia.substr(0, 10);
        entry.paymentType = 1;
        if (entry.fitId !== null) {
          entry.isReconciliated = 'Sim';
        } else {
          entry.isReconciliated = 'Não';
        }
        if (entry.statusPayment === true) {
          entry.statusString = 'Pago';
        } else if (
          entry.dateVencimento < convertDate(new Date()) &&
          entry.journalEntryType === 1
        ) {
          entry.statusString = 'Vencido';
        } else {
          entry.statusString = 'Pendente';
        }
        // if (entry.journalEntryType === 1) {
        if (entry.isInput) {
          entry.entryType = 'Entrada';
        } else {
          entry.entryType = 'Saída';
        }
        // }
        if (entry.journalEntryType === 5) {
          entry.entryType = 'Transferência';
        }
        if (entry.journalEntryType === 2) {
          entry.entryType = 'Saldo Inicial';
        }
        entry.dateVencimento = entry.dateVencimento.substr(0, 10);
        entry.dateCompetencia = entry.dateCompetencia.substr(0, 10);

        return entry;
      });
    }

    return response;
  };

  // error handler
  const onError = function (error) {
    return Promise.reject(error.response);
  };

  // adding success and error handlers to apiClient
  return apiClient(options).then(onSuccess).catch(onError);
};

export default request;
