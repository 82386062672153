import React from 'react';
import { Outlet } from 'react-router-dom';

import MainOutletComponent from './MainOutletComponent';
import Navbar from './Navbar';
import { useQueryCompany } from '../../services/api/companiesService';
import { Pane } from 'evergreen-ui';

function LayoutPage() {
  // const queryEntries = useQuery(["entries", dataCompany.data.id], getEntries, {
  //   staleTime: Infinity,
  // });

  return (
    <Pane
      className='LayoutPage'
      flexDirection='column'
      display='flex'
      height='100%'
      width='100%'
      overflow='hidden'
    >
      <Navbar />

      <Pane
        className='OutletDashboard'
        display='flex'
        flex={1}
        overflow='hidden'
      >
        <Outlet />
      </Pane>

      {/* <MainOutletComponent><Outlet /></MainOutletComponent> */}

      <style jsx='true' scoped={true}>
        {`
          .OutletDashboard {
            background: linear-gradient(
              257.63deg,
              rgba(9, 153, 157, 0.25) -0.36%,
              rgba(59, 128, 177, 0.25) 14.31%,
              rgba(0, 36, 164, 0.25) 40.45%,
              rgba(169, 107, 231, 0.25) 88.1%,
              rgba(243, 72, 91, 0.25) 120.83%
            );
            background: #8591bc40;
          }
        `}
      </style>
    </Pane>
  );
}

export default LayoutPage;
