import React, { useState } from 'react';

import { Button, Pane, Table, TextInput, TrashIcon } from 'evergreen-ui';

import {
  useQueryBanks,
  useQueryBankBalances,
  deleteBank,
} from '../../services/api/banksService';
import BankAccountsFormComponent from './BankAccountsFormComponent';
import BankAccountsEditFormComponent from './BankAccountsEditFormComponent';
import { toastNotification } from '../../shared/toastNotification';
import { mutate } from 'swr';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function BankAccountsPage() {
  /* API calls */

  const companyId = localStorage.getItem('companyId');

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const [isShownBanksDialog, setIsShowBanksDialog] = useState(false);
  const [isShownBanksEditDialog, setIsShowBanksEditDialog] = useState(false);
  const [bankOnFocus, setBankOnFocus] = useState();
  const [localSearch, setLocalSearch] = useState('');

  const deleteHandler = (bankId) => {
    let statusResponse = false;
    let toastContent = '';
    deleteBank(bankId)
      .then((res) => {
        console.log('res post bank: ', res);
        statusResponse = 'success';
        toastContent = 'Conta excluída com sucesso.';
        mutate('banks');
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  const openBanksDialog = () => {
    setIsShowBanksDialog(true);
  };
  const openBanksEditDialog = (bank) => {
    setIsShowBanksEditDialog(true);
    setBankOnFocus(bank);
  };

  useEffect(() => {
    mutate('banks');
  }, []);

  if (isLoadingBanks) {
    return <h2> Loading... </h2>;
  }

  return (
    <>
      <BankAccountsEditFormComponent
        isShown={isShownBanksEditDialog}
        setIsShown={setIsShowBanksEditDialog}
        bankAccount={bankOnFocus}
        // isPrincipal={isPrincipal}
        // setIsPrincipal={setIsPrincipal}
      />
      <BankAccountsFormComponent
        isShown={isShownBanksDialog}
        setIsShown={setIsShowBanksDialog}
      />
      <Pane
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        overflowY='scroll'
        padding='8px'
      >
        <Pane display='flex' flexDirection='column' gap={6}>
          <Button
            appearance='primary'
            intent='info'
            marginBottom={8}
            maxWidth='fit-content'
            onClick={openBanksDialog}
          >
            Cadastrar Conta
          </Button>
          <TextInput
            placeholder='Filtrar'
            marginBottom={12}
            width='200px'
            onChange={(e) => setLocalSearch(e.target.value.toLowerCase())}
          />
        </Pane>
        <Table className='banksTable'>
          <Table.Head>
            <Table.TextHeaderCell>Nome</Table.TextHeaderCell>
            <Table.TextHeaderCell>Descrição</Table.TextHeaderCell>
            <Table.TextHeaderCell>Tipo</Table.TextHeaderCell>
            <Table.TextHeaderCell>Agência</Table.TextHeaderCell>
            <Table.TextHeaderCell>Nº da Conta</Table.TextHeaderCell>
            <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {!isLoadingBanks &&
              banks.data.map((bank) => {
                if (
                  bank.name.toLowerCase().includes(localSearch) ||
                  bank.description.toLowerCase().includes(localSearch) ||
                  bank.accountType.toLowerCase().includes(localSearch) ||
                  bank.agencyNumber.toLowerCase().includes(localSearch) ||
                  bank.accountBankNumber.toLowerCase().includes(localSearch)
                )
                  return (
                    <Table.Row
                      key={bank.id}
                      isSelectable
                      onSelect={() => openBanksEditDialog(bank)}
                    >
                      <Table.TextCell>{bank.name}</Table.TextCell>
                      <Table.TextCell>{bank.description}</Table.TextCell>
                      <Table.TextCell>{bank.accountType}</Table.TextCell>
                      <Table.TextCell>{bank.agencyNumber}</Table.TextCell>
                      <Table.TextCell>{bank.accountBankNumber}</Table.TextCell>
                      <Table.TextCell
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHandler(bank.id);
                        }}
                      >
                        <TrashIcon className='trash-icon' />
                      </Table.TextCell>
                    </Table.Row>
                  );
              })}
          </Table.Body>
        </Table>
      </Pane>
    </>
  );
}

export default BankAccountsPage;
