import { Pane } from 'evergreen-ui';
import React from 'react';

export default function DividerComponent(props) {
  return (
    <Pane
      display='flex'
      flex={1}
      width='100%'
      height={0.5}
      backgroundColor='#e2e2e2'
      marginY={8}
      {...props}
    ></Pane>
  );
}
