import { Heading, Pane, Text } from 'evergreen-ui';
import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const AccumulatedBalance = ({ accumulatedBalance }) => {
  const CustomTooltip = ({ props, payload }) => {
    return (
      <Pane
        backgroundColor='#9b9b9b'
        padding={10}
        display='flex'
        flexDirection='column'
        gap={10}
      >
        <Heading size={600} textAlign='center' fontWeight='bold'>
          {payload?.[0]?.payload?.name}
        </Heading>
        <Pane>
          <Text fontWeight='bold'>Entrada: </Text>
          <Text fontWeight='bold' color='#028090'>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(payload[0]?.value)}
          </Text>
        </Pane>
        <Pane>
          <Text fontWeight='bold'>Saída: </Text>
          <Text fontWeight='bold' color='#F3485B'>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(payload[1]?.value)}
          </Text>
        </Pane>
        <Pane>
          <Text fontWeight='bold'>Saldo Acumulado: </Text>
          <Text
            fontWeight='bold'
            color={payload[2]?.value < 0 ? '#F3485B' : '#028090'}
          >
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(payload[2]?.value)}
          </Text>
        </Pane>
      </Pane>
    );
  };
  return (
    <ResponsiveContainer width='100%' aspect={2}>
      <ComposedChart
        data={accumulatedBalance}
        // width={300}
        // height='90%'
        margin={{
          top: 20,
          right: 36.8,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid
          stroke='#f5f5f5'
          strokeDasharray='1'
          horizontal='true'
          vertical=''
        />
        <XAxis dataKey='name' padding={{ left: 10, right: 10 }} />
        <YAxis
        // label={{
        //   value: 'Lançamentos',
        //   angle: -90,
        //   position: 'left',
        // }}
        />

        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine y={0} />
        <Bar dataKey='Entrada' barSize={20} fill='#028090' />
        <Bar dataKey='Saída' barSize={20} fill='#F3485B' />
        <Line
          type='monotone'
          dataKey='Saldo Acumulado'
          stroke='white'
          strokeWidth={2}
          activeDot={{ r: 6, stroke: 'green', strokeWidth: 5 }}
          dot={<CustomizedDot />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  if (value > 0) {
    return (
      <svg x={cx - 8} y={cy - 8}>
        <circle
          cx='8'
          cy='8'
          r='5'
          stroke='black'
          strokeWidth='1'
          fill='#4d8742'
        />
      </svg>
    );
  }

  if (value < 0) {
    return (
      <svg x={cx - 8} y={cy - 8}>
        <circle
          cx='8'
          cy='8'
          r='5'
          stroke='black'
          strokeWidth='1'
          fill='#cc5151'
        />
      </svg>
    );
  }
  return (
    <svg x={cx - 8} y={cy - 8}>
      <circle
        cx='8'
        cy='8'
        r='5'
        stroke='black'
        strokeWidth='1'
        fill='#9e9e9e'
      />
    </svg>
  );
};

export default AccumulatedBalance;
