import { React, useState } from 'react';

import { auth } from '../../firebase-config';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword,
  updatePhoneNumber,
  updateProfile,
} from 'firebase/auth';

import {
  BanCircleIcon,
  Button,
  Card,
  FormField,
  Heading,
  TextInputField,
  EyeOpenIcon,
  EyeOffIcon,
  Tooltip,
  Position,
  Text,
} from 'evergreen-ui';

import { signUp } from '../../services/api/signInUpService';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { toastNotification } from '../../shared/toastNotification';
import { checkString } from '../../helpers/signInUpValidation';
import { postVitaUser } from '../../services/api/vitaUserService';

export default function SignUpComponent({ setLogin }) {
  const [loading, setLoading] = useState(false);
  const [alertIsShown, setAlertIsShown] = useState(false);

  const navigate = useNavigate();

  const fetchSignUp = (formData) => {
    setLoading(true);
    const signUpObject = {
      email: formData.email.toLowerCase().trim(),
      password: formData.password.trim(),
      name: formData.name.trim(),
      lastName: formData.lastName,
      // phoneNumber: formData.phoneNumber,
    };

    let statusResponse = '';
    let toastContent = '';

    createUserWithEmailAndPassword(
      auth,
      signUpObject.email,
      signUpObject.password
    )
      .then(async (createdUser) => {
        await updateProfile(auth.currentUser, {
          displayName: signUpObject.name + ' ' + signUpObject.lastName,
        });

        console.log('createdUser: ', auth.currentUser);
        await postVitaUser({
          email: signUpObject.email,
          name: signUpObject.name,
          lastName: signUpObject.lastName,
          // phoneNumber: signUpObject.phoneNumber,
          identityUserId: createdUser.user.uid,
        })
          .then((res) => {
            console.log('res post user: ', res);
            statusResponse = 'success';
            toastContent = 'Conta criada com sucesso.';
            navigate('/termos-de-uso');
          })
          .catch((err) => {
            deleteUser(auth.currentUser).then(() => {
              console.log('User deleted');
            });
            console.log('post err', err);
            statusResponse = 'warning';
            toastContent =
              'Erro ao criar usuário. Verifique os campos e tente novamente.';
          });
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-email') {
          statusResponse = 'warning';
          toastContent = 'Por favor, insira um e-mail válido.';
        } else if (error.code === 'auth/email-already-in-use') {
          statusResponse = 'warning';
          toastContent = 'E-mail já cadastrado.';
        } else if (error.code === 'auth/weak-password') {
          statusResponse = 'warning';
          toastContent = 'Sua senha deve conter pelo menos 6 caracteres.';
        } else {
          statusResponse = 'warning';
          toastContent =
            'Parece que falhamos aqui. Por favor, tente novamente.';
          console.log(error.code);
        }
      })
      .finally(() => {
        setLoading(false);
        if (statusResponse == 'success') setLogin(true);

        toastNotification(statusResponse, toastContent);
      });
  };

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });
  const {
    errors,
    dirtyFields,
    isDirty,
    isSubmitting,
    touchedFields,
    submitCount,
  } = formState;

  const [inputType, setInputType] = useState(true);
  const [iconState, setIconState] = useState(true);

  const togglePassword = () => {
    setInputType((old) => !old);
    setIconState((old) => !old);
  };

  return (
    <Card
      elevation={4}
      float='left'
      background='white'
      margin={24}
      padding={16}
      display='flex'
      gap={16}
      height='fit-content'
      // justifyContent="center"
      // alignItems="center"
      flexDirection='column'
    >
      <Heading size={700}>Registrar</Heading>

      <form id='signup-form' onSubmit={handleSubmit(fetchSignUp)}>
        <FormField display='flex' flexDirection='column' gap={0}>
          <div style={{ display: 'flex' }}>
            <TextInputField
              {...register(
                'name',

                {
                  maxLength: {
                    value: 50,
                    message: 'Máximo de 50 caracteres.',
                  },
                  required: 'Obrigatório.',
                }
              )}
              minWidth={250}
              label='Nome'
              type='text'
              validationMessage={errors?.name?.message}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <TextInputField
              {...register(
                'lastName',

                {
                  maxLength: {
                    value: 50,
                    message: 'Máximo de 50 caracteres.',
                  },
                  required: 'Obrigatório.',
                }
              )}
              minWidth={250}
              label='Sobrenome'
              type='text'
              validationMessage={errors?.lastName?.message}
            />
          </div>
          {/* <div style={{ display: 'flex' }}>
            <TextInputField
              {...register(
                'phoneNumber',

                {
                  pattern: {
                    value: /^[0-9]{10,11}$/,
                    message: 'Digite somente números.',
                  },
                  minLength: {
                    value: 10,
                    message: 'Digite um telefone válido.',
                  },
                  maxLength: {
                    value: 11,
                    message: 'Digite um telefone válido.',
                  },
                  required: 'Obrigatório.',
                }
              )}
              minWidth={250}
              label='Telefone'
              type='tel'
              validationMessage={errors?.phoneNumber?.message}
            />
          </div> */}
          <div style={{ display: 'flex' }}>
            <TextInputField
              {...register(
                'email',

                {
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.]+@[a-zA-Z0-9]{2,}.[a-zA-Z]{2,}(.?[a-z]{2,3})?$/gm,
                    message: 'Digite um e-mail válido.',
                  },
                  required: 'Obrigatório.',
                }
              )}
              minWidth={250}
              label='E-mail'
              type='email'
              validationMessage={errors?.email?.message}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <TextInputField
              {...register(
                'password',

                {
                  required: 'Obrigatório.',
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/gm,
                    // message: 'Padrão incorreto.',
                  },
                  minLength: {
                    value: 6,
                    // message: 'A senha deve conter pelo menos 6 caracteres',
                  },
                }
              )}
              type={inputType ? 'password' : 'text'}
              label='Senha'
              validationMessage={errors.password?.message}
              width='100%'
              marginBottom={5}
            />
            {iconState ? (
              <Tooltip
                content={<Text fontSize={12}>Mostrar senha</Text>}
                position={Position.TOP}
                showDelay={300}
              >
                <EyeOffIcon
                  color='gray'
                  onClick={togglePassword}
                  alignSelf='center'
                  marginLeft={-25}
                  marginTop={18}
                  cursor='pointer'
                  opacity='50%'
                  size={18}
                />
              </Tooltip>
            ) : (
              <Tooltip
                content={<Text fontSize={12}>Ocultar senha</Text>}
                position={Position.TOP}
                showDelay={300}
              >
                <EyeOpenIcon
                  color='gray'
                  onClick={togglePassword}
                  alignSelf='center'
                  marginLeft={-25}
                  marginTop={18}
                  cursor='pointer'
                />
              </Tooltip>
            )}
          </div>
          {touchedFields && checkString(watch('password'))}

          <TextInputField
            {...register('passwordConfirmation', {
              // required: 'Obrigatório.',
              validate: (val) => {
                if (watch('password') != val) {
                  return 'Senhas não coincidem.';
                }
              },
            })}
            type={inputType ? 'password' : 'text'}
            label='Repetir senha'
            validationMessage={errors.passwordConfirmation?.message}
          />
          {/* {errors?.passwordConfirmation && (
            <p>password cannot less than 5 characters</p>
          )} */}
          <Button
            marginRight={16}
            appearance='minimal'
            color='blue'
            size='large'
            margin={0}
            isLoading={loading}
            form='signup-form'
            type='submit'
          >
            Registrar
          </Button>
        </FormField>
      </form>
      <Card
        display='flex'
        flexDirection='column'
        gap={0}
        justifyContent='space-between'
      ></Card>
      <style jsx='true'>{``}</style>
    </Card>
  );
}
