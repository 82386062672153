import { createSlice } from '@reduxjs/toolkit';
import { firstDay, lastDay } from '../helpers/dateConverter';
import { months } from '../components/dashboard/dashboardHelper';

const initialDashboardValuesState = {
  filterType: 'dateVencimento',
  entryStatus: 'Todos',
  filterYear: new Date().getFullYear(),
  startDashboardDate: firstDay(0, new Date().getFullYear()),
  endDashboardDate: lastDay(11, new Date().getFullYear()),
  dashboardYear: new Date().getFullYear(),
  allMonthsSelected: true,
  selectedMonths: months.map((month) => month.id),
};

const dashboardValuesSlice = createSlice({
  name: 'dashboardValues',
  initialState: initialDashboardValuesState,
  reducers: {
    setDashboardStartDate(state, action) {
      state.startDashboardDate = action.payload;
    },
    setDashboardEndDate(state, action) {
      state.endDashboardDate = action.payload;
    },
    resetFilter(state) {
      state.startDate = firstDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.endDate = lastDay(new Date().getMonth(), new Date().getFullYear());
      state.startDateTemp = firstDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.endDateTemp = lastDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.filterYear = new Date().getFullYear();
      state.filterType = 'dateVencimento';
    },
    setFilterType(state, action) {
      state.filterType = action.payload;
    },
    setEntryStatus(state, action) {
      state.entryStatus = action.payload;
    },
    increaseDashboardYear(state, action) {
      state.dashboardYear = state.dashboardYear + 1;
    },
    decreaseDashboardYear(state, action) {
      state.dashboardYear = state.dashboardYear - 1;
    },
    setAllMonthsSelected(state, action) {
      state.allMonthsSelected = action.payload;
    },
    setSelectedMonths(state, action) {
      state.selectedMonths = action.payload;
    },
  },
});

export const dashboardValuesActions = dashboardValuesSlice.actions;

export default dashboardValuesSlice.reducer;
