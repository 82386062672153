import React from 'react';
import HeaderSignComponent from './HeaderSignComponent';
import ResetPasswordCard from './ResetPasswordCard';
import NewPasswordCard from './NewPasswordCard';

const NewPasswordPage = () => {
  return (
    <div className='container'>
      <NewPasswordCard />
      <style jsx='true'>
        {`
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        `}
      </style>
    </div>
  );
};

export default NewPasswordPage;
