import {
  Button,
  Combobox,
  CrossIcon,
  Dialog,
  FormField,
  Heading,
  Icon,
  IconButton,
  Pane,
  ShopIcon,
  TextInputField,
} from 'evergreen-ui';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { postCompany } from '../../services/api/companiesService';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { toastNotification } from '../../shared/toastNotification';
import { useDispatch, useSelector } from 'react-redux';
import { loginValuesActions } from '../../store/loginValues';

function CreateCompanyForm({ isShown, setIsShown }) {
  const userId = localStorage.getItem('userId');

  const dispatch = useDispatch();

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId && userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { register, handleSubmit, getValues, setValue, reset } = useForm({});

  const onClose = () => {
    reset();
    setIsShown(false);
  };

  const buildFormHeader = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='space-between'>
        <Pane
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          gap={16}
        >
          <Icon icon={ShopIcon} color='selected' />
          <Heading size={700}>Nova empresa</Heading>
        </Pane>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='flex-end'>
        <Button
          form='company-form'
          type='submit'
          appearance='primary'
          marginY={8}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (company) => {
    let statusResponse = false;
    let toastContent = '';

    let companyToCreate = {
      cpfCnpj: company.cpfCnpj,
      fieldActivity: company.fieldActivity,
      name: company.name,
      userId: !isLoadingVitaUser && vitaUser.data.id,
    };

    console.log('submit company: ', companyToCreate);

    postCompany(companyToCreate)
      .then((res) => {
        console.log('res post user: ', res);
        statusResponse = 'success';
        toastContent = 'Empresa criada com sucesso.';
        localStorage.setItem('companyId', res.data.id);
        mutate('vitaUserByIdentityId');
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  if (isLoadingVitaUser) {
    return <LoadingOverlay />;
  }

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={buildFormHeader}
      footer={buildFormFooter}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <form id='company-form' onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <Container
            fluid={true}
            style={{
              width: '100%',
              margin: 0,
              padding: 0,
            }}
          >
            <Row>
              <Col sm={12} md={12} lg={12}>
                <TextInputField
                  padding={0}
                  {...register('name')}
                  label='Nome'
                  id='name'
                  name='name'
                  type='text'
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInputField
                  {...register('cpfCnpj', {
                    required: true,
                  })}
                  label='CPF ou CNPJ'
                  id='cpfCnpj'
                  name='cpfCnpj'
                  placeholder=''
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={12} lg={12}>
                <Heading size={400} marginBottom={8}>
                  Área de atuação
                </Heading>
                <Combobox
                  label='Área de atuação'
                  {...register('fieldActivity')}
                  initialSelectedItem={''}
                  items={[
                    {
                      id: 1,
                      name: '',
                    },
                    {
                      id: 2,
                      name: 'Comércio',
                    },
                    { id: 3, name: 'Indústria' },
                    {
                      id: 4,
                      name: 'Serviços',
                    },
                  ]}
                  itemToString={(item) => {
                    return item?.name ?? '';
                  }}
                  onChange={(selected) => {
                    console.log('tipo empresa: selected: ', selected);
                    setValue('fieldActivity', selected.name);
                  }}
                  marginBottom={24}
                  width='100%'
                />
              </Col>
            </Row>
          </Container>
        </FormField>
      </form>
    </Dialog>
  );
}

export default CreateCompanyForm;
