import React, { useState } from 'react';

import {
  TextInputField,
  FormField,
  Button,
  SelectField,
  Tooltip,
  EyeOffIcon,
  EyeOpenIcon,
  Position,
  Text,
} from 'evergreen-ui';
import { Container, Row, Col } from 'react-grid-system';
import { useForm } from 'react-hook-form';

import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
} from 'firebase/auth';
import { mutate } from 'swr';
import { toastNotification } from '../../shared/toastNotification';
import { postEmployee } from '../../services/api/vitaUserService';
import { firebaseConfig } from '../../firebase-config';
import { initializeApp } from 'firebase/app';
import { useSelector } from 'react-redux';
import { checkString } from '../../helpers/signInUpValidation';

const CreateEmployeeForm = () => {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');
  const [inputType, setInputType] = useState(true);
  const [iconState, setIconState] = useState(true);

  const togglePassword = () => {
    setInputType((old) => !old);
    setIconState((old) => !old);
  };

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      createName: '',
      createLastname: '',
      createEmail: '',
      createRole: '',
      createPassword: '',
      confirmPassword: '',
    },
  });

  const {
    errors,
    dirtyFields,
    isDirty,
    isSubmitting,
    touchedFields,
    submitCount,
  } = formState;

  const createNewEmployee = async (employeeForm) => {
    let statusResponse = '';
    let toastContent = '';
    let uid = '';

    const newEmployee = {
      name: employeeForm.createName,
      lastName: employeeForm.createLastname,
      email: employeeForm.createEmail,
      identityUserId: uid,
      // phoneNumber: employeeForm.createPhoneNumber,
      adminId: userId,
      companyId: companyId,
      userRole: employeeForm.createRole,
    };

    console.log('newEmployee', newEmployee);

    const tempApp = initializeApp(firebaseConfig, 'tempApp');
    const tempAuth = getAuth(tempApp);

    await createUserWithEmailAndPassword(
      tempAuth,
      employeeForm.createEmail,
      employeeForm.createPassword
    )
      .then((userCredential) => {
        const user = tempAuth.currentUser;
        console.log('userCredential: ', userCredential);
        console.log('user tempauth: ', user);
        newEmployee.identityUserId = userCredential.user.uid;

        postEmployee(newEmployee)
          .then((res) => {
            console.log('res post employee: ', res);
            statusResponse = 'success';
            toastContent = 'Usuário criado com sucesso.';
            mutate('userRolesWithUserId');
            reset();
          })
          .catch((err) => {
            statusResponse = 'warning';
            console.log('post err', err);
            toastContent = `Verifique os campos novamente.`;
            deleteUser(userCredential.user).then(() => {
              console.log('user deleted');
            });
          })
          .finally(() => {
            toastNotification(statusResponse, toastContent);
            tempAuth.signOut();
          });
      })
      .catch((error) => {
        console.log(error.code);
        if (error.code === 'auth/invalid-email') {
          statusResponse = 'warning';
          toastContent = 'Por favor, insira um e-mail válido.';
        } else if (error.code === 'auth/email-already-in-use') {
          statusResponse = 'warning';
          toastContent = 'E-mail já cadastrado.';
        } else if (error.code === 'auth/weak-password') {
          statusResponse = 'warning';
          toastContent = 'Sua senha deve conter pelo menos 6 caracteres.';
        } else {
          statusResponse = 'warning';
          toastContent =
            'Parece que falhamos aqui. Por favor, tente novamente.';
        }
        toastNotification(statusResponse, toastContent);
      });
  };

  return (
    <form id='create-form' onSubmit={handleSubmit(createNewEmployee)}>
      <FormField>
        <Container
          fluid={true}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <Row>
            <Col>
              <TextInputField
                {...register('createName', {
                  maxLength: {
                    value: 50,
                    message: 'Máximo de 50 caracteres.',
                  },
                  required: 'Obrigatório.',
                })}
                label='Nome'
                id='name'
                name='name'
                validationMessage={errors?.createName?.message}
                onChange={(e) => {
                  setValue('createName', e.target.value);
                }}
              />
            </Col>
            <Col>
              <TextInputField
                {...register('createLastname', {
                  maxLength: {
                    value: 50,
                    message: 'Máximo de 50 caracteres.',
                  },
                  required: 'Obrigatório.',
                })}
                label='Sobrenome'
                id='sobrenome'
                name='sobrenome'
                validationMessage={errors?.createLastname?.message}
                onChange={(e) => {
                  setValue('createLastname', e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectField
                {...register('createRole')}
                label='Nível de Acesso'
                defaultValue={undefined}
                onChange={(e) => setValue('createRole', e.target.value)}
              >
                <option value={undefined}></option>
                <option value='ADMIN'>ADMIN</option>
                <option value='ANALISTA'>ANALISTA</option>
                <option value='CONSULTOR'>CONSULTOR</option>
                <option value='LANÇADOR'>LANÇADOR</option>
              </SelectField>
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInputField
                {...register('createEmail', {
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.]+@[a-zA-Z0-9]{2,}.[a-zA-Z]{2,}(.?[a-z]{2,3})?$/gm,
                    message: 'Digite um e-mail válido.',
                  },
                  required: 'Obrigatório.',
                })}
                label='E-mail'
                id='email'
                name='email'
                type='email'
                validationMessage={errors?.createEmail?.message}
                onChange={(e) => {
                  setValue('createEmail', e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ display: 'flex', position: 'relative' }}>
                <TextInputField
                  {...register('createPassword', {
                    required: 'Obrigatório.',
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/gm,
                      // message: 'Padrão incorreto.',
                    },
                    minLength: {
                      value: 6,
                      // message: 'A senha deve conter pelo menos 6 caracteres',
                    },
                  })}
                  label='Senha'
                  id='createPassword'
                  name='senha'
                  type={inputType ? 'password' : 'text'}
                  validationMessage={errors.createPassword?.message}
                  onChange={(e) => {
                    setValue('createPassword', e.target.value);
                  }}
                />
                {iconState ? (
                  <div style={{ position: 'absolute', right: 2, top: 15 }}>
                    <Tooltip
                      content={<Text fontSize={12}>Mostrar senha</Text>}
                      position={Position.TOP}
                      showDelay={300}
                    >
                      <EyeOffIcon
                        color='gray'
                        onClick={togglePassword}
                        alignSelf='center'
                        marginLeft={-25}
                        marginTop={18}
                        cursor='pointer'
                        opacity='50%'
                        size={18}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div style={{ position: 'absolute', right: 2, top: 15 }}>
                    <Tooltip
                      content={<Text fontSize={12}>Ocultar senha</Text>}
                      position={Position.TOP}
                      showDelay={300}
                    >
                      <EyeOpenIcon
                        color='gray'
                        onClick={togglePassword}
                        alignSelf='center'
                        marginLeft={-25}
                        marginTop={18}
                        cursor='pointer'
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <TextInputField
                {...register('confirmPassword', {
                  // required: 'Obrigatório.',
                  validate: (val) => {
                    if (watch('createPassword') != val) {
                      return 'Senhas não coincidem.';
                    }
                  },
                })}
                type={inputType ? 'password' : 'text'}
                label='Confirmar senha'
                validationMessage={errors.confirmPassword?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>{touchedFields && checkString(watch('createPassword'))}</Col>
          </Row>

          <Button
            type='submit'
            appearance='primary'
            intent='success'
            alignSelf='center'
          >
            Cadastrar
          </Button>
        </Container>
      </FormField>
    </form>
  );
};

export default CreateEmployeeForm;
