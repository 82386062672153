// Array de meses
export const monthsArray = [
  'janeiro',
  'fevereiro',
  'marco',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

// Objeto de meses com seus indices (0-based)
export const indexToMonthObject = {
  0: 'janeiro',
  1: 'fevereiro',
  2: 'marco',
  3: 'abril',
  4: 'maio',
  5: 'junho',
  6: 'julho',
  7: 'agosto',
  8: 'setembro',
  9: 'outubro',
  10: 'novembro',
  11: 'dezembro',
};

// Array com todas as classificações de DRE
export const arrayDre = [
  'Receita Bruta',
  'Desconto de Faturamento',
  'Receita Líquida',
  'CPV/CMV/CSP',
  'Margem Bruta',
  'Despesas Gerais',
  'EBITDA',
  'Depreciações e Amortizações',
  'Investimentos',
  'EBIT',
  'Receitas Financeiras',
  'Despesas Financeiras',
  'Receitas não operacionais',
  'Despesas não operacionais',
  'Lucro Líquido',
];
// Arrays com os nomes unicos (Grouping, AccPlan, Category)
let arrayGrouping = [];
let arrayAccPlan = [];
let arrayCategory = [];

// Arrays com os lançamentos unicos (DRE, Grouping, AccPlan, Category)
let dreEntries = [];
let groupingEntries = [];
let accPlanEntries = [];
let categoryEntries = [];
// Array com os lançamentos normais formatados
let normalEntries = [];

// Modelo de objeto para cada lançamento
const entryModel = {
  janeiro: 0,
  janeiroPercent: 0,
  fevereiro: 0,
  fevereiroPercent: 0,
  marco: 0,
  marcoPercent: 0,
  abril: 0,
  abrilPercent: 0,
  maio: 0,
  maioPercent: 0,
  junho: 0,
  junhoPercent: 0,
  julho: 0,
  julhoPercent: 0,
  agosto: 0,
  agostoPercent: 0,
  setembro: 0,
  setembroPercent: 0,
  outubro: 0,
  outubroPercent: 0,
  novembro: 0,
  novembroPercent: 0,
  dezembro: 0,
  dezembroPercent: 0,
  media: 0,
  mediaPercent: 0,
  acumulado: 0,
  subRows: [],
};

export const resetData = () => {
  normalEntries = [];
  dreEntries = [];
  groupingEntries = [];
  accPlanEntries = [];
  categoryEntries = [];
};

// Popular os arrays com os nomes unicos (Grouping, AccPlan, Category)
// Criar 1 lançamento para cada lançamento normal, populando os campos 'acumulado', 'month', 'media', 'category', 'description' e o valor no mês correto
export const populateNamesArrays = (
  entries,
  type,
  status,
  uncheckedMonths,
  state
) => {
  const tempArr = [[], [], []];
  entries
    .filter((entry) => entry.journalEntryType === 1)
    .filter((entry) => {
      if (status === 'Pago') {
        return entry.statusString === 'Pago';
      } else if (status === 'Pendente') {
        return entry.statusString !== 'Pago';
      } else {
        return entry;
      }
    })
    .filter((entry) => {
      if (
        !uncheckedMonths.includes(
          indexToMonthObject[new Date(entry[type]).getUTCMonth()]
        )
      ) {
        return entry;
      }
    })
    .forEach((entry) => {
      if (
        entry.dreGroupingName?.toLowerCase() === 'outras receitas financeiras'
      ) {
        entry.dreGroupingName = 'Outras receitas financeiras';
      }
      if (
        entry.dreGroupingName?.toLowerCase() === 'outras despesas financeiras'
      ) {
        entry.dreGroupingName = 'Outras despesas financeiras';
      }
      let formattedEntry = {
        ...entryModel,
        category: entry.categoryName,
        accPlan: entry.accountPlanName,
        grouping: entry.dreGroupingName,
        classification: entry.dreClassification,
        description: entry.description,
        month: indexToMonthObject[new Date(entry[type]).getUTCMonth()],
        acumulado: entry.value,
        media: entry.value / (12 - uncheckedMonths.length) || 0,
        juros: entry.interestValue,
        multa: entry.fineValue,
        desconto: entry.discountValue,
        entryType: entry.entryType,
      };
      formattedEntry[formattedEntry.month] = entry.value;
      // Caso haja juros ou multa, criar um lançamento novo
      if (entry.interestValue > 0 || entry.fineValue > 0) {
        let newEntry = {
          ...formattedEntry,
          [formattedEntry.month]: entry.interestValue + entry.fineValue,
          acumulado: entry.interestValue + entry.fineValue,
          description: `*${entry.description}`,
          category: 'Juros/Multas',
          accPlan: 'Juros/Multas/Descontos',
        };

        if (entry.entryType === 'Entrada') {
          newEntry.grouping = 'Outras receitas financeiras';
          newEntry.classification = 'Receitas Financeiras';
        } else {
          newEntry.grouping = 'Outras despesas financeiras';
          newEntry.classification = 'Despesas Financeiras';
        }
        newEntry.media =
          newEntry.acumulado / (12 - uncheckedMonths.length) || 0;
        // console.log('newEntry', newEntry);
        normalEntries.push(newEntry);
      }

      if (entry.discountValue > 0) {
        let newEntry = {
          ...formattedEntry,
          [formattedEntry.month]: entry.discountValue,
          acumulado: entry.discountValue,
          description: `*${entry.description}`,
          category: 'Descontos',
          accPlan: 'Juros/Multas/Descontos',
        };

        if (entry.entryType === 'Entrada') {
          newEntry.grouping = 'Outras despesas financeiras';
          newEntry.classification = 'Despesas Financeiras';
        } else {
          newEntry.grouping = 'Outras receitas financeiras';
          newEntry.classification = 'Receitas Financeiras';
        }
        newEntry.media =
          newEntry.acumulado / (12 - uncheckedMonths.length) || 0;
        // console.log('newEntry', newEntry);
        normalEntries.push(newEntry);
      }

      tempArr[0].push(entry.dreGroupingName);
      tempArr[1].push(entry.accountPlanName);
      tempArr[2].push(entry.categoryName);
      normalEntries.push(formattedEntry);
    });
  arrayGrouping = [...new Set(tempArr[0])];
  arrayAccPlan = [...new Set(tempArr[1])];
  arrayCategory = [...new Set(tempArr[2])];

  !arrayGrouping.includes('Outras receitas financeiras') &&
    arrayGrouping.push('Outras receitas financeiras');
  !arrayGrouping.includes('Outras despesas financeiras') &&
    arrayGrouping.push('Outras despesas financeiras');

  arrayAccPlan.push('Juros/Multas/Descontos');
  arrayCategory.push('Juros/Multas', 'Descontos');

  // console.log('arrayGrouping', arrayGrouping);
  // console.log('arrayAccPlan', arrayAccPlan);
  // console.log('arrayCategory', arrayCategory);
  // console.log('entries', entries);

  createUniqueEntries();
  populateCategoryEntries(uncheckedMonths);
  populateAccPlanEntries(uncheckedMonths);
  populateGrouping(uncheckedMonths);
  populateDre(uncheckedMonths);
  populateResultLines(uncheckedMonths);
  populateMediaPercent();
  populateMonthPercent();
  // dreEntries = dreEntries.sort((a, b) => b.acumulado - a.acumulado);
  groupingEntries = groupingEntries.sort((a, b) => b.acumulado - a.acumulado);
  accPlanEntries = accPlanEntries.sort((a, b) => b.acumulado - a.acumulado);
  categoryEntries = categoryEntries.sort((a, b) => b.acumulado - a.acumulado);
  normalEntries = normalEntries
    .reduce((accumulator, currentObj) => {
      const existingObj = accumulator.find(
        (obj) =>
          obj.description === currentObj.description &&
          obj.category === currentObj.category
      );

      if (existingObj) {
        // Object with the same description already exists, so update its value
        existingObj.abril += currentObj.abril;
        existingObj.abrilPercent += currentObj.abrilPercent;
        existingObj.acumulado += currentObj.acumulado;
        existingObj.agosto += currentObj.agosto;
        existingObj.agostoPercent += currentObj.agostoPercent;
        existingObj.desconto += currentObj.desconto;
        existingObj.dezembro += currentObj.dezembro;
        existingObj.dezembroPercent += currentObj.dezembroPercent;
        existingObj.entryType += currentObj.entryType;
        existingObj.fevereiro += currentObj.fevereiro;
        existingObj.fevereiroPercent += currentObj.fevereiroPercent;
        existingObj.janeiro += currentObj.janeiro;
        existingObj.janeiroPercent += currentObj.janeiroPercent;
        existingObj.julho += currentObj.julho;
        existingObj.julhoPercent += currentObj.julhoPercent;
        existingObj.junho += currentObj.junho;
        existingObj.junhoPercent += currentObj.junhoPercent;
        existingObj.juros += currentObj.juros;
        existingObj.maio += currentObj.maio;
        existingObj.maioPercent += currentObj.maioPercent;
        existingObj.marco += currentObj.marco;
        existingObj.marcoPercent += currentObj.marcoPercent;
        existingObj.media += currentObj.media;
        existingObj.mediaPercent += currentObj.mediaPercent;
        existingObj.month += currentObj.month;
        existingObj.multa += currentObj.multa;
        existingObj.novembro += currentObj.novembro;
        existingObj.novembroPercent += currentObj.novembroPercent;
        existingObj.outubro += currentObj.outubro;
        existingObj.outubroPercent += currentObj.outubroPercent;
        existingObj.setembro += currentObj.setembro;
        existingObj.setembroPercent += currentObj.setembroPercent;
      } else {
        // Object with this description doesn't exist, so add it to the accumulator
        accumulator.push({
          ...currentObj,
        });
      }

      return accumulator;
    }, [])
    .sort((a, b) => b.acumulado - a.acumulado);
  createNestedObjects();
  state(dreEntries);

  // console.log('normalEntries', normalEntries);
  // console.log('dreEntries', dreEntries);
  // console.log('groupingEntries', groupingEntries);
  // console.log('accPlanEntries', accPlanEntries);
  // console.log('categoryEntries', categoryEntries);

  return dreEntries;
};
// Cria 1 lançamento para cada tipo (DRE, Grouping, AccPlan, Category)
const createUniqueEntries = () => {
  const tempArr = [[], [], [], []];

  arrayDre.forEach((dre) => {
    tempArr[0].push({ ...entryModel, description: dre });
  });
  arrayGrouping.forEach((grouping) => {
    tempArr[1].push({ ...entryModel, description: grouping });
  });
  arrayAccPlan.forEach((accPlan) => {
    if (accPlan === 'Juros/Multas/Descontos') {
      tempArr[2].push({
        ...entryModel,
        description: accPlan,
        entryType: 'Entrada',
        grouping: 'Outras receitas financeiras',
      });
      tempArr[2].push({
        ...entryModel,
        description: accPlan,
        entryType: 'Saída',
        grouping: 'Outras despesas financeiras',
      });
    } else {
      tempArr[2].push({ ...entryModel, description: accPlan });
    }
  });
  arrayCategory.forEach((category) => {
    if (category === 'Juros/Multas') {
      tempArr[3].push({
        ...entryModel,
        description: category,
        entryType: 'Entrada',
        grouping: 'Outras receitas financeiras',
      });
      tempArr[3].push({
        ...entryModel,
        description: category,
        entryType: 'Saída',
        grouping: 'Outras despesas financeiras',
      });
    } else if (category === 'Descontos') {
      tempArr[3].push({
        ...entryModel,
        description: category,
        entryType: 'Entrada',
        grouping: 'Outras despesas financeiras',
      });
      tempArr[3].push({
        ...entryModel,
        description: category,
        entryType: 'Saída',
        grouping: 'Outras receitas financeiras',
      });
    } else {
      tempArr[3].push({ ...entryModel, description: category });
    }
  });

  dreEntries = [...new Set(tempArr[0])];
  groupingEntries = [...new Set(tempArr[1])];
  accPlanEntries = [...new Set(tempArr[2])];
  categoryEntries = [...new Set(tempArr[3])];
};

// Popular os lançamentos de categoria, somando os valores mensais, média e acumulado
const populateCategoryEntries = (uncheckedMonths) => {
  let tempArr = [];
  categoryEntries.forEach((category) => {
    let tempCategory = { ...category };
    if (category.description === 'Juros/Multas') {
      normalEntries.forEach((entry) => {
        if (
          entry.category === 'Juros/Multas' &&
          entry.entryType === category.entryType
        ) {
          tempCategory.acumulado += entry.acumulado;
          tempCategory[entry.month] += entry.acumulado;
          tempCategory.accPlan = entry.accPlan;
        }
      });
    } else if (category.description === 'Descontos') {
      normalEntries.forEach((entry) => {
        if (
          entry.category === 'Descontos' &&
          entry.entryType === category.entryType
        ) {
          tempCategory.acumulado += entry.acumulado;
          tempCategory[entry.month] += entry.acumulado;
          tempCategory.accPlan = entry.accPlan;
        }
      });
    } else {
      normalEntries.forEach((entry) => {
        if (entry.category === category.description) {
          tempCategory.acumulado += entry.acumulado;
          tempCategory[entry.month] += entry.acumulado;
          tempCategory.accPlan = entry.accPlan;
        }
      });
    }
    tempCategory.media =
      tempCategory.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log(tempCategory);
    tempArr.push(tempCategory);
  });
  categoryEntries = [...tempArr];
};

// Popular os lançamentos de plano de conta, somando os valores mensais, média e acumulado
const populateAccPlanEntries = (uncheckedMonths) => {
  let tempArr = [];
  accPlanEntries.forEach((accPlan) => {
    let tempAccPlan = { ...accPlan };
    if (accPlan.description === 'Juros/Multas/Descontos') {
      normalEntries.forEach((entry) => {
        if (
          entry.grouping === accPlan.grouping &&
          entry.accPlan === accPlan.description
        ) {
          tempAccPlan.acumulado += entry.acumulado;
          tempAccPlan[entry.month] += entry.acumulado;
          tempAccPlan.grouping = entry.grouping;
        }
      });
    } else {
      normalEntries.forEach((entry) => {
        if (entry.accPlan === accPlan.description) {
          tempAccPlan.acumulado += entry.acumulado;
          tempAccPlan[entry.month] += entry.acumulado;
          tempAccPlan.grouping = entry.grouping;
        }
      });
    }
    tempAccPlan.media =
      tempAccPlan.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log('TempAccPlan: ', tempAccPlan);
    tempArr.push(tempAccPlan);
  });
  accPlanEntries = [...tempArr];
};

// Popular os lançamentos de agrupamento, somando os valores mensais, média e acumulado
const populateGrouping = (uncheckedMonths) => {
  let tempArr = [];
  groupingEntries.forEach((grouping) => {
    let tempGrouping = { ...grouping };
    normalEntries.forEach((entry) => {
      if (entry.grouping === grouping.description) {
        tempGrouping.acumulado += entry.acumulado;
        tempGrouping[entry.month] += entry.acumulado;
        tempGrouping.classification = entry.classification;
      }
    });
    tempGrouping.media =
      tempGrouping.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log(tempGrouping);
    tempArr.push(tempGrouping);
  });
  groupingEntries = [...tempArr];
};

// Popular os lançamentos de DRE, somando os valores mensais, média e acumulado
const populateDre = (uncheckedMonths) => {
  let tempArr = [];
  dreEntries.forEach((dre) => {
    let tempDre = { ...dre };
    normalEntries.forEach((entry) => {
      if (entry.classification === dre.description) {
        tempDre.acumulado += entry.acumulado;
        tempDre[entry.month] += entry.acumulado;
        // tempDre.classification = entry.classification;
      }
    });
    tempDre.media = tempDre.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log(tempDre);
    tempArr.push(tempDre);
  });
  dreEntries = [...tempArr];
};

// Popular as linhas de resultado somando os valores mensais, média e acumulado
const populateResultLines = (uncheckedMonths) => {
  populateReceitaLiquida(uncheckedMonths);
  populateMargemBruta(uncheckedMonths);
  populateEBITDA(uncheckedMonths);
  populateEBIT(uncheckedMonths);
  populateLucroLiquido(uncheckedMonths);
};

const populateReceitaLiquida = (uncheckedMonths) => {
  let tempReceitaLiquida = { ...dreEntries[2] };

  monthsArray.forEach((month) => {
    tempReceitaLiquida[month] = dreEntries[0][month] - dreEntries[1][month];
    tempReceitaLiquida.acumulado += tempReceitaLiquida[month];
  });
  // console.log('tempReceitaLiquida', tempReceitaLiquida);
  tempReceitaLiquida.media =
    tempReceitaLiquida.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[2] = { ...tempReceitaLiquida };
};

const populateMargemBruta = (uncheckedMonths) => {
  let tempMargemBruta = { ...dreEntries[4] };

  monthsArray.forEach((month) => {
    tempMargemBruta[month] = dreEntries[2][month] - dreEntries[3][month];
    tempMargemBruta.acumulado += tempMargemBruta[month];
  });
  // console.log('tempMargemBruta', tempMargemBruta);
  tempMargemBruta.media =
    tempMargemBruta.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[4] = { ...tempMargemBruta };
};

const populateEBITDA = (uncheckedMonths) => {
  let tempEBITDA = { ...dreEntries[6] };

  monthsArray.forEach((month) => {
    tempEBITDA[month] = dreEntries[4][month] - dreEntries[5][month];
    tempEBITDA.acumulado += tempEBITDA[month];
  });
  // console.log('tempEBITDA', tempEBITDA);
  tempEBITDA.media = tempEBITDA.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[6] = { ...tempEBITDA };
};

const populateEBIT = (uncheckedMonths) => {
  let tempEBIT = { ...dreEntries[9] };

  monthsArray.forEach((month) => {
    tempEBIT[month] =
      dreEntries[6][month] - (dreEntries[7][month] + dreEntries[8][month]);
    tempEBIT.acumulado += tempEBIT[month];
  });
  // console.log('tempEBIT', tempEBIT);
  tempEBIT.media = tempEBIT.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[9] = { ...tempEBIT };
};

const populateLucroLiquido = (uncheckedMonths) => {
  let tempLucroLiquido = { ...dreEntries[14] };

  monthsArray.forEach((month) => {
    tempLucroLiquido[month] =
      dreEntries[9][month] +
      (dreEntries[10][month] - dreEntries[11][month]) +
      (dreEntries[12][month] - dreEntries[13][month]);
    tempLucroLiquido.acumulado += tempLucroLiquido[month];
  });
  // console.log('tempLucroLiquido', tempLucroLiquido);
  tempLucroLiquido.media =
    tempLucroLiquido.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[14] = { ...tempLucroLiquido };
};

// Popular os valores percentuais, mensal e média
const populateMediaPercent = () => {
  dreEntries.forEach((dre) => {
    dre.mediaPercent = safeDivision(dre.media, dreEntries[0].media);
  });
  groupingEntries.forEach((grouping) => {
    grouping.mediaPercent = safeDivision(grouping.media, dreEntries[0].media);
  });
  accPlanEntries.forEach((accPlan) => {
    accPlan.mediaPercent = safeDivision(accPlan.media, dreEntries[0].media);
  });
  categoryEntries.forEach((category) => {
    category.mediaPercent = safeDivision(category.media, dreEntries[0].media);
  });
  normalEntries.forEach((normal) => {
    normal.mediaPercent = safeDivision(normal.media, dreEntries[0].media);
  });
};

const populateMonthPercent = () => {
  monthsArray.forEach((month) => {
    dreEntries.forEach((dre) => {
      dre[`${month}Percent`] = safeDivision(dre[month], dreEntries[0][month]);
    });
    groupingEntries.forEach((grouping) => {
      grouping[`${month}Percent`] = safeDivision(
        grouping[month],
        dreEntries[0][month]
      );
    });
    accPlanEntries.forEach((accPlan) => {
      accPlan[`${month}Percent`] = safeDivision(
        accPlan[month],
        dreEntries[0][month]
      );
    });
    categoryEntries.forEach((category) => {
      category[`${month}Percent`] = safeDivision(
        category[month],
        dreEntries[0][month]
      );
    });
    normalEntries.forEach((normal) => {
      normal[`${month}Percent`] = safeDivision(
        normal[month],
        dreEntries[0][month]
      );
    });
  });
};

// Criar os objetos aninhados
const createNestedObjects = () => {
  categoryEntries.forEach((category) => {
    let tempCategory = [];
    if (category.description === 'Juros/Multas') {
      normalEntries.forEach((entry) => {
        if (
          category.description === entry.category &&
          category.entryType === entry.entryType
        ) {
          tempCategory.push(entry);
        }
      });
    } else if (category.description === 'Descontos') {
      normalEntries.forEach((entry) => {
        if (
          category.description === entry.category &&
          category.entryType === entry.entryType
        ) {
          tempCategory.push(entry);
        }
      });
    } else {
      normalEntries.forEach((entry) => {
        if (category.description === entry.category) {
          tempCategory.push(entry);
        }
      });
    }
    category.subRows = [...tempCategory];
  });

  accPlanEntries.forEach((accPlan) => {
    let tempAccPlan = [];
    if (accPlan.description === 'Juros/Multas/Descontos') {
      categoryEntries.forEach((category) => {
        if (accPlan.grouping === category.grouping) {
          tempAccPlan.push(category);
        }
      });
    } else {
      categoryEntries.forEach((category) => {
        if (accPlan.description === category.accPlan) {
          tempAccPlan.push(category);
        }
      });
    }
    accPlan.subRows = [...tempAccPlan];
  });

  groupingEntries.forEach((grouping) => {
    let tempGrouping = [];
    accPlanEntries.forEach((accPlan) => {
      if (grouping.description === accPlan.grouping) {
        tempGrouping.push(accPlan);
      }
    });
    grouping.subRows = [...tempGrouping];
  });

  dreEntries.forEach((dre) => {
    let tempDre = [];
    groupingEntries.forEach((grouping) => {
      if (grouping.classification === dre.description) {
        tempDre.push(grouping);
      }
    });
    dre.subRows = [...tempDre];
  });
};

const safeDivision = (numerator, denominator) => {
  if (denominator === 0) {
    return 0; // Display 0 when denominator is zero
  } else {
    return (numerator / denominator) * 100;
  }
};
