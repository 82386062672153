import BoxSignComponent from './BoxSignComponent';
import HeaderSignComponent from './HeaderSignComponent';

import React from 'react';

export default function LandPageTest() {
  return (
    <div className='container'>
      <HeaderSignComponent />
      <BoxSignComponent />
      <style jsx='true'>
        {`
          .container {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        `}
      </style>
    </div>
  );
}
