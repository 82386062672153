import React from 'react';

import { FileCard, FileUploader, Heading, Pane, Text } from 'evergreen-ui';

const UploadStatementPane = ({
  handleChange,
  handleRejected,
  handleRemove,
  files,
  fileRejections,
}) => {
  return (
    <Pane
      // maxWidth={300}
      marginX={10}
      marginY={10}
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      <Heading marginBottom={20} size={600} textAlign='center'>
        Importar Extrato
      </Heading>

      <FileUploader
        // label='Adicionar extrato'
        description={
          <Text>
            Apenas extratos no formato <Text fontWeight='bold'>.ofx</Text> são
            permitidos.
          </Text>
        }
        maxSizeInBytes={1024 ** 2}
        browseOrDragText={() => <Text>Insira ou arraste o extrato aqui.</Text>}
        maxFiles={1}
        onChange={handleChange}
        onRejected={handleRejected}
        renderFile={(file) => {
          const { name, size, type } = file;
          const fileRejection = fileRejections.find(
            (fileRejection) => fileRejection.file === file
          );
          const { message } = fileRejection || {};
          return (
            <FileCard
              key={name}
              isInvalid={fileRejection != null}
              name={name}
              onRemove={handleRemove}
              sizeInBytes={size}
              type={type}
              validationMessage={message}
            />
          );
        }}
        values={files}
      />
    </Pane>
  );
};

export default UploadStatementPane;
