export const CustomTooltip = {
  Tooltip: {
    appearances: {
      default: {
        borderRadius: 4,
        border: "none",
        color: "#747474",
        backgroundColor: "#edeff5",
      },
    },
  },
};
