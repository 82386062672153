// Array de meses
export const monthsArray = [
  'janeiro',
  'fevereiro',
  'marco',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

// Array de meses formatado
export const formattedMonthsArray = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const formattedIndexToMonthObject = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

// Objeto de meses com seus indices (0-based)
export const indexToMonthObject = {
  0: 'janeiro',
  1: 'fevereiro',
  2: 'marco',
  3: 'abril',
  4: 'maio',
  5: 'junho',
  6: 'julho',
  7: 'agosto',
  8: 'setembro',
  9: 'outubro',
  10: 'novembro',
  11: 'dezembro',
};

// Array com todas as classificações de DRE
let arrayDre = [
  'Receita Bruta',
  'Desconto de Faturamento',
  'Receita Líquida',
  'CPV/CMV/CSP',
  'Margem Bruta',
  'Despesas Gerais',
  'EBITDA',
  'Depreciações e Amortizações',
  'Investimentos',
  'EBIT',
  'Receitas Financeiras',
  'Despesas Financeiras',
  'Receitas não operacionais',
  'Despesas não operacionais',
  'Lucro Líquido',
];
// Arrays com os nomes unicos (Grouping, AccPlan, Category)
let arrayGrouping = [];
let arrayAccPlan = [];
let arrayCategory = [];

// Arrays com os lançamentos unicos (DRE, Grouping, AccPlan, Category)
let dreEntries = [];
let groupingEntries = [];
let accPlanEntries = [];
let categoryEntries = [];
// Array com os lançamentos normais formatados
let normalEntries = [];

// Modelo de objeto para cada lançamento
const entryModel = {
  janeiro: 0,
  janeiroPercent: 0,
  fevereiro: 0,
  fevereiroPercent: 0,
  marco: 0,
  marcoPercent: 0,
  abril: 0,
  abrilPercent: 0,
  maio: 0,
  maioPercent: 0,
  junho: 0,
  junhoPercent: 0,
  julho: 0,
  julhoPercent: 0,
  agosto: 0,
  agostoPercent: 0,
  setembro: 0,
  setembroPercent: 0,
  outubro: 0,
  outubroPercent: 0,
  novembro: 0,
  novembroPercent: 0,
  dezembro: 0,
  dezembroPercent: 0,
  media: 0,
  mediaPercent: 0,
  acumulado: 0,
  subRows: [],
};

export const resetData = () => {
  normalEntries = [];
  dreEntries = [];
  groupingEntries = [];
  accPlanEntries = [];
  categoryEntries = [];
};

// Popular os arrays com os nomes unicos (Grouping, AccPlan, Category)
// Criar 1 lançamento para cada lançamento normal, populando os campos 'acumulado', 'month', 'media', 'category', 'description' e o valor no mês correto
export const populateNamesArrays = (
  entries,
  type,
  status,
  uncheckedMonths,
  state
) => {
  const tempArr = [[], [], []];
  entries
    .filter((entry) => entry.journalEntryType === 1)
    .filter((entry) => {
      if (status === 'Pago') {
        return entry.statusString === 'Pago';
      } else if (status === 'Pendente') {
        return entry.statusString !== 'Pago';
      } else {
        return entry;
      }
    })
    .filter((entry) => {
      if (
        !uncheckedMonths.includes(
          indexToMonthObject[new Date(entry[type]).getUTCMonth()]
        )
      ) {
        return entry;
      }
    })
    .forEach((entry) => {
      let formattedEntry = {
        ...entryModel,
        category: entry.categoryName,
        accPlan: entry.accountPlanName,
        grouping: entry.dreGroupingName,
        classification: entry.dreClassification,
        description: entry.description,
        month: indexToMonthObject[new Date(entry[type]).getUTCMonth()],
        acumulado: entry.valueFinal,
        media: entry.valueFinal / (12 - uncheckedMonths.length) || 0,
      };
      formattedEntry[formattedEntry.month] = entry.valueFinal;

      tempArr[0].push(entry.dreGroupingName);
      tempArr[1].push(entry.accountPlanName);
      tempArr[2].push(entry.categoryName);
      normalEntries.push(formattedEntry);
    });
  arrayGrouping = [...new Set(tempArr[0])];
  arrayAccPlan = [...new Set(tempArr[1])];
  arrayCategory = [...new Set(tempArr[2])];

  console.log('arrayGrouping', arrayGrouping);
  console.log('arrayAccPlan', arrayAccPlan);
  console.log('arrayCategory', arrayCategory);

  createUniqueEntries();
  populateCategoryEntries(uncheckedMonths);
  populateAccPlanEntries(uncheckedMonths);
  populateGrouping(uncheckedMonths);
  populateDre(uncheckedMonths);
  populateResultLines(uncheckedMonths);
  populateMediaPercent();
  populateMonthPercent();
  createNestedObjects();
  state(dreEntries);

  console.log('dreEntries', dreEntries);
  console.log('groupingEntries', groupingEntries);
  console.log('accPlanEntries', accPlanEntries);
  console.log('categoryEntries', categoryEntries);
  console.log('normalEntries', normalEntries);
};

// Cria 1 lançamento para cada tipo (DRE, Grouping, AccPlan, Category)
const createUniqueEntries = () => {
  const tempArr = [[], [], [], []];

  arrayDre.forEach((dre) => {
    tempArr[0].push({ ...entryModel, description: dre });
  });
  arrayGrouping.forEach((grouping) => {
    tempArr[1].push({ ...entryModel, description: grouping });
  });
  arrayAccPlan.forEach((accPlan) => {
    tempArr[2].push({ ...entryModel, description: accPlan });
  });
  arrayCategory.forEach((category) => {
    tempArr[3].push({ ...entryModel, description: category });
  });

  dreEntries = [...new Set(tempArr[0])];
  groupingEntries = [...new Set(tempArr[1])];
  accPlanEntries = [...new Set(tempArr[2])];
  categoryEntries = [...new Set(tempArr[3])];
};

// Popular os lançamentos de categoria, somando os valores mensais, média e acumulado
const populateCategoryEntries = (uncheckedMonths) => {
  let tempArr = [];
  categoryEntries.forEach((category) => {
    let tempCategory = { ...category };
    normalEntries.forEach((entry) => {
      if (entry.category === category.description) {
        tempCategory.acumulado += entry.acumulado;
        tempCategory[entry.month] += entry.acumulado;
        tempCategory.accPlan = entry.accPlan;
      }
    });
    tempCategory.media =
      tempCategory.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log(tempCategory);
    tempArr.push(tempCategory);
  });
  categoryEntries = [...tempArr];
};

// Popular os lançamentos de plano de conta, somando os valores mensais, média e acumulado
const populateAccPlanEntries = (uncheckedMonths) => {
  let tempArr = [];
  accPlanEntries.forEach((accPlan) => {
    let tempAccPlan = { ...accPlan };
    normalEntries.forEach((entry) => {
      if (entry.accPlan === accPlan.description) {
        tempAccPlan.acumulado += entry.acumulado;
        tempAccPlan[entry.month] += entry.acumulado;
        tempAccPlan.grouping = entry.grouping;
      }
    });
    tempAccPlan.media =
      tempAccPlan.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log(tempAccPlan);
    tempArr.push(tempAccPlan);
  });
  accPlanEntries = [...tempArr];
};

// Popular os lançamentos de agrupamento, somando os valores mensais, média e acumulado
const populateGrouping = (uncheckedMonths) => {
  let tempArr = [];
  groupingEntries.forEach((grouping) => {
    let tempGrouping = { ...grouping };
    normalEntries.forEach((entry) => {
      if (entry.grouping === grouping.description) {
        tempGrouping.acumulado += entry.acumulado;
        tempGrouping[entry.month] += entry.acumulado;
        tempGrouping.classification = entry.classification;
      }
    });
    tempGrouping.media =
      tempGrouping.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log(tempGrouping);
    tempArr.push(tempGrouping);
  });
  groupingEntries = [...tempArr];
};

// Popular os lançamentos de DRE, somando os valores mensais, média e acumulado
const populateDre = (uncheckedMonths) => {
  let tempArr = [];
  dreEntries.forEach((dre) => {
    let tempDre = { ...dre };
    normalEntries.forEach((entry) => {
      if (entry.classification === dre.description) {
        tempDre.acumulado += entry.acumulado;
        tempDre[entry.month] += entry.acumulado;
        // tempDre.classification = entry.classification;
      }
    });
    tempDre.media = tempDre.acumulado / (12 - uncheckedMonths.length) || 0;
    // console.log(tempDre);
    tempArr.push(tempDre);
  });
  dreEntries = [...tempArr];
};

// Popular as linhas de resultado somando os valores mensais, média e acumulado
const populateResultLines = (uncheckedMonths) => {
  populateReceitaLiquida(uncheckedMonths);
  populateMargemBruta(uncheckedMonths);
  populateEBITDA(uncheckedMonths);
  populateEBIT(uncheckedMonths);
  populateLucroLiquido(uncheckedMonths);
};

const populateReceitaLiquida = (uncheckedMonths) => {
  let tempReceitaLiquida = { ...dreEntries[2] };

  monthsArray.forEach((month) => {
    tempReceitaLiquida[month] = dreEntries[0][month] - dreEntries[1][month];
    tempReceitaLiquida.acumulado += tempReceitaLiquida[month];
  });
  // console.log('tempReceitaLiquida', tempReceitaLiquida);
  tempReceitaLiquida.media =
    tempReceitaLiquida.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[2] = { ...tempReceitaLiquida };
};

const populateMargemBruta = (uncheckedMonths) => {
  let tempMargemBruta = { ...dreEntries[4] };

  monthsArray.forEach((month) => {
    tempMargemBruta[month] = dreEntries[2][month] - dreEntries[3][month];
    tempMargemBruta.acumulado += tempMargemBruta[month];
  });
  // console.log('tempMargemBruta', tempMargemBruta);
  tempMargemBruta.media =
    tempMargemBruta.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[4] = { ...tempMargemBruta };
};

const populateEBITDA = (uncheckedMonths) => {
  let tempEBITDA = { ...dreEntries[6] };

  monthsArray.forEach((month) => {
    tempEBITDA[month] = dreEntries[4][month] - dreEntries[5][month];
    tempEBITDA.acumulado += tempEBITDA[month];
  });
  // console.log('tempEBITDA', tempEBITDA);
  tempEBITDA.media = tempEBITDA.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[6] = { ...tempEBITDA };
};

const populateEBIT = (uncheckedMonths) => {
  let tempEBIT = { ...dreEntries[9] };

  monthsArray.forEach((month) => {
    tempEBIT[month] =
      dreEntries[6][month] - (dreEntries[7][month] - dreEntries[8][month]);
    tempEBIT.acumulado += tempEBIT[month];
  });
  // console.log('tempEBIT', tempEBIT);
  tempEBIT.media = tempEBIT.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[9] = { ...tempEBIT };
};

const populateLucroLiquido = (uncheckedMonths) => {
  let tempLucroLiquido = { ...dreEntries[14] };

  monthsArray.forEach((month) => {
    tempLucroLiquido[month] =
      dreEntries[9][month] +
      (dreEntries[10][month] - dreEntries[11][month]) +
      (dreEntries[12][month] - dreEntries[13][month]);
    tempLucroLiquido.acumulado += tempLucroLiquido[month];
  });
  // console.log('tempLucroLiquido', tempLucroLiquido);
  tempLucroLiquido.media =
    tempLucroLiquido.acumulado / (12 - uncheckedMonths.length) || 0;
  dreEntries[14] = { ...tempLucroLiquido };
};

// Popular os valores percentuais, mensal e média
const populateMediaPercent = () => {
  dreEntries.forEach((dre) => {
    dre.mediaPercent = (dre.media / dreEntries[0].media) * 100 || 0;
  });
  groupingEntries.forEach((grouping) => {
    grouping.mediaPercent = (grouping.media / dreEntries[0].media) * 100 || 0;
  });
  accPlanEntries.forEach((accPlan) => {
    accPlan.mediaPercent = (accPlan.media / dreEntries[0].media) * 100 || 0;
  });
  categoryEntries.forEach((category) => {
    category.mediaPercent = (category.media / dreEntries[0].media) * 100 || 0;
  });
  normalEntries.forEach((normal) => {
    normal.mediaPercent = (normal.media / dreEntries[0].media) * 100 || 0;
  });
};

const populateMonthPercent = () => {
  monthsArray.forEach((month) => {
    dreEntries.forEach((dre) => {
      dre[`${month}Percent`] = (dre[month] / dreEntries[0][month]) * 100 || 0;
    });
    groupingEntries.forEach((grouping) => {
      grouping[`${month}Percent`] =
        (grouping[month] / dreEntries[0][month]) * 100 || 0;
    });
    accPlanEntries.forEach((accPlan) => {
      accPlan[`${month}Percent`] =
        (accPlan[month] / dreEntries[0][month]) * 100 || 0;
    });
    categoryEntries.forEach((category) => {
      category[`${month}Percent`] =
        (category[month] / dreEntries[0][month]) * 100 || 0;
    });
    normalEntries.forEach((normal) => {
      normal[`${month}Percent`] =
        (normal[month] / dreEntries[0][month]) * 100 || 0;
    });
  });
};

// Criar os objetos aninhados
const createNestedObjects = () => {
  categoryEntries.forEach((category) => {
    let tempCategory = [];
    normalEntries.forEach((entry) => {
      if (category.description === entry.category) {
        tempCategory.push(entry);
      }
    });
    category.subRows = [...tempCategory];
  });

  accPlanEntries.forEach((accPlan) => {
    let tempAccPlan = [];
    categoryEntries.forEach((category) => {
      if (accPlan.description === category.accPlan) {
        tempAccPlan.push(category);
      }
    });
    accPlan.subRows = [...tempAccPlan];
  });

  groupingEntries.forEach((grouping) => {
    let tempGrouping = [];
    accPlanEntries.forEach((accPlan) => {
      if (grouping.description === accPlan.grouping) {
        tempGrouping.push(accPlan);
      }
    });
    grouping.subRows = [...tempGrouping];
  });

  dreEntries.forEach((dre) => {
    let tempDre = [];
    groupingEntries.forEach((grouping) => {
      if (grouping.classification === dre.description) {
        tempDre.push(grouping);
      }
    });
    dre.subRows = [...tempDre];
  });
};
