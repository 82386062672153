import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AddIcon,
  Card,
  Heading,
  ListItem,
  Pane,
  UnorderedList,
} from 'evergreen-ui';
import simboloVitaResize from '../../assets/simboloVitaResize.png';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import { toastNotification } from '../../shared/toastNotification';
import CreateCompanyForm from './CreateCompanyForm';
import { mutate } from 'swr';
import { useDispatch } from 'react-redux';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import { useQueryUserRolesCurrentRole } from '../../services/api/userRolesService';

function SwitchCompanyPage() {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const [isShownDialog, setIsShownDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const dispatch = useDispatch();

  const openDialog = (user) => {
    setIsShownDialog(true);
    setCurrentUser(user);
  };

  let statusResponse = false;
  let toastContent = '';

  const navigate = useNavigate();

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError: isErrorVitaUser,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const chooseCompany = (company) => {
    console.log('choose company: ', company.name);

    localStorage.setItem('companyId', company.id);
    localStorage.setItem('companyName', company.name);
    statusResponse = 'success';
    toastContent = `${company.name} selecionada`;
    mutate('entriesFilteredByDate');
    mutate('bankBalances');
    mutate('userRolesCurrentRole');
    dispatch(reconciliationValuesActions.resetFilter());

    toastNotification(statusResponse, toastContent);
    navigate('/lancamentos');
  };

  useEffect(() => {
    if (!isLoadingVitaUser && !vitaUser?.data?.signedTerm) {
      navigate('/termos-de-uso');
      console.log('navigate to termos de uso');
    }
  }, [companyId, userId, vitaUser]);

  if (isLoadingVitaUser) {
    return <LoadingOverlay />;
  }
  return (
    <Pane
      height='100%'
      width='100%'
      display='flex'
      flexDirection='column'
      gap={60}
      alignItems='center'
      justifyContent='center'
      padding={16}
      backgroundImage='radial-gradient(circle, rgba(232,238,248,1) 8%, rgba(195,207,226,1) 91%);'
    >
      <CreateCompanyForm
        isShown={isShownDialog}
        setIsShown={setIsShownDialog}
      />
      <img src={simboloVitaResize} height='20%' />
      <Heading size={800}>Selecione a empresa</Heading>
      <Pane
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexWrap='wrap'
        gap={9}
      >
        {!isLoadingVitaUser && vitaUser?.data?.isClient && (
          <Card
            className='cardCompany'
            display='flex'
            position='relative'
            elevation={2}
            width='200px'
            height='80px'
            backgroundColor='white'
            padding={16}
            flexGrow={1}
            justifyContent='center'
            alignContent='center'
            flexWrap='wrap'
            textOverflow='ellipsis'
            onClick={openDialog}
          >
            <UnorderedList size={600}>
              <ListItem icon={AddIcon} color='blue'>
                Criar empresa
              </ListItem>
            </UnorderedList>
          </Card>
        )}
        {!isLoadingVitaUser &&
          !isErrorVitaUser &&
          vitaUser?.data?.companies.map((company) => (
            <Card
              className='cardCompany'
              display='flex'
              elevation={2}
              width='200px'
              height='80px'
              backgroundColor='white'
              key={company.id}
              padding={16}
              flexGrow={1}
              justifyContent='center'
              alignContent='center'
              flexWrap='wrap'
              textOverflow='ellipsis'
              onClick={() => chooseCompany(company)}
            >
              <Heading size={600}>{company.name}</Heading>
            </Card>
          ))}
      </Pane>
      <style jsx='true'>{`
        .cardCompany:hover {
          cursor: pointer;
        }
      `}</style>
    </Pane>
  );
}

export default SwitchCompanyPage;
