import { createSlice } from '@reduxjs/toolkit';

const initialValuesState = {
  input: 0,
  output: 0,
  transferInput: 0,
  transferOutput: 0,
};

const balanceValuesSlice = createSlice({
  name: 'balanceValues',
  initialState: initialValuesState,
  reducers: {
    setInput(state, action) {
      state.input = action.payload;
    },
    setOutput(state, action) {
      state.output = action.payload;
    },
    setTransferInput(state, action) {
      state.transferInput = action.payload;
    },
    setTransferOutput(state, action) {
      state.transferOutput = action.payload;
    },
    resetValues(state) {
      state.input = 0;
      state.output = 0;
      state.transferInput = 0;
      state.transferOutput = 0;
    },
  },
});

export const balanceValuesActions = balanceValuesSlice.actions;

export default balanceValuesSlice.reducer;
