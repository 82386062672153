import { Button, Card, Text } from "evergreen-ui";
import React from "react";

function SwitchSignInUpComponent({ login, switchCard }) {
  return (
    <Card
      elevation={4}
      float="left"
      background="white"
      marginX={24}
      padding={16}
      display="flex"
      gap={16}
      height="fit-content"
      alignItems="center"
      justifyContent="space-between"
    >
      {login ? (
        <>
          <Text>Não possui conta?</Text>
          <Button onClick={switchCard}>Registre-se</Button>
        </>
      ) : (
        <>
          <Text>Já possui conta?</Text>
          <Button onClick={switchCard}>Logar-se</Button>
        </>
      )}
      <style jsx="true">
        {`
          Text {
            margin: 0 16px;
          }
        `}
      </style>
    </Card>
  );
}

export default SwitchSignInUpComponent;
