import { CustomTooltip } from "./CustomTooltip";
import { mergeTheme, defaultTheme } from "evergreen-ui";

export const CustomExporter = {
  components: {
    ...CustomTooltip,
  },
};

export const ThemeReducer = mergeTheme(
  defaultTheme,
  CustomExporter
);
