export const inputValidation = (a, b, c) => {
  if (a !== '' && a !== undefined && b !== undefined) {
    c(false);
  } else {
    c(true);
  }
};
export const inputCategoryValidation = (a, b, c, d) => {
  if (
    a !== '' &&
    a !== undefined &&
    b !== undefined &&
    c !== ''
  ) {
    d(false);
  } else {
    d(true);
  }
};

export const inputUserValidation = (a, b, d) => {
  if (a !== '' && b !== '') {
    d(false);
  } else {
    d(true);
  }
};

export const inputDreValidation = (a, b, c, d) => {
  if (
    a !== '' &&
    a !== undefined &&
    b !== undefined &&
    c !== ''
  ) {
    d(false);
  } else {
    d(true);
  }
};

export const inputAccPlanValidation = (a, b, c, d) => {
  if (
    a !== '' &&
    a !== undefined &&
    b !== undefined &&
    c !== ''
  ) {
    d(false);
  } else {
    d(true);
  }
};

export const inputBankValidation = (a, b, c, d, e, f) => {
  if (
    a !== '' &&
    b !== '' &&
    c !== '' &&
    d !== '' &&
    e !== ''
  ) {
    f(false);
  } else {
    f(true);
  }
};

export const inputEntryValidation = (a, b, c, d, e, f) => {
  if (
    a !== undefined &&
    b !== undefined &&
    c !== '' &&
    d !== '' &&
    e !== '0,00'
  ) {
    f(false);
  } else {
    f(true);
  }
};

export const inputTransferValidation = (a, b, c, d, e) => {
  if (
    a !== '' &&
    b !== '' &&
    c !== '' &&
    d !== '0,00' &&
    a !== b
  ) {
    e(false);
  } else {
    e(true);
  }
};

export const dreClassificationInputArray = [
  '',
  'Receita Bruta',
  'Receitas Financeiras',
  'Receitas não operacionais',
];
export const dreClassificationOutputArray = [
  '',
  'Desconto de Faturamento',
  'CPV/CMV/CSP',
  'Despesas Gerais',
  'Depreciações e Amortizações',
  'Despesas Financeiras',
  'Despesas não operacionais',
  'Investimentos',
];

export const bankAccountTypeArray = [
  'Caixa',
  'Corrente',
  'Poupança',
  'Outro',
];
