export const monthsArray = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const firstDay = (month, filterYear) => {
  const now = new Date();
  const year = now.getFullYear();
  // console.log('Ano: ', filterYear);
  return new Date(filterYear, month, 1)
    .toISOString()
    .substr(0, 10);
};

export const lastDay = (month, filterYear) => {
  const now = new Date();
  const year = now.getFullYear();
  return new Date(filterYear, month + 1, 0)
    .toISOString()
    .substr(0, 10);
};

export const convertDate = (date) => {
  const newDate = new Date(date);

  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return `${year}-${month}-${day}`;
};

export const convertDate2 = (date) => {
  const year = date.substr(0, 4);
  const month = date.substr(5, 2);
  const day = date.substr(8, 2);
  return `${day}/${month}/${year}`;
};

export const convertDate3 = (date) => {
  const newDate = new Date(date);

  let seconds = newDate.getSeconds();
  let minutes = newDate.getMinutes();
  let hours = newDate.getHours();
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  if (seconds < 10) seconds = '0' + seconds;
  if (minutes < 10) minutes = '0' + minutes;
  if (hours < 10) hours = '0' + hours;
  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
};
