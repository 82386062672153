import React, { useEffect } from 'react';

import {
  Button,
  Card,
  CircleArrowLeftIcon,
  CircleArrowRightIcon,
  Pane,
} from 'evergreen-ui';

import TermsAndConditionsComponent from './TermsAndConditionsComponent';
import CreateFirstCompanyForm from '../create-company/CreateFirstCompanyForm';
import CreateUserForm from '../user/CreateUserForm';
import { useNavigate } from 'react-router-dom';
import DividerComponent from '../../custom/DividerComponent';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import { useDispatch, useSelector } from 'react-redux';
import { createUserActions } from '../../store/createUser';

function FirstAccessPage() {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [tabs] = React.useState(['Termos e Condições', 'Empresa']);

  const navigate = useNavigate();
  const pageTab = useSelector((state) => state.createUser.pageTab);

  const currentCompany = companyId;
  const dispatch = useDispatch();

  const { data: vitaUser, isLoading: isLoadingVitaUser } =
    useQueryVitaUserByIdentityId(userId, {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  useEffect(() => {
    if (!userId || currentCompany) {
      navigate('/');
    }
    if (userId && !currentCompany) {
      dispatch(createUserActions.setTabDisabled(false));
      setSelectedIndex(0);
    }
  }, []);

  // const onSubmitTermsAndConditions = () => {};

  return (
    <Pane
      height='100%'
      width='100%'
      display='flex'
      flexDirection='column'
      gap={12}
      alignItems='center'
      justifyContent='center'
      padding={16}
      backgroundImage='radial-gradient(circle, rgba(232,238,248,1) 8%, rgba(195,207,226,1) 91%);'
    >
      <Pane
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexWrap='wrap'
        gap={9}
        maxHeight='80%'
      >
        <Card
          display='flex'
          flexDirection='column'
          elevation={2}
          backgroundColor='white'
          padding={16}
          flexGrow={1}
          justifyContent='center'
          minWidth={290}
          width={320}
          maxHeight='100%'
        >
          <Pane padding={16} background='tint1' flex='1'>
            <Pane
              className='termsPane'
              overflowY='scroll'
              height='600px'
              key={0}
              id={`panel-${0}`}
              role='0panel'
              aria-labelledby={0}
              aria-hidden={0 !== selectedIndex}
              display={0 === selectedIndex ? 'block' : 'none'}
            >
              <TermsAndConditionsComponent />
            </Pane>
            {/* <Pane
              className='userPane'
              key={1}
              height='340px'
              id={`panel-${1}`}
              role='1panel'
              aria-labelledby={1}
              aria-hidden={1 !== selectedIndex}
              display={1 === selectedIndex ? 'block' : 'none'}
            >
              <CreateUserForm />
            </Pane> */}
            <Pane
              className='companyPane'
              key={1}
              height='340px'
              id={`panel-${1}`}
              role='2panel'
              aria-labelledby={1}
              aria-hidden={1 !== selectedIndex}
              display={1 === selectedIndex ? 'block' : 'none'}
            >
              <CreateFirstCompanyForm />
            </Pane>
          </Pane>
          <DividerComponent />

          <Pane display='flex' flex={1} justifyContent='space-between'>
            {!!tabs[selectedIndex - 1] ? (
              <Button
                type='button'
                appearance='minimal'
                color='blue'
                iconBefore={CircleArrowLeftIcon}
                onClick={() => setSelectedIndex((state) => state - 1)}
              >
                Voltar
              </Button>
            ) : (
              <span></span>
            )}
            {!!tabs[selectedIndex + 1] ? (
              <Button
                type='button'
                appearance='minimal'
                color='blue'
                iconAfter={CircleArrowRightIcon}
                disabled={selectedIndex === 1 && pageTab}
                onClick={() => setSelectedIndex((state) => state + 1)}
              >
                Próximo{' '}
              </Button>
            ) : (
              false
            )}
          </Pane>
        </Card>
      </Pane>
      {/* <Pane
        display='flex'
        flexDirection='row'
        flexWrap='nowrap'
      >
        <Tablist>
          <Tab
            key={0}
            id={0}
            onSelect={() => setSelectedIndex(0)}
            isSelected={0 === selectedIndex}
            aria-controls={`panel-${0}`}
            disabled={0 === selectedIndex}
          >
            {tabs[0]}
          </Tab>
          <Tab
            key={1}
            id={1}
            onSelect={() => setSelectedIndex(1)}
            isSelected={1 === selectedIndex}
            aria-controls={`panel-${1}`}
            disabled={1 === selectedIndex}
          >
            {tabs[1]}
          </Tab>
          <Tab
            key={2}
            id={2}
            onSelect={() => setSelectedIndex(2)}
            isSelected={2 === selectedIndex}
            aria-controls={`panel-${2}`}
            disabled={2 === selectedIndex}
          >
            {tabs[2]}
          </Tab>
        </Tablist>
      </Pane> */}
    </Pane>
  );
}

export default FirstAccessPage;
