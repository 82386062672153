import React, { useState } from 'react';

import {
  ArrowBottomRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowTopRightIcon,
  Button,
  Card,
  Checkbox,
  ChevronLeftIcon,
  ChevronRightIcon,
  Heading,
  IconButton,
  Menu,
  Pane,
  Popover,
  Position,
  StatusIndicator,
  SwapHorizontalIcon,
} from 'evergreen-ui';
import EntryFormComponent from './EntryFormComponent';
import TransferFormComponent from './TransferFormComponent';
import { current } from '@reduxjs/toolkit';

function EntryJournalFilterBar({ tableProps, journalEntries, currentRole }) {
  const [isShownDialog, setIsShownDialog] = useState(false);
  const [isShownTransferDialog, setIsShownTransferDialog] = useState(false);
  const [journalEntryOnFocus, setJournalEntryOnFocus] = useState({});

  const openDialog = (entry) => {
    setIsShownDialog(true);
    setJournalEntryOnFocus(entry);
  };
  const openTransferDialog = (entry) => {
    setIsShownTransferDialog(true);
    setJournalEntryOnFocus(entry);
  };

  const resetSelectFilter = (column) => {
    if (column.id === 'select') {
      console.log('resetSelectFilter', column.getFilterValue());
      tableProps.resetRowSelection();
      column.setFilterValue(undefined);
    }
    column.toggleVisibility(!column.getIsVisible());
  };

  return (
    <Card
      minHeight={64}
      display='flex'
      position='relative'
      alignItems='center'
      gap={16}
      padding={16}
      flexDirection='row'
      flexWrap='nowrap'
      width='100%'
      backgroundColor='#f4f5f9'
      justifyContent='space-between'
    >
      <EntryFormComponent
        isShown={isShownDialog}
        setIsShown={setIsShownDialog}
        journalEntry={journalEntryOnFocus}
      />
      <TransferFormComponent
        isShown={isShownTransferDialog}
        setIsShown={setIsShownTransferDialog}
        journalEntry={journalEntryOnFocus}
      />
      <Pane display='flex' alignItems='center'>
        <Pane
          display='flex'
          alignItems='center'
          paddingRight={12}
          height='100%'
        >
          <Pane className='filterStatus'>
            <Heading size={300} className='filterStatus' textAlign='center'>
              {`${
                tableProps.getFilteredRowModel().flatRows.length
              } lançamentos`}
            </Heading>
          </Pane>
        </Pane>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Pane paddingX={15} overflowY='auto' maxHeight={300}>
                <Checkbox
                  type='checkbox'
                  key='mostrar-tudo'
                  label='Mostrar tudo'
                  checked={tableProps.getIsAllColumnsVisible()}
                  onChange={() => tableProps.toggleAllColumnsVisible()}
                />
                <Menu.Divider />
                {tableProps.getAllLeafColumns().map((column) => {
                  if (
                    column.columnDef.header !== 'Tipo' &&
                    column.columnDef.header !== 'Ações' &&
                    column.columnDef.header !== 'Valor Final'
                    // column.columnDef.header !== 'Selecionar'
                  ) {
                    return (
                      <Checkbox
                        type='checkbox'
                        key={column.id}
                        label={column.columnDef.header}
                        checked={column.getIsVisible()}
                        onChange={() => resetSelectFilter(column)}
                      />
                    );
                  }
                })}
              </Pane>
            </Menu>
          }
        >
          <Button className='filterButton' flexShrink='inherit'>
            Habilitar colunas
            {!tableProps.getIsAllColumnsVisible() && (
              <StatusIndicator
                color='warning'
                marginLeft={8}
                marginBottom={6}
                marginRight='-8px'
                padding={0}
              />
            )}
          </Button>
        </Popover>
      </Pane>
      <Pane display='flex' alignItems='center'>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Menu.Group>
                <Menu.Item
                  icon={ArrowTopRightIcon}
                  intent='selected'
                  onClick={() =>
                    openDialog({
                      journalEntryType: 1,
                      isInput: true,
                    })
                  }
                >
                  Entrada
                </Menu.Item>
                <Menu.Item
                  icon={ArrowBottomRightIcon}
                  intent='danger'
                  onClick={() => {
                    openDialog({
                      journalEntryType: 1,
                      isInput: false,
                    });
                  }}
                >
                  Saída
                </Menu.Item>
                <Menu.Item
                  icon={SwapHorizontalIcon}
                  intent='success'
                  // secondaryText=" • "
                  onClick={() => {
                    openTransferDialog({
                      journalEntryType: 5,
                    });
                  }}
                >
                  Transferência
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <Button
            className='journalButton'
            appearance='primary'
            // marginLeft='auto'
            maxWidth='fit-content'
            size='large'
            flex='auto'
            disabled={currentRole.data.userRole === 'ANALISTA'}
          >
            Lançar
          </Button>
        </Popover>
      </Pane>
    </Card>
  );
}
export default EntryJournalFilterBar;
