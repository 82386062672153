import { Checkbox, Heading, Pane, Select, Text } from 'evergreen-ui';
import React from 'react';

const BanksChartOptions = ({
  selectedBanks,
  setSelectedBanks,
  bankBalances,
  consolidated,
  setConsolidated,
  pending,
  setPending,
}) => {
  return (
    <Pane
      width='25%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      gap={20}
    >
      <Pane>
        <Heading textAlign='center' color='whitesmoke' padding={5}>
          Resultado
        </Heading>
        <Checkbox
          label={
            <Text size={300} color='white'>
              Consolidado
            </Text>
          }
          checked={consolidated}
          onChange={(e) => {
            setConsolidated(e.target.checked);
          }}
        />
        <Checkbox
          label={
            <Text size={300} color='white'>
              Pendente
            </Text>
          }
          checked={pending}
          onChange={(e) => {
            setPending(e.target.checked);
          }}
        />
      </Pane>
      <Pane>
        <Heading textAlign='center' color='whitesmoke' padding={5}>
          Bancos
        </Heading>
        <Checkbox
          label={
            <Text size={300} color='white'>
              Todos
            </Text>
          }
          checked={selectedBanks.length === bankBalances.data.length}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedBanks(bankBalances.data.map((bank) => bank.name));
            } else {
              setSelectedBanks([]);
            }
          }}
        />
        {bankBalances.data.map((bank, index) => (
          <Checkbox
            key={bank.name}
            label={
              <Text size={300} color='white'>
                {bank.name}
              </Text>
            }
            checked={selectedBanks.includes(bank.name)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedBanks([...selectedBanks, bank.name]);
              } else {
                setSelectedBanks(
                  selectedBanks.filter((bankName) => bankName !== bank.name)
                );
              }
            }}
          />
        ))}
      </Pane>
    </Pane>
  );
};

export default BanksChartOptions;
