import React, { useState } from 'react';
import {
  Badge,
  Button,
  Pane,
  Table,
  TextInput,
  TrashIcon,
  Heading,
  SelectField,
  SavedIcon,
  Dialog,
} from 'evergreen-ui';
import { deleteUserRoles } from '../../services/api/userRolesService';
import { mutate } from 'swr';
import { toastNotification } from '../../shared/toastNotification';
import { useSelector } from 'react-redux';

const CompanyByUser = ({ companyByUser }) => {
  const userId = localStorage.getItem('userId');
  const [selectedRole, setSelectedRole] = useState();
  const [isShownDeleteDialog, setIsShownDeleteDialog] = useState(false);

  const removeUser = (company) => {
    let statusResponse = '';
    let toastContent = '';

    console.log('companyByUser: ', company);

    const user = {
      adminId: userId,
      companyId: company.companyId,
      userId: company.vitaUserId,
      roleId: company.userRoleId,
    };

    console.log('userRoleDelete: ', user);
    deleteUserRoles(user)
      .then((res) => {
        console.log('res post deleted user: ', res);
        mutate('userRolesCompanies');
        statusResponse = 'success';
        toastContent = 'Usuário removido com sucesso.';
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = err.data;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        setIsShownDeleteDialog(false);
        // mutate('banks');
      });
  };
  return (
    <>
      <Dialog
        isShown={isShownDeleteDialog}
        title='Deletar lançamento'
        intent='danger'
        onCloseComplete={() => setIsShownDeleteDialog(false)}
        confirmLabel='Remover'
        cancelLabel='Cancelar'
        onConfirm={() => removeUser(companyByUser)}
        preventBodyScrolling
      >
        Tem certeza que deseja remover seu acesso à esta empresa?
      </Dialog>
      <Table.Row>
        <Table.TextCell>{companyByUser.companyName}</Table.TextCell>
        <Table.TextCell>{companyByUser.userRole}</Table.TextCell>
        <Table.TextCell>
          <TrashIcon
            className='trash-icon'
            size={20}
            padding={2}
            marginRight={2}
            onClick={() => setIsShownDeleteDialog(true)}
          />
        </Table.TextCell>
      </Table.Row>
      <style scoped jsx='true'>{`
        .trash-icon:hover {
          color: red;
          cursor: pointer;
          transform: scale(1.2); /* Add this line to make it bigger */
        }

        .save-icon:hover {
          color: green;
          cursor: pointer;
          transform: scale(1.2); /* Add this line to make it bigger */
        }
      `}</style>
    </>
  );
};

export default CompanyByUser;
