import { arrayDre } from '../../helpers/dreHelper';
import {
  formattedIndexToMonthObject,
  formattedMonthsArray,
} from '../../helpers/dreHelper2';

export const contrastedColors = [
  // 'hsl(44.267715717599516, 88.95603707943127%, 61.25950754184247%)',
  // 'hsl(102.89218676829976, 95.6635004745472%, 66.82430014211111%)',
  // 'hsl(160.84857094727434, 97.6747895449212%, 70.24944256940471%)',
  'hsl(207.00006242933738, 91.57362277325026%, 59.39231216633392%)',
  // 'hsl(251.4933290394062, 98.24847231298125%, 47.343516704134535%)',
  'hsl(37.36167497068949, 97.25266782684803%, 59.78686260085219%)',
  // 'hsl(78.53374464352108, 97.22537714443882%, 45.55153306327122%)',
  'hsl(105.14518440093378, 91.49620226124712%, 66.17938838219828%)',
  // 'hsl(161.8291846384883, 84.99092559417644%, 61.12046317642462%)',
  'hsl(216.7812643457596, 99.68451284074225%, 57.37117786914696%)',
  // 'hsl(19.56281808696341, 95.99245859916877%, 53.840169815956165%)',
  'hsl(58.25368911161517, 95.62595024917696%, 60.11956126296196%)',
  // 'hsl(106.99034696230784, 91.70174861259809%, 47.18313848812447%)',
  'hsl(156.05455758607798, 96.9688626120527%, 48.74081935523481%)',
  // 'hsl(212.71991271639444, 84.22221339495005%, 66.2159109063538%)',
  'hsl(21.12559258771808, 84.10259152528726%, 64.52058705116437%)',
  // 'hsl(69.01382248236738, 99.44687644052275%, 50.198165732334496%)',
  'hsl(115.04472542068538, 86.84177583357826%, 65.17488982174511%)',
  // 'hsl(157.01906462089037, 95.3920342054732%, 60.52232082498799%)',
  'hsl(223.93346122695753, 97.49465255994637%, 64.37071614179458%)',
  // 'hsl(27.055935967827085, 99.41205721506042%, 62.09519088413422%)',
  'hsl(77.14919762330012, 86.8223823588116%, 68.19831451368407%)',
  // 'hsl(124.14430193644467, 95.28834075857355%, 49.46899702492068%)',
  'hsl(170.45542789885934, 97.68768437406843%, 64.38088081075645%)',
  'hsl(233.75602867779152, 99.12288070251506%, 62.31631317877525%)',
  'hsl(32.2014647036541, 96.61149568472036%, 65.52715933852572%)',
  'hsl(86.15271968197107, 98.03896215211315%, 70.73118499919513%)',
  'hsl(134.36618588096694, 92.32445551836906%, 58.82043808938469%)',
  'hsl(179.31354877083677, 95.60926076848201%, 62.20411169478805%)',
  'hsl(244.73922814820604, 87.2392869321221%, 65.18457817659027%)',
  'hsl(37.86402131411154, 91.95563510864663%, 63.94498827263516%)',
  'hsl(91.79166100254073, 85.8696963531908%, 68.26761789255622%)',
  'hsl(141.7931389907852, 89.72801652257327%, 65.01820874249614%)',
  'hsl(187.8222826985381, 97.06208566705753%, 51.83733483567165%)',
  'hsl(255, 99.18069976514434%, 54.34070100478839%)',
  'hsl(49.132183719792636, 97.49584977135233%, 54.18367262085878%)',
  'hsl(98.70937483841394, 95.55901844475222%, 66.99513144250501%)',
  'hsl(147.15589065610134, 91.70300782977965%, 46.40310127781808%)',
  'hsl(193.81569547501134, 94.67558849158866%, 49.990849650589585%)',
  'hsl(17.165829656666596, 99.70794705144266%, 68.63743231825886%)',
  'hsl(60.054158597983106, 99.57166179764196%, 68.08313152233097%)',
  'hsl(108.57870108430513, 94.14231466965162%, 51.33167436327185%)',
  'hsl(153.0701235357594, 94.27529718896596%, 46.75933204698355%)',
  'hsl(201.09660205006722, 95.36914700401591%, 67.66913459068813%)',
  'hsl(15.416317451053826, 97.00965664257165%, 51.80699399449048%)',
  'hsl(64.61935150569338, 97.70066150712147%, 70.37323728612816%)',
  'hsl(112.5505130732552, 91.36713194383442%, 59.115199134265714%)',
  'hsl(158.70033154729926, 97.10620872060626%, 47.88502817259562%)',
  'hsl(207.9072459032469, 95.32225612382248%, 62.49107744866196%)',
  'hsl(10.93987004878765, 98.16208481695902%, 57.861671376974185%)',
  'hsl(70.27479401240117, 87.76940047377133%, 55.74210278161864%)',
  'hsl(118.74579897895652, 95.36671465054827%, 64.40110848034675%)',
  'hsl(166.41437615892926, 99.37685043903547%, 52.993512077936265%)',
  'hsl(214.60923706683115, 94.75677262866083%, 66.87544222552486%)',
];

export const contrastedColors2 = [
  '#2A9D8F',
  '#E9C46A',
  '#F4A261',
  '#E76F51',
  '#59e5ab',
  '#79d187',
  '#BCBFD1',
  '#4e90aa',
  '#3599B8',
  '#DFBFBF',
  '#4AC5BB',
  '#36ed7f',
  '#FB8281',
  '#F4D25A',
  '#7F898A',
  '#A4DDEE',
  '#FDAB89',
  '#B687AC',
  '#1abff2',
  '#A78F8F',
  '#168980',
  '#d653d4',
  '#BB4A4A',
  '#B59525',
  '#475052',
  '#6A9FB0',
  '#BD7150',
  '#7B4F71',
  '#1B4D5C',
  '#706060',
  '#0F5C55',
  '#1C2325',
];

export const months = [
  { id: 1, name: 'Janeiro' },
  { id: 2, name: 'Fevereiro' },
  { id: 3, name: 'Março' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Maio' },
  { id: 6, name: 'Junho' },
  { id: 7, name: 'Julho' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Setembro' },
  { id: 10, name: 'Outubro' },
  { id: 11, name: 'Novembro' },
  { id: 12, name: 'Dezembro' },
];

export const createNetProfit = (
  entries,
  entryStatus,
  filterType,
  setChartData
) => {
  const dataObjectsArray = entries.data
    .filter((entry) => {
      return entry.journalEntryType === 1;
    })
    .filter((entry) => {
      if (entryStatus === 'Pago') {
        return entry.statusString === 'Pago';
      } else if (entryStatus === 'Pendente') {
        return entry.statusString !== 'Pago';
      } else {
        return entry;
      }
    })
    .map((entry) => {
      return {
        entryType: entry.entryType,
        value: entry.valueFinal,
        month:
          formattedIndexToMonthObject[
            new Date(entry[filterType]).getUTCMonth()
          ],
      };
    });
  // console.log('dataObjectsArray: ', dataObjectsArray);
  const formattedArray = formattedMonthsArray.map((month) => {
    const tempObj = {
      name: month.substring(0, 3),
      Entrada: 0,
      Saída: 0,
      'Lucro Líquido': 0,
    };
    dataObjectsArray.map((entry) => {
      if (entry.month === month) {
        tempObj[entry.entryType] += entry.value;
        tempObj['Lucro Líquido'] =
          entry.entryType === 'Entrada'
            ? tempObj['Lucro Líquido'] + entry.value
            : tempObj['Lucro Líquido'] - entry.value;
      }
    });
    return tempObj;
  });
  // console.log('Formatted Array: ', formattedArray);
  setChartData(formattedArray);
};

export const createAccumulatedBalance = (
  entries,
  entryStatus,
  filterType,
  setChartData
) => {
  const dataObjectsArray = entries.data
    .filter((entry) => {
      return entry.journalEntryType !== 5;
    })
    .filter((entry) => {
      if (entryStatus === 'Pago') {
        return entry.statusString === 'Pago';
      } else if (entryStatus === 'Pendente') {
        return entry.statusString !== 'Pago';
      } else {
        return entry;
      }
    })
    .map((entry) => {
      return {
        entryType:
          entry.entryType === 'Entrada' || entry.entryType === 'Saldo Inicial'
            ? 'Entrada'
            : 'Saída',
        value: entry.valueFinal,
        month:
          formattedIndexToMonthObject[
            new Date(entry[filterType]).getUTCMonth()
          ],
      };
    });
  // console.log('dataObjectsArray: ', dataObjectsArray);
  let accumulatedBalance = 0;
  const formattedArray = formattedMonthsArray.map((month) => {
    const tempObj = {
      name: month.substring(0, 3),
      Entrada: 0,
      Saída: 0,
      'Saldo Acumulado': 0,
    };
    dataObjectsArray.map((entry) => {
      if (entry.month === month) {
        tempObj[entry.entryType] += entry.value;
        accumulatedBalance =
          entry.entryType === 'Entrada'
            ? accumulatedBalance + entry.value
            : accumulatedBalance - entry.value;
        tempObj['Saldo Acumulado'] = accumulatedBalance;
      } else {
        tempObj['Saldo Acumulado'] = accumulatedBalance;
      }
    });
    return tempObj;
  });
  // console.log('Formatted Array: ', formattedArray);
  setChartData(formattedArray);
};

export const createPieChart = (
  entries,
  entryStatus,
  inputType,
  dataTypeValue,
  setChartData,
  setSelectedSet,
  setTotalValue,
  filterType,
  selectedMonths
) => {
  const dreSet = new Set();
  const groupingSet = new Set();
  const accPlanSet = new Set();
  const categorySet = new Set();

  const dreArray = [];
  const groupingArray = [];
  const accPlanArray = [];
  const categoryArray = [];

  let totalValue = 0;

  const dataObjectsArray = entries.data
    .filter((entry) => {
      return entry.journalEntryType === 1;
    })
    .filter((entry) => {
      if (entryStatus === 'Pago') {
        return entry.statusString === 'Pago';
      } else if (entryStatus === 'Pendente') {
        return entry.statusString !== 'Pago';
      } else {
        return entry;
      }
    })
    .filter((entry) => {
      if (inputType === 'Entrada') {
        return entry.entryType === 'Entrada';
      }
      if (inputType === 'Saída') {
        return entry.entryType === 'Saída';
      }
    })
    .filter((entry) => {
      if (selectedMonths.length > 0) {
        return selectedMonths.includes(
          new Date(entry[filterType]).getMonth() + 1
        );
      } else {
        return false;
      }
    })
    .map((entry) => {
      dreSet.add(entry.dreClassification);
      groupingSet.add(entry.dreGroupingName);
      accPlanSet.add(entry.accountPlanName);
      categorySet.add(entry.categoryName);
      return {
        entryType: entry.entryType,
        value: entry.valueFinal,
        category: entry.categoryName,
        grouping: entry.dreGroupingName,
        accPlan: entry.accountPlanName,
        dre: entry.dreClassification,
        month:
          formattedIndexToMonthObject[
            new Date(entry[filterType]).getUTCMonth()
          ],
      };
    });

  if (dataTypeValue === 'DRE') {
    dreSet.forEach((dre) => {
      const tempObj = {
        name: dre,
        entriesCount: 0,
        value: 0,
      };
      dataObjectsArray.forEach((entry) => {
        if (entry.dre === dre) {
          tempObj.entriesCount += 1;
          tempObj.value += entry.value;
          totalValue += entry.value;
        }
      });
      dreArray.push(tempObj);
    });
    setChartData(dreArray.sort((a, b) => b.value - a.value));
    setSelectedSet(dreArray.map((dre) => dre.name));
    setTotalValue(totalValue);
    // console.log('DRE Array: ', dreArray);
  } else if (dataTypeValue === 'Agrupamento') {
    groupingSet.forEach((grouping) => {
      const tempObj = {
        name: grouping,
        entriesCount: 0,
        value: 0,
      };
      dataObjectsArray.forEach((entry) => {
        if (entry.grouping === grouping) {
          tempObj.entriesCount += 1;
          tempObj.value += entry.value;
          totalValue += entry.value;
        }
      });
      groupingArray.push(tempObj);
    });
    setChartData(groupingArray.sort((a, b) => b.value - a.value));
    setSelectedSet(groupingArray.map((grouping) => grouping.name));
    setTotalValue(totalValue);
    // console.log('Grouping Array: ', groupingArray);
  } else if (dataTypeValue === 'Plano de Conta') {
    accPlanSet.forEach((accPlan) => {
      const tempObj = {
        name: accPlan,
        entriesCount: 0,
        value: 0,
      };
      dataObjectsArray.forEach((entry) => {
        if (entry.accPlan === accPlan) {
          tempObj.entriesCount += 1;
          tempObj.value += entry.value;
          totalValue += entry.value;
        }
      });
      accPlanArray.push(tempObj);
    });
    setChartData(accPlanArray.sort((a, b) => b.value - a.value));
    setSelectedSet(accPlanArray.map((accPlan) => accPlan.name));
    setTotalValue(totalValue);
    // console.log('AccPlan Array: ', accPlanArray);
  } else if (dataTypeValue === 'Categoria') {
    categorySet.forEach((category) => {
      const tempObj = {
        name: category,
        entriesCount: 0,
        value: 0,
      };
      dataObjectsArray.forEach((entry) => {
        if (entry.category === category) {
          tempObj.entriesCount += 1;
          tempObj.value += entry.value;
          totalValue += entry.value;
        }
      });
      categoryArray.push(tempObj);
    });
    setChartData(categoryArray.sort((a, b) => b.value - a.value));
    setSelectedSet(categoryArray.map((category) => category.name));
    setTotalValue(totalValue);
    // console.log('Category Array: ', categoryArray);
  }
};

export const createBanksChart = (bankBalance, selectedBanks, setBanksChart) => {
  const banksArray = [];
  // console.log('bankBalance: ', bankBalance.data);
  const totalBankBalance = {
    name: 'Total',
    Consolidado: 0,
    Pendente: 0,
  };
  bankBalance.data.map((bank) => {
    if (selectedBanks.includes(bank.name)) {
      banksArray.push({
        id: bank.id,
        name: bank.name,
        Consolidado: bank.inflowConsolidated - bank.outflowConsolidated,
        Pendente: bank.inflowNotConsolidated - bank.outflowNotConsolidated,
      });
      totalBankBalance.Consolidado +=
        bank.inflowConsolidated - bank.outflowConsolidated;
      totalBankBalance.Pendente +=
        bank.inflowNotConsolidated - bank.outflowNotConsolidated;
    }
  });
  banksArray.push(totalBankBalance);
  // selectedBanks.forEach((bank) => {
  //   const tempObj = {
  //     name: bank,
  //     value: bankBalance[bank],
  //   };
  //   banksArray.push(tempObj);
  // });
  setBanksChart(banksArray);
};

export const createDreResults = (entries, selectedDre, setChartData) => {
  const formattedArray = formattedMonthsArray.map((month) => {
    const tempObj = {
      month: month.substring(0, 3),
      Valor: 0,
      Percentual: 0,
      name: '',
    };
    entries
      .filter((entry) => entry.description === selectedDre)
      .map((entry) => {
        if (month === 'Março') {
          tempObj.Valor = entry['marco'];
          tempObj.Percentual = entry['marcoPercent'];
          tempObj.name = entry.description;
          return;
        }
        tempObj.Valor = entry[month.charAt(0).toLowerCase() + month.slice(1)];
        tempObj.Percentual =
          entry[month.charAt(0).toLowerCase() + month.slice(1) + 'Percent'];
        tempObj.name = entry.description;
      });
    return tempObj;
  });
  console.log('Formatted Array: ', formattedArray);
  setChartData(formattedArray);
};
