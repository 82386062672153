import {
  BanCircleIcon,
  ListItem,
  TickCircleIcon,
  UnorderedList,
  WarningSignIcon,
} from 'evergreen-ui';

export const checkString = (str) => {
  let lowerCase = /[a-z]/;
  let upperCase = /[A-Z]/;
  let numbers = /[0-9]/;
  let special = /[!@#$%&*]/;
  let sixChars = str.length >= 6;

  return (
    <UnorderedList size={300} marginBottom={20}>
      <ListItem
        icon={
          str === ''
            ? WarningSignIcon
            : lowerCase.test(str)
            ? TickCircleIcon
            : BanCircleIcon
        }
        iconColor={
          str === '' ? 'warning' : lowerCase.test(str) ? 'success' : 'danger'
        }
        style={{
          color: str === '' ? 'orange' : lowerCase.test(str) ? 'green' : 'red',
        }}
      >
        Uma letra minúscula (a-z)
      </ListItem>
      <ListItem
        icon={
          str === ''
            ? WarningSignIcon
            : upperCase.test(str)
            ? TickCircleIcon
            : BanCircleIcon
        }
        iconColor={
          str === '' ? 'warning' : upperCase.test(str) ? 'success' : 'danger'
        }
        style={{
          color: str === '' ? 'orange' : upperCase.test(str) ? 'green' : 'red',
        }}
      >
        Uma letra maiúscula (A-Z)
      </ListItem>
      <ListItem
        icon={
          str === ''
            ? WarningSignIcon
            : numbers.test(str)
            ? TickCircleIcon
            : BanCircleIcon
        }
        iconColor={
          str === '' ? 'warning' : numbers.test(str) ? 'success' : 'danger'
        }
        style={{
          color: str === '' ? 'orange' : numbers.test(str) ? 'green' : 'red',
        }}
      >
        Um número (0-9)
      </ListItem>
      <ListItem
        icon={
          str === ''
            ? WarningSignIcon
            : special.test(str)
            ? TickCircleIcon
            : BanCircleIcon
        }
        iconColor={
          str === '' ? 'warning' : special.test(str) ? 'success' : 'danger'
        }
        style={{
          color: str === '' ? 'orange' : special.test(str) ? 'green' : 'red',
        }}
      >
        Um símbolo (!@#$%&*)
      </ListItem>
      <ListItem
        icon={
          str === ''
            ? WarningSignIcon
            : sixChars
            ? TickCircleIcon
            : BanCircleIcon
        }
        iconColor={str === '' ? 'warning' : sixChars ? 'success' : 'danger'}
        style={{
          color: str === '' ? 'orange' : sixChars ? 'green' : 'red',
        }}
      >
        Pelo menos 6 caracteres
      </ListItem>
    </UnorderedList>
  );
};
