import { React, useEffect, useState } from 'react';

import { auth } from '../../firebase-config';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword,
  updatePhoneNumber,
  updateProfile,
  verifyPasswordResetCode,
} from 'firebase/auth';

import {
  BanCircleIcon,
  Button,
  Card,
  FormField,
  Heading,
  TextInputField,
  EyeOpenIcon,
  EyeOffIcon,
  Tooltip,
  Position,
  Text,
} from 'evergreen-ui';

import { signUp } from '../../services/api/signInUpService';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { toastNotification } from '../../shared/toastNotification';
import { checkString } from '../../helpers/signInUpValidation';
import { postVitaUser } from '../../services/api/vitaUserService';

const NewPasswordCard = ({ setLogin }) => {
  const [loading, setLoading] = useState(false);
  const [alertIsShown, setAlertIsShown] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.forEach((value, key) => {
      console.log('searchParams: ', key, value);
    });
    const oobCode = searchParams.get('oobCode');
    const mode = searchParams.get('mode');
    const apiKey = searchParams.get('apiKey');
    const continueUrl = searchParams.get('continueUrl');

    let statusResponse = '';
    let toastContent = '';

    verifyPasswordResetCode(auth, oobCode)
      .then((email) => {
        console.log('email: ', email);
        if (!email) {
          statusResponse = 'warning';
          toastContent = 'Link inválido.';
          toastNotification(statusResponse, toastContent);
          navigate('/');
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        switch (error.code) {
          case 'auth/expired-action-code':
            statusResponse = 'warning';
            toastContent = 'Link expirado. Solicite um novo link.';
            navigate('/alterar-senha');
            break;
          case 'auth/invalid-action-code':
            statusResponse = 'warning';
            toastContent = 'Link inválido.';
            break;
          case 'auth/user-disabled':
            statusResponse = 'warning';
            toastContent = 'Usuário desativado.';
            break;
          case 'auth/user-not-found':
            statusResponse = 'warning';
            toastContent = 'Usuário não encontrado.';
            break;
          default:
            statusResponse = 'warning';
            toastContent = 'Erro ao verificar link. Tente novamente.';
            break;
        }
        toastNotification(statusResponse, toastContent);
        navigate('/');
      });
  }, []);

  const handlePasswordReset = async (data) => {
    let statusResponse = false;
    let toastContent = '';
    let actionCodeSettings = {
      // url: 'https://novo.vita.app.br',
    };
    confirmPasswordReset(auth, searchParams.get('oobCode'), data.password)
      .then(() => {
        statusResponse = 'success';
        toastContent = `Senha alterada com sucesso.`;
        setTimeout(() => {
          navigate('/');
        }, 1500);
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          statusResponse = 'error';
          toastContent = 'Usuário não encontrado.';
        } else if (error.code === 'auth/invalid-email') {
          statusResponse = 'error';
          toastContent = 'E-mail inválido.';
        } else if (error.code === 'auth/too-many-requests') {
          statusResponse = 'error';
          toastContent = 'Muitas tentativas. Tente mais tarde.';
        } else {
          statusResponse = 'error';
          toastContent = 'Erro ao enviar e-mail.';
        }
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });
  const {
    errors,
    dirtyFields,
    isDirty,
    isSubmitting,
    touchedFields,
    submitCount,
  } = formState;

  const [inputType, setInputType] = useState(true);
  const [iconState, setIconState] = useState(true);

  const togglePassword = () => {
    setInputType((old) => !old);
    setIconState((old) => !old);
  };

  return (
    <div className='content'>
      <Card
        elevation={4}
        float='left'
        background='white'
        margin={24}
        padding={16}
        display='flex'
        gap={16}
        height='fit-content'
        // justifyContent="center"
        // alignItems="center"
        flexDirection='column'
      >
        <Heading size={700}>Alterar Senha</Heading>
        <form id='signup-form' onSubmit={handleSubmit(handlePasswordReset)}>
          <FormField display='flex' flexDirection='column' gap={0}>
            <div style={{ display: 'flex' }}>
              <TextInputField
                {...register('password', {
                  required: 'Obrigatório.',
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/gm,
                    // message: 'Padrão incorreto.',
                  },
                  minLength: {
                    value: 6,
                    // message: 'A senha deve conter pelo menos 6 caracteres',
                  },
                })}
                type={inputType ? 'password' : 'text'}
                label='Nova Senha'
                validationMessage={errors.password?.message}
                width='100%'
                marginBottom={5}
                onChange={(e) => {
                  setValue('password', e.target.value);
                }}
              />
              {iconState ? (
                <Tooltip
                  content={<Text fontSize={12}>Mostrar senha</Text>}
                  position={Position.TOP}
                  showDelay={300}
                >
                  <EyeOffIcon
                    color='gray'
                    onClick={togglePassword}
                    alignSelf='center'
                    marginLeft={-25}
                    marginTop={18}
                    cursor='pointer'
                    opacity='50%'
                    size={18}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  content={<Text fontSize={12}>Ocultar senha</Text>}
                  position={Position.TOP}
                  showDelay={300}
                >
                  <EyeOpenIcon
                    color='gray'
                    onClick={togglePassword}
                    alignSelf='center'
                    marginLeft={-25}
                    marginTop={18}
                    cursor='pointer'
                  />
                </Tooltip>
              )}
            </div>
            {touchedFields && checkString(watch('password'))}
            <TextInputField
              {...register('passwordConfirmation', {
                // required: 'Obrigatório.',
                validate: (val) => {
                  if (watch('password') != val) {
                    return 'Senhas não coincidem.';
                  }
                },
              })}
              type={inputType ? 'password' : 'text'}
              label='Repetir senha'
              validationMessage={errors.passwordConfirmation?.message}
            />
            {/* {errors?.passwordConfirmation && (
                <p>password cannot less than 5 characters</p>
              )} */}
            <Button
              marginRight={16}
              appearance='minimal'
              color='blue'
              size='large'
              margin={0}
              isLoading={loading}
              form='signup-form'
              type='submit'
            >
              Alterar Senha
            </Button>
          </FormField>
        </form>
        <style jsx='true'>
          {`
            .content {
              display: flex;
              flex-direction: row-reverse;
              gap: 36px;
              height: 100%;
              width: 100%;
              background: linear-gradient(
                257.63deg,
                rgba(9, 153, 157, 0.25) -0.36%,
                rgba(59, 128, 177, 0.25) 14.31%,
                rgba(0, 36, 164, 0.25) 40.45%,
                rgba(169, 107, 231, 0.25) 88.1%,
                rgba(243, 72, 91, 0.25) 120.83%
              );
            }
          `}
        </style>
      </Card>
    </div>
  );
};

export default NewPasswordCard;
