import { React, useEffect, useState } from 'react';

import { Card } from 'evergreen-ui';
import SignInComponent from './SignInComponent';
import SwitchSignInUpComponent from './SwitchSignInUpComponent';
import { useLocation } from 'react-router-dom';
import SignUpComponent from './SignUpComponent';

export default function BoxSignComponent() {
  const [login, setLogin] = useState(true);

  const switchCardSign = () => {
    let newState = !login;
    setLogin(newState);
  };

  const urlParams = useLocation().search;
  const actionParam = new URLSearchParams(urlParams).get('action');

  useEffect(() => {
    if (actionParam && actionParam == 'sign-in') {
      setLogin(true);
    } else if (actionParam && actionParam == 'sign-up') {
      setLogin(false);
    }
  }, [actionParam]);

  return (
    <div className='content'>
      <Card
        className='wrap-cards-login'
        display='flex'
        flexDirection='column'
        width='fit-content'
      >
        {login ? <SignInComponent /> : <SignUpComponent setLogin={setLogin} />}
        <SwitchSignInUpComponent login={login} switchCard={switchCardSign} />
      </Card>
      <style jsx='true'>
        {`
          .content {
            display: flex;
            flex-direction: row-reverse;
            gap: 36px;
            height: 100%;
            width: 100%;
            background: linear-gradient(
              257.63deg,
              rgba(9, 153, 157, 0.25) -0.36%,
              rgba(59, 128, 177, 0.25) 14.31%,
              rgba(0, 36, 164, 0.25) 40.45%,
              rgba(169, 107, 231, 0.25) 88.1%,
              rgba(243, 72, 91, 0.25) 120.83%
            );
          }
        `}
      </style>
    </div>
  );
}
