import useSWR from 'swr';
import request from './request';

/*
  Axios call methods
*/

export const getUserRoles = async (companyId) => {
  return request({
    url: `UserRoles/${companyId}`,
    method: 'GET',
  });
};

export const getCompaniesByUserRoles = async (vitaUserId) => {
  return request({
    url: `UserRoles/companies/${vitaUserId}`,
    method: 'GET',
  });
};

export const putEntry = async (entry) => {
  console.log('putEntry: ', entry);
  return request({
    url: `JournalEntries/${entry.id}`,
    method: 'POST',
    data: entry,
  });
};

export const postGetWithUserIdUserRoles = async (data) => {
  console.log('postGetWithUserIdUserRoles: ', data);
  return request({
    url: `UserRoles/search`,
    method: 'POST',
    data: data,
  });
};

export const postGetCurrentRole = async (data) => {
  console.log('postGetCurrentRole: ', data);
  return request({
    url: `UserRoles/currentRole`,
    method: 'POST',
    data: data,
  });
};

export const postInviteUserRoles = async (userRoles) => {
  console.log('postUserRoles: ', userRoles);
  return request({
    url: `UserRoles/invite`,
    method: 'POST',
    data: userRoles,
  });
};

export const postEditUserRoles = async (userRoles) => {
  console.log('postEditUserRoles: ', userRoles);
  return request({
    url: `UserRoles/update`,
    method: 'POST',
    data: userRoles,
  });
};

export const deleteUserRoles = async (userRoles) => {
  console.log('deleteUserRoles: ', userRoles);
  return request({
    url: `UserRoles/delete`,
    method: 'DELETE',
    data: userRoles,
  });
};

export const deleteManyEntries = async (entryList) => {
  console.log('deleteManyEntry: ', entryList);
  return request({
    url: `JournalEntries/DeleteMany`,
    method: 'DELETE',
    data: entryList,
  });
};

/*
  useQuery - SWR methods
*/

export const useQueryUserRoles = (companyId, options) => {
  const key = 'userRoles';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId ? () => getUserRoles(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryUserRolesCurrentRole = (userData, options) => {
  const key = 'userRolesCurrentRole';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    userData ? () => postGetCurrentRole(userData) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryUserRolesWithUserId = (userData, options) => {
  const key = 'userRolesWithUserId';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    userData ? () => postGetWithUserIdUserRoles(userData) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryCompaniesByUserRoles = (vitaUserId, options) => {
  const key = 'userRolesCompanies';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    vitaUserId ? () => getCompaniesByUserRoles(vitaUserId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};
