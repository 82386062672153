import { Card, Heading, Pane, Text } from 'evergreen-ui';
import React from 'react';
import iconUp from '../../assets/icons8-up-48.png';
import iconDown from '../../assets/icons8-down-48.png';
import { formatToMoney } from '../../helpers/moneyFormatter';
import { useSelector } from 'react-redux';

function TransferResultComponent() {
  const transferInput = useSelector(
    (state) => state.balanceValues.transferInput
  );
  const transferOutput = useSelector(
    (state) => state.balanceValues.transferOutput
  );

  const flows = [
    {
      title: 'Entrada',
      value: transferInput,
      icon: iconUp,
      background: '#defce5',
    },
    {
      title: 'Saída',
      value: transferOutput,
      icon: iconDown,
      background: '#FFEDED',
    },
  ];

  return (
    <Pane
      display='flex'
      flexWrap='wrap'
      paddingY={15}
      paddingX={24}
      gap={36}
    >
      {flows.map((flow, index) => (
        <Pane
          display='flex'
          gap={15}
          alignItems='center'
          key={index}
        >
          <Card
            // background={flow.background}
            width={40}
            height={46}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <img
              className='img'
              loading='lazy'
              src={flow.icon}
              alt='Entrada'
            />
          </Card>
          <Pane display='flex' flexDirection='column' gap={6}>
            <Text
              letterSpacing={0.8}
              color='#727272'
              fontWeight={500}
            >
              {flow.title}
            </Text>
            <Heading size={600} whiteSpace='nowrap'>
              R$ {formatToMoney(flow.value)}
            </Heading>
          </Pane>
        </Pane>
      ))}

      <style jsx='true' scoped={true}>
        {`
          .img {
            width: 1.9em;
            transform: rotate(45deg);
          }
        `}
      </style>
    </Pane>
  );
}

export default TransferResultComponent;
