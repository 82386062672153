import useSWR from 'swr';
import request from './request';

/*
  Axios call methods
*/

export const getDreGrouping = async (companyId) => {
  return request({
    url: `DreGrouping/Company/${companyId}`,
    method: 'GET',
  });
};

export const getDreGroupingItem = async (id) => {
  return request({
    url: `DreGrouping/${id}`,
    method: 'GET',
  });
};

export const putDreGroupingItem = async (dreGroup) => {
  console.log('putDreGroupingItem: ', dreGroup);
  return request({
    url: `DreGrouping/${dreGroup.id}`,
    method: 'PUT',
    data: dreGroup,
  });
};

export const postDreGroupingItem = async (dreGroup) => {
  console.log('postDreGroupingItem: ', dreGroup);
  return request({
    url: `DreGrouping/`,
    method: 'POST',
    data: dreGroup,
  });
};
export const deleteDreGroupingItem = async (dreGroup) => {
  console.log('deleteDreGroupingItem: ', dreGroup);
  return request({
    url: `DreGrouping?id=${dreGroup}`,
    method: 'DELETE',
  });
};

/*
  useQuery - SWR methods
*/

export const useQueryDreGrouping = (companyId, options) => {
  const key = 'dreGrouping';
  const { data, error } = useSWR(
    () => key,
    companyId ? () => getDreGrouping(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryDreGroupingItem = (id, options) => {
  const key = 'dreGroupingItem';
  const { data, error } = useSWR(
    () => key,
    id ? () => getDreGroupingItem(id) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
