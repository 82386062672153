import {
  ArrowBottomRightIcon,
  ArrowTopLeftIcon,
  Pane,
} from 'evergreen-ui';

export const getNameByJournal = (entry) => {
  switch (entry.journalEntryType) {
    case 1:
      if (entry.isInput) return 'Entrada';
      return 'Saída';
    case 2:
      if (entry.isInput) return 'Entrada';
      return 'Saída';
    case 3:
      if (entry.isInput) return 'Entrada';
      return 'Saída';
    case 5:
      return 'Transferência';
    default:
      if (entry.isInput) return 'Entrada';
      return 'Saída';
  }
};

export const getColorByJournal = (entry) => {
  switch (entry.journalEntryType) {
    case 1:
      if (entry.isInput) return 'blue';
      return 'red';
    case 3:
      if (entry.isInput) return 'blue';
      return 'red';
    case 5:
      return 'green';
    default:
      return 'gray';
  }
};

export const getNameAndColorEntryType = (cell) => {
  switch (cell.entryType) {
    case 'Entrada':
      return ['Entrada', 'blue'];
    case 'Saída':
      return ['Saída', 'red'];
    case 'Saldo Inicial':
      return ['Saldo Inicial', 'rgba(5, 147, 29, 0.9)'];
    case 'Transferência':
      return [
        <Pane display='flex' alignItems='center' gap={8}>
          <>Transferência</>
          {cell.isInput ? (
            <ArrowTopLeftIcon size={10} color='#545cf7' />
          ) : (
            <ArrowBottomRightIcon size={12} color='#ed6f6f' />
          )}
        </Pane>,
        'green',
      ];
    default:
      return 'neutral';
  }
};

export const getIntentColorByJournal = (entry) => {
  switch (entry.journalEntryType) {
    case 1:
      if (entry.isInput) return 'selected';
      return 'danger';
    case 3:
      if (entry.isInput) return 'selected';
      return 'danger';
    case 5:
      return 'success';
    default:
      return 'none';
  }
};

export const getIconByJournal = (entry) => {
  switch (entry.journalEntryType) {
    case 1:
      if (entry.isInput) return 'ArrowTopRightIcon';
      return 'ArrowBottomRightIcon';
    case 3:
      if (entry.isInput) return 'ArrowTopRightIcon';
      return 'ArrowBottomRightIcon';
    case 5:
      return 'SwapHorizontalIcon';
    default:
      return '';
  }
};
