import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  realValuesCheckbox: true,
  percentValuesCheckbox: false,
  entryStatus: 'Todos',
  filterType: 'dateCompetencia',
  uncheckedMonths: [],
};

const dreValuesSlice = createSlice({
  name: 'dreValues',
  initialState: initialFormState,
  reducers: {
    setRealValuesCheckbox(state, action) {
      state.realValuesCheckbox = action.payload;
    },
    setPercentValuesCheckbox(state, action) {
      state.percentValuesCheckbox = action.payload;
    },
    setEntryStatus(state, action) {
      state.entryStatus = action.payload;
    },
    setFilterType(state, action) {
      state.filterType = action.payload;
    },
    setUncheckedMonths(state, action) {
      state.uncheckedMonths = action.payload;
    },
  },
});

export const dreValuesActions = dreValuesSlice.actions;

export default dreValuesSlice.reducer;
