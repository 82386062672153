import { Pane } from "evergreen-ui";
import React from "react";

function MainOutletComponent({ children }) {
  return (
    <Pane display="flex" flex={1} overflow="hidden">
      {children}
      <style jsx="true" scoped={true}>
        {``}
      </style>
    </Pane>
  );
}

export default MainOutletComponent;
