import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  TextInputField,
  FormField,
  Checkbox,
  Pane,
  Button,
  SelectField,
} from 'evergreen-ui';
import { Container, Row, Col } from 'react-grid-system';
import { putBank } from '../../services/api/banksService';
import { toastNotification } from '../../shared/toastNotification';
import { mutate } from 'swr';
import {
  bankAccountTypeArray,
  inputBankValidation,
} from '../../helpers/formHelper';

function BankAccountsEditFormComponent({
  isShown,
  setIsShown,
  bankAccount = {},
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({
    defaultValues: bankAccount,
  });

  useEffect(() => {
    reset(bankAccount);
    setChecked(bankAccount.isPrincipal);
    console.log('bankAccount', bankAccount);
  }, [isShown]);

  useEffect(() => {
    inputBankValidation(
      watch('agencyNumber'),
      watch('name'),
      watch('accountBankNumber'),
      watch('description'),
      watch('bankAccountType'),
      setIsDisabled
    );
  }, [
    watch('agencyNumber'),
    watch('name'),
    watch('accountBankNumber'),
    watch('description'),
    watch('bankAccountType'),
  ]);

  const bankAccountFormFooter = () => {
    return (
      <Pane>
        <Button
          form='banks-form'
          type='submit'
          width='fit-content'
          id='onSubmit'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onClose = () => {
    reset();
    setIsShown(false);
  };

  const onSubmit = (bankAccount) => {
    console.log('onSubmit');
    let statusResponse = false;
    let toastContent = '';

    let editedBankAccount = {
      isPrincipal: bankAccount.mainAccount,
      name: bankAccount.name,
      accountType: bankAccount.accountType,
      description: bankAccount.description,
      agencyNumber: bankAccount.agencyNumber,
      accountBankNumber: bankAccount.accountBankNumber,
      id: bankAccount.id,
    };

    console.log(editedBankAccount);

    putBank(editedBankAccount)
      .then((res) => {
        console.log('res put bank: ', res);
        mutate('banks');
        statusResponse = 'success';
        toastContent = 'Conta alterada com sucesso.';
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };
  return (
    <Dialog
      isShown={isShown}
      title={'Editar Conta'}
      onCloseComplete={onClose}
      confirmLabel='Cadastrar'
      hasFooter={true}
      footer={bankAccountFormFooter}
      shouldCloseOnEscapePress={!formState.isDirty}
      width={600}
      backgroundColor='#f8f8f8'
    >
      <div>
        <form id='banks-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('name')}
                    label='Nome'
                    id='name'
                    name='name'
                    type='text'
                  />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('accountType')}
                    label='Tipo de Conta'
                    onChange={(e) =>
                      setValue('bankAccountType', e.target.value)
                    }
                  >
                    {bankAccountTypeArray.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('agencyNumber')}
                    label='Agência'
                    id='agencyNumber'
                    name='agencyNumber'
                    type='text'
                  />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('accountBankNumber')}
                    label='Número da Conta'
                    id='accountBankNumber'
                    name='accountBankNumber'
                    type='text'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInputField
                    {...register('description')}
                    label='Descrição'
                    id='description'
                    name='description'
                    type='text'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    {...register('mainAccount')}
                    label='Conta Principal'
                    id='mainAccount'
                    name='mainAccount'
                    checked={checked}
                    onChange={(e) => {
                      setValue('mainAccount', e.target.checked);
                      setChecked(e.target.checked);
                      console.log(watch('mainAccount'));
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default BankAccountsEditFormComponent;
