import {
  Button,
  Checkbox,
  Heading,
  Menu,
  Pane,
  Popover,
  Position,
  Select,
  StatusIndicator,
  Text,
} from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import { contrastedColors, contrastedColors2, months } from './dashboardHelper';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardValuesActions } from '../../store/dashboardValues';

const PieChartsOptions = ({
  resultValue,
  dataTypeValue,
  setResultValue,
  setDataTypeValue,
  selectedSet,
}) => {
  // const [allMonthsSelected, setAllMonthsSelected] = useState(true);
  // const [selectedMonths, setSelectedMonths] = useState(
  //   months.map((month) => month.id)
  // );
  const dispatch = useDispatch();
  const { allMonthsSelected, selectedMonths } = useSelector(
    (state) => state.dashboardValues
  );

  const handleMonthToggle = (monthId) => {
    if (selectedMonths.includes(monthId)) {
      dispatch(
        dashboardValuesActions.setSelectedMonths(
          selectedMonths.filter((id) => id !== monthId)
        )
      );
      // setSelectedMonths(selectedMonths.filter((id) => id !== monthId));
    } else {
      dispatch(
        dashboardValuesActions.setSelectedMonths([...selectedMonths, monthId])
      );
      // setSelectedMonths([...selectedMonths, monthId]);
    }
  };

  const handleAllMonthsToggle = () => {
    if (allMonthsSelected) {
      dispatch(dashboardValuesActions.setSelectedMonths([]));
      // setSelectedMonths([]);
    } else {
      dispatch(
        dashboardValuesActions.setSelectedMonths(
          months.map((month) => month.id)
        )
      );
      // setSelectedMonths(months.map((month) => month.id));
    }
    dispatch(dashboardValuesActions.setAllMonthsSelected(!allMonthsSelected));
    // setAllMonthsSelected(!allMonthsSelected);
  };

  useEffect(() => {
    if (selectedMonths.length === months.length) {
      dispatch(dashboardValuesActions.setAllMonthsSelected(true));
      // setAllMonthsSelected(true);
    } else {
      dispatch(dashboardValuesActions.setAllMonthsSelected(false));
      // setAllMonthsSelected(false);
    }
  }, [selectedMonths]);

  return (
    <Pane
      width='26%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      gap={5}
    >
      <Pane>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Pane paddingX={15} overflowY='auto' maxHeight={400}>
                <Checkbox
                  type='checkbox'
                  key='mostrar-tudo'
                  label='Mostrar tudo'
                  checked={allMonthsSelected}
                  onChange={handleAllMonthsToggle}
                />
                <Menu.Divider />
                {months.map((month) => (
                  <Checkbox
                    key={month.id}
                    type='checkbox'
                    label={month.name}
                    checked={selectedMonths.includes(month.id)}
                    onChange={() => handleMonthToggle(month.id)}
                  />
                ))}
              </Pane>
            </Menu>
          }
        >
          <Button className='filterButton' flexShrink='inherit'>
            Meses
            {!allMonthsSelected && (
              <StatusIndicator
                color='warning'
                marginLeft={8}
                marginBottom={6}
                marginRight='-8px'
                padding={0}
              />
            )}
          </Button>
        </Popover>
      </Pane>
      <Pane>
        <Heading textAlign='center' color='whitesmoke' padding={5}>
          Tipo
        </Heading>

        <Select
          value={resultValue}
          onChange={(event) => setResultValue(event.target.value)}
          height={20}
          background='white'
          borderRadius={5}
        >
          <option value='Entrada'>Entrada</option>
          <option value='Saída'>Saída</option>
        </Select>
      </Pane>
      <Pane>
        <Heading textAlign='center' color='whitesmoke' padding={5}>
          Níveis
        </Heading>

        <Select
          value={dataTypeValue}
          onChange={(e) => setDataTypeValue(e.target.value)}
          height={20}
          background='white'
          borderRadius={5}
        >
          <option value='DRE'>DRE</option>
          <option value='Agrupamento'>Agrupamento</option>
          <option value='Plano de Conta'>Plano de Conta</option>
          <option value='Categoria'>Categoria</option>
        </Select>
      </Pane>
      <Pane marginY={20} overflowY='auto'>
        {selectedSet.map((item, index) => (
          <Pane key={item} display='flex' alignItems='center' gap={4}>
            <Pane
              width={8}
              height={8}
              background={contrastedColors2[index]}
            ></Pane>
            <Text textAlign='center' size={300} color='whitesmoke'>
              {item}
            </Text>
          </Pane>
        ))}
      </Pane>
    </Pane>
  );
};

export default PieChartsOptions;
