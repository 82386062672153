import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  IconButton,
  SelectField,
  CrossIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Switch,
  Checkbox,
} from 'evergreen-ui';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { useQueryCategories } from '../../services/api/categoriesService';
import { useQueryBanks } from '../../services/api/banksService';
import { putEntry } from '../../services/api/entriesService';
import {
  getIntentColorByJournal,
  getNameByJournal,
} from '../../helpers/entriesHelper';
import styles from './EntryFormComponent.module.css';

import DividerComponent from '../../custom/DividerComponent';

import { toastNotification } from '../../shared/toastNotification';
import { formatWithLimitedDecimals } from '../../helpers/moneyFormatter';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import { useSelector } from 'react-redux';

function InitialBalanceEditFormComponent({
  isShown,
  setIsShown,
  journalEntry = {},
}) {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  /* Dialog settings  */

  const [checked, setChecked] = useState(false);

  /* API calls */

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    // defaultValues: journalEntry,
  });

  useEffect(() => {
    if (isShown) {
      console.log('journalEntry', journalEntry);
      reset({
        ...journalEntry,
        dateCompetencia: journalEntry.dateCompetencia.substr(0, 10),
        dateVencimento: journalEntry.dateVencimento.substr(0, 10),
      });
      setValue(
        'value',
        journalEntry.value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      );
      setChecked(!journalEntry.isInput);
    }
  }, [isShown]);

  setConfiguration({
    containerWidths: '100%',
    gutterWidth: '12',
  });

  const handleInput = (event) => {
    let inputVal = event.target.value.replace(/\D/g, '');
    if (inputVal === '') {
      setValue('value', '');
      return;
    }
    inputVal = inputVal.padStart(3, '0');
    const wholeNum = inputVal.slice(0, -2);
    const decimal = inputVal.slice(-2);
    const formattedWholeNum = Number(wholeNum).toLocaleString();
    const formattedValue = `${formattedWholeNum},${decimal}`;

    // Convert the formatted value to a number with two decimal places
    const numericValue = Number(
      formattedValue.replace(/\./g, '').replace(',', '.')
    );
    setValue('value', formattedValue);
    console.log(numericValue); // Output the numeric value to the console
    console.log(typeof watch('value')); // Output the numeric value to the console
  };

  const buildFormHeader = () => {
    return (
      <Pane display='flex' flexDirection='column' flex={1} gap={10}>
        <Pane display='flex' justifyContent='space-between' alignItems='center'>
          <Heading textAlign='center' size={600}>
            Editar Saldo Inicial
          </Heading>
          <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
        </Pane>
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane display='flex' justifyContent='center'>
        <Button form='entry-form' type='submit' width='fit-content'>
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (entry) => {
    console.log('onSubmit');
    let statusResponse = false;
    let toastContent = '';
    console.log(entry);

    let entryToUpdate = {
      id: entry.id,
      userUpdateId: !isLoadingVitaUser && vitaUser.data.id,
      userCreateId: entry.userCreateId,
      dateCompetencia: entry.dateCompetencia,
      dateVencimento: entry.dateCompetencia,
      paymentType: entry.paymentType,
      description: entry.description,
      statusPayment: entry.statusPayment,
      isInput: !entry.isNegative,
      discountValue: !!entry.discountValue ? entry.discountValue : 0,
      fineValue: !!entry.fineValue ? entry.fineValue : 0,
      interestValue: !!entry.interestValue ? entry.interestValue : 0,
      value:
        typeof entry.value === typeof 5
          ? entry.value
          : Number(entry.value.replace(/\./g, '').replace(',', '.')),
      categoryId: entry.categoryId,
      bankAccountId: entry.bankAccountId,
      bankAccountIdDestination: entry.bankAccountIdDestination,
    };

    console.log('entryToUpdate', entryToUpdate);
    putEntry(entryToUpdate)
      .then((res) => {
        console.log('res put entry: ', res);
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
        statusResponse = 'success';
        toastContent = 'Edição salva.';
        onClose();
      })
      .catch((err) => {
        console.log('put err', err);
        statusResponse = 'warning';
        toastContent = `Erro. Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
      });
  };

  const onClose = () => {
    reset();
    setIsShown(false);
  };

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={buildFormHeader}
      footer={buildFormFooter}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={true}
      width={300}
      backgroundColor='#f8f8f8'
    >
      <div className={styles.dialogWrapper}>
        <form id='entry-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField className={styles.formArea}>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col xs={2} sm={11} md={11} lg={11}>
                  <SelectField
                    {...register('bankAccountId')}
                    label='Conta'
                    disabled
                    value={journalEntry?.bankAccountId}
                    onChange={(e) => setValue('bankAccountId', e.target.value)}
                  >
                    <option value={undefined}></option>
                    {!isLoadingBanks &&
                      banks.data
                        .sort((a, b) => {
                          const nameA = a.name;
                          const nameB = b.name;

                          if (nameA < nameB) {
                            return -1;
                          }

                          if (nameA > nameB) {
                            return 1;
                          }

                          return 0;
                        })
                        .map((bank) => {
                          return (
                            <option key={bank.id} value={bank.id}>
                              {bank.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col xs={2} sm={11} md={11} lg={11}>
                  <TextInputField
                    {...register('dateCompetencia', {
                      required: true,
                    })}
                    label='Data'
                    type='date'
                    id='dateCompetencia'
                    name='dateCompetencia'
                    placeholder=''
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={2} sm={11} md={11} lg={11}>
                  <TextInputField
                    {...register('value', {
                      required: true,
                      // min: 0,
                      // valueAsNumber: true,
                    })}
                    defaultValue='0,00'
                    label='Saldo Inicial (R$)'
                    type='text'
                    // min='0,00'
                    id='value'
                    name='value'
                    placeholder=''
                    // step='0.01'
                    onChange={handleInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    {...register('isNegative')}
                    label='Saldo negativo'
                    id='isNegative'
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                      setValue('isNegative', !e.target.checked);
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>

      {/* <div> Rodapé </div> */}
    </Dialog>
  );
}

export default InitialBalanceEditFormComponent;
