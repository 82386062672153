import useSWR from 'swr';
import request from './request';

/*
  Axios call methods
*/

export const getStatements = async (companyId) => {
  return request({
    url: `Statement/Company/${companyId}`,
    method: 'GET',
  });
};

export const postStatement = async (statement) => {
  console.log('postStatement: ', statement);
  return request({
    url: `Statement`,
    method: 'POST',
    data: statement,
  });
};

export const putStatement = async (statementId, mode) => {
  console.log('putStatement: ', statementId);
  return request({
    url: `Statement/${statementId}`,
    method: 'PUT',
    data: mode,
  });
};

export const deleteStatement = async (id) => {
  console.log('deleteStatement: ', id);
  return request({
    url: `Statement/${id}`,
    method: 'DELETE',
  });
};

/*
  useQuery - SWR methods
*/

export const useQueryStatements = (companyId, options) => {
  const key = 'statements';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId ? () => getStatements(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};
