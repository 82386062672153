import { Pane } from "evergreen-ui";
import React from "react";

function HamburgerComponent({ setIsShown }) {
  return (
    <Pane
      className="hamburger"
      display="flex"
      flexDirection="column"
      gap={6}
      onClick={() => setIsShown(true)}
      paddingTop={12}
    >
      <span className="line one"></span>
      <span className="line two"></span>
      <span className="line three"></span>
      <span className="line threeCopy"></span>
      <style jsx="true">
        {`
          .hamburger:hover {
            cursor: pointer;
          }

          .hamburger:hover .one {
            transform: rotate(-60deg) translate(4px, 19px);
          }

          .hamburger:hover .two {
            transform: rotate(60deg) translate(2px, 4px)
              scaleY(1.3);
          }

          .hamburger:hover .three {
            transform: rotate(-60deg) translate(19px, 1px)
              scaleX(0.65);
          }

          .hamburger:hover .threeCopy {
            display: flex;
            transform: rotate(-60deg) translate(18.5px, -7.5px)
              scaleX(0.27);
            opacity: 1;
          }

          .line {
            width: 36px;
            height: 3.6px;
            border-radius: 10px;
          }
          .one {
            background-color: #4ebfc2;
            transition: transform 0.5s;
          }
          .two {
            background-color: #3a7bb0;
            transition: transform 0.5s;
          }
          .three {
            background-color: #f3485b;
            transition: transform 0.5s;
          }
          .threeCopy {
            position: relative;
            top: -9.5px;
            background-color: #f3485b;
            opacity: 0;
            transition: transform 0.5s, opacity 0.9s;
            transition-timing-function: ease-out;
          }
        `}
      </style>
    </Pane>
  );
}

export default HamburgerComponent;
