export const formatToMoney = (value, decimals = 2) => {
  const formattedValue = Number(
    Math.round(value + 'e' + decimals) + 'e-' + decimals
  )
    .toFixed(decimals)
    .replace('.', ',');

  const stringValue = formattedValue.split(',');
  const formattedString = `${Number(
    stringValue[0]
  ).toLocaleString('pt-BR')},${stringValue[1]}`;
  return formattedString;
};

export const formatToMoneyWithSymbol = (value) => {
  return `R$ ${formatToMoney(value)}`;
};

export const formatWithLimitedDecimals = (
  value,
  decimals = 2
) => {
  return Number(value).toFixed(decimals);
};
