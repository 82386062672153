import React from 'react';
import {
  Badge,
  Heading,
  Pane,
  Text,
  IconButton,
  CircleArrowLeftIcon,
  CircleArrowRightIcon,
  Card,
} from 'evergreen-ui';
import { Carousel } from 'react-responsive-carousel';
import styles from './BankCarousel.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  useQueryBankBalancesByCompanyId,
  useQueryBanks,
} from '../../services/api/banksService';
import { formatToMoney } from '../../helpers/moneyFormatter';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  useQueryEntries,
  useQueryEntriesBankBalance,
} from '../../services/api/entriesService';
import { convertDate } from '../../helpers/dateConverter';
import { useDispatch, useSelector } from 'react-redux';
import { bankValueActions } from '../../store/bankValue';
import { mutate } from 'swr';

function BankCarousel() {
  const companyId = localStorage.getItem('companyId');

  const [totalConsolidated, setTotalConsolidated] = useState(0);
  const [totalNotConsolidated, setTotalNotConsolidated] = useState(0);

  const selectedIndex = useSelector((state) => state.bankValue.selectedIndex);
  const dispatch = useDispatch();

  /* API calls */

  const {
    data: bankBalances = [],
    isLoading: isLoadingBankBalances,
    isValidating: isValidatingBankBalances,
  } = useQueryBankBalancesByCompanyId(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const totalBanksBalance = () => {
    let inflowCon = 0;
    let inflowNotCon = 0;
    let outflowCon = 0;
    let outflowNotCon = 0;

    bankBalances.data.map((bank) => {
      inflowCon += bank.inflowConsolidated;

      inflowNotCon += bank.inflowNotConsolidated;

      outflowCon += bank.outflowConsolidated;

      outflowNotCon += bank.outflowNotConsolidated;
    });

    setTotalConsolidated(inflowCon - outflowCon);
    setTotalNotConsolidated(inflowNotCon - outflowNotCon);
  };

  const banksListHandler = () => {
    const tempBanksObj = {};
    bankBalances.data
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      })
      .map((bank, index) => {
        tempBanksObj[bank.name] = index + 1;
      });
    dispatch(bankValueActions.setBanksList(tempBanksObj));
  };

  useEffect(() => {
    if (!isLoadingBankBalances) {
      banksListHandler();
      totalBanksBalance();
    }
  }, [isLoadingBankBalances, isValidatingBankBalances]);

  if (isLoadingBankBalances) {
    return <LoadingOverlay />;
  }

  return (
    <Card
      className={styles.mainCard}
      background='white'
      display='flex'
      flexWrap='wrap'
      maxWidth='100%'
      minWidth='30%'
      height='100%'
      border='none'
      boxShadow='none'
      outline='none'
      padding={6}
    >
      <Pane
        className={styles.mainPane}
        display='flex'
        flexDirection='column'
        width='100%'
        flex='auto'
      >
        <Pane display='flex' paddingTop={16} paddingX={24} gap={12}>
          <Heading size={600} color='#767676' fontWeight='400'>
            Contas
          </Heading>
        </Pane>
        <Carousel
          className={styles.carousel}
          centerMode={true}
          centerSlidePercentage={100}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          onChange={(index) =>
            dispatch(bankValueActions.setSelectedIndex(index))
          }
          selectedItem={selectedIndex}
          showArrows={true}
          swipeable={true}
          labels={{ leftArrow: 'Anterior', rightArrow: 'Próximo' }}
          emulateTouch={true}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <IconButton
                position='absolute'
                zIndex={2}
                bottom={0}
                left={0}
                cursor='pointer'
                type='button'
                appearance='minimal'
                icon={CircleArrowLeftIcon}
                onClick={onClickHandler}
                title={label}
                size='large'
              />
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <IconButton
                position='absolute'
                zIndex={2}
                bottom={0}
                right={0}
                cursor='pointer'
                type='button'
                appearance='minimal'
                icon={CircleArrowRightIcon}
                onClick={onClickHandler}
                title={label}
                size='large'
              />
            )
          }
        >
          <Pane
            className={styles.itemsPane}
            display='flex'
            height='fit-content'
            flexWrap='wrap'
            paddingX={24}
            paddingY={15}
          >
            <Pane
              display='flex'
              flexDirection='column'
              textAlign='left'
              flex='auto'
              maxWidth={150}
              textOverflow='ellipsis'
              overflow='hidden'
              whiteSpace='nowrap'
            >
              <Text size={600}>Todas as contas</Text>
            </Pane>
            <Pane
              className={styles.allAccountsPane}
              display='flex'
              justifyContent='start'
              gap={15}
              paddingX={10}
              flexWrap='wrap'
              flex={1}
            >
              <Pane
                display='flex'
                flexDirection='column'
                marginBottom={15}
                flex={1}
              >
                <Badge color='red' width='fit-content'>
                  Pendente
                </Badge>
                <Heading
                  paddingY={10}
                  size={600}
                  textAlign='left'
                  whiteSpace='nowrap'
                >
                  R$ {formatToMoney(totalNotConsolidated)}
                </Heading>
              </Pane>

              <Pane display='flex' flexDirection='column' flex={1}>
                <Badge color='green' width='fit-content'>
                  Consolidado
                </Badge>
                <Heading
                  paddingY={10}
                  size={600}
                  textAlign='left'
                  whiteSpace='nowrap'
                >
                  R$ {formatToMoney(totalConsolidated)}
                </Heading>
              </Pane>
            </Pane>
          </Pane>
          {!isLoadingBankBalances &&
            bankBalances.data
              .sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }

                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              })
              .map((bank) => {
                return (
                  <Pane
                    className={styles.allItemsPane}
                    key={bank.id}
                    display='flex'
                    height='fit-content'
                    flexWrap='wrap'
                    paddingX={24}
                    paddingY={15}
                  >
                    <Pane
                      display='flex'
                      flexDirection='column'
                      textAlign='left'
                      flex='auto'
                      maxWidth={150}
                      textOverflow='ellipsis'
                      overflow='hidden'
                      whiteSpace='nowrap'
                    >
                      <Text size={600}>{bank.name}</Text>
                      <Text size={300}>{bank.description}</Text>
                    </Pane>
                    <Pane
                      className={styles.balancePane}
                      display='flex'
                      justifyContent='start'
                      gap={15}
                      paddingX={10}
                      flexWrap='wrap'
                      flex={1}
                    >
                      <Pane
                        display='flex'
                        flexDirection='column'
                        marginBottom={15}
                        flex={1}
                      >
                        <Badge color='red' width='fit-content'>
                          Pendente
                        </Badge>
                        <Heading
                          paddingY={10}
                          size={600}
                          textAlign='left'
                          whiteSpace='nowrap'
                        >
                          R${' '}
                          {formatToMoney(
                            bank.inflowNotConsolidated.toFixed(2) -
                              bank.outflowNotConsolidated.toFixed(2)
                          )}
                        </Heading>
                      </Pane>

                      <Pane display='flex' flexDirection='column' flex={1}>
                        <Badge color='green' width='fit-content'>
                          Consolidado
                        </Badge>
                        <Heading
                          paddingY={10}
                          size={600}
                          textAlign='left'
                          whiteSpace='nowrap'
                        >
                          R${' '}
                          {formatToMoney(
                            bank.inflowConsolidated.toFixed(2) -
                              bank.outflowConsolidated.toFixed(2)
                          )}
                        </Heading>
                      </Pane>
                    </Pane>
                  </Pane>
                );
              })}
        </Carousel>
      </Pane>
    </Card>
  );
}

export default BankCarousel;
