import { toast } from 'react-toastify';

export const toastNotification = (status, content) => {
  console.log('notify status: ', status);
  console.log('notify status: ', content);

  const showToast = () =>
    toast(content, {
      type: status,
    });

  return showToast();
};
