import { React, useEffect, useState } from 'react';

import {
  Dialog,
  TextInputField,
  FormField,
  Button,
  Pane,
  Menu,
  Popover,
  ChevronDownIcon,
  SelectField,
} from 'evergreen-ui';
import { Container, Row, Col } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { putCategory } from '../../../services/api/categoriesService';
import { useQueryAccountPlans } from '../../../services/api/accountPlansService';
import { toastNotification } from '../../../shared/toastNotification';

import { inputValidation } from '../../../helpers/formHelper';
import { useSelector } from 'react-redux';

function CategoryEditFormComponent({ isShown, setIsShown, category = {} }) {
  const companyId = localStorage.getItem('companyId');
  /* API calls */

  const { data: accountPlans = [], isLoading: isLoadingAccountPlans } =
    useQueryAccountPlans(
      // company.data.id,
      companyId,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const [isDisabled, setIsDisabled] = useState(true);

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({
    defaultValues: category,
  });

  useEffect(() => {
    inputValidation(watch('name'), watch('isInput'), setIsDisabled);
  }, [watch('isInput'), watch('name')]);

  useEffect(() => {
    reset(category);
  }, [isShown]);

  const categoryFormFooter = () => {
    return (
      <Pane>
        <Button
          form='category-form'
          type='submit'
          width='fit-content'
          id='onSubmit'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (category) => {
    console.log('onSubmit');
    let statusResponse = false;
    let toastContent = '';

    let categoryToUpdate = {
      name: category.name,
      id: category.id,
      accountPlanId: category.accountPlanId,
      userUpdateId: category.userUpdateId,
    };

    putCategory(categoryToUpdate)
      .then((res) => {
        console.log('res put category: ', res);
        mutate('categories');
        statusResponse = 'success';
        toastContent = 'Edição salva.';
        onClose();
      })
      .catch((err) => {
        console.log('put err', err);
        statusResponse = 'warning';
        toastContent = `Erro. Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  const onClose = () => {
    console.log('onClose function');
    reset();
    setIsShown(false);
  };

  return (
    <Dialog
      isShown={isShown}
      title='Editar categoria'
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      footer={categoryFormFooter}
      shouldCloseOnEscapePress={!formState.isDirty}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div>
        <form id='category-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <TextInputField
                    {...register('name')}
                    label='Categoria'
                    id='name'
                    name='name'
                    type='text'
                  />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('accountPlanId')}
                    label='Plano de Conta'
                    onChange={(e) => setValue('accountPlanId', e.target.value)}
                  >
                    {!isLoadingAccountPlans &&
                      accountPlans.data
                        .filter((accountPlan) => {
                          return accountPlan.isInput === watch('isInput');
                        })
                        .map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Popover
                    content={
                      <Menu
                        {...register('isInput', {
                          value: null,
                        })}
                      >
                        <Menu.Group>
                          <Menu.Item
                            onSelect={() => {
                              setValue('isInput', true);
                            }}
                          >
                            Entrada
                          </Menu.Item>
                          <Menu.Item
                            onSelect={() => {
                              setValue('isInput', false);
                            }}
                          >
                            Saída
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <Button
                      marginRight={15}
                      id='categoryType'
                      type='button'
                      iconAfter={ChevronDownIcon}
                      disabled={true}
                    >
                      {watch('isInput') ? 'Entrada' : 'Saída'}
                    </Button>
                  </Popover>
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default CategoryEditFormComponent;
