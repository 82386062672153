import {
  Pill,
  Pane,
  Heading,
  Tooltip,
  Position,
  Text,
  RadioGroup,
  Card,
  BanCircleIcon,
  TickCircleIcon,
} from 'evergreen-ui';
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useQueryEntriesCalendar } from '../../services/api/entriesService';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { calendarValuesActions } from '../../store/calendarValues';
import { firstDay, lastDay } from '../../helpers/dateConverter';
import { mutate } from 'swr';
import { useEffect } from 'react';

function CalendarPage() {
  const dispatch = useDispatch();
  const [value, onChange] = useState(new Date());
  const [options] = useState([
    { label: 'Data de Competência', value: 'dateCompetencia' },
    { label: 'Data de Vencimento', value: 'dateVencimento' },
  ]);

  const { startCalendarDate, endCalendarDate, filterType } = useSelector(
    (state) => state.calendarValues
  );

  const companyId = localStorage.getItem('companyId');

  const filterHandler = (item) => {
    if (filterType === 'dateCompetencia') {
      // console.log(new Date(item.dateCompetencia).toLocaleDateString());
      return new Date(item.dateCompetencia).toLocaleDateString();
    } else {
      // console.log(new Date(item.dateVencimento).toLocaleDateString());
      return new Date(item.dateVencimento).toLocaleDateString();
    }
  };

  const {
    data: entriesCalendar,
    isLoading: isLoadingEntriesCalendar,
    isValidating: isValidatingEntriesCalendar,
  } = useQueryEntriesCalendar(
    // company.data.id,
    companyId,
    startCalendarDate,
    endCalendarDate,
    filterType,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleCalendar = (view, date) => {
    let inputCounter = 0;
    let outputCounter = 0;
    let transferCounter = 0;
    let initialBalanceCounter = 0;
    let inputPaidValue = 0;
    let outputPaidValue = 0;
    let transferPaidValue = 0;
    let initialBalancePaidValue = 0;
    let inputPendingValue = 0;
    let outputPendingValue = 0;
    let transferPendingValue = 0;
    let inputExpiredValue = 0;
    let outputExpiredValue = 0;
    const inputArray = [];
    const outputArray = [];
    const transferArray = [];
    const initialBalanceArray = [];
    if (view === 'month') {
      entriesCalendar.data.map((item) => {
        if (item.journalEntryType === 1 && item.entryType === 'Entrada') {
          inputArray.push({
            data: filterHandler(item),
            valor: item.value,
            statusString: item.statusString,
          });
        } else if (item.journalEntryType === 1 && item.entryType === 'Saída') {
          outputArray.push({
            data: filterHandler(item),
            valor: item.value,
            statusString: item.statusString,
          });
        } else if (item.journalEntryType === 5) {
          transferArray.push({
            data: filterHandler(item),
            valor: item.value,
            statusPayment: item.statusPayment,
          });
        } else {
          initialBalanceArray.push({
            data: filterHandler(item),
            valor: item.value,
            statusPayment: item.statusPayment,
          });
        }
      });

      inputArray.map((i) => {
        if (date.toLocaleDateString() === i.data) {
          inputCounter++;
          if (i.statusString === 'Pago') {
            inputPaidValue += i.valor;
          } else if (i.statusString === 'Pendente') {
            inputPendingValue += i.valor;
          } else {
            inputExpiredValue += i.valor;
          }
        }
      });

      outputArray.map((i) => {
        if (date.toLocaleDateString() === i.data) {
          outputCounter++;
          if (i.statusString === 'Pago') {
            outputPaidValue += i.valor;
          } else if (i.statusString === 'Pendente') {
            outputPendingValue += i.valor;
          } else {
            outputExpiredValue += i.valor;
          }
        }
      });

      transferArray.map((i) => {
        if (date.toLocaleDateString() === i.data) {
          transferCounter++;
          if (i.statusPayment) {
            transferPaidValue += i.valor;
          } else {
            transferPendingValue += i.valor;
          }
        }
      });
      initialBalanceArray.map((i) => {
        if (date.toLocaleDateString() === i.data) {
          initialBalanceCounter++;
          if (i.statusPayment) {
            initialBalancePaidValue += i.valor;
          }
        }
      });
    }

    return (
      <Pane
        display='flex'
        flexDirection='column'
        alignItems='center'
        color='#999'
        gap={3}
      >
        {inputCounter > 0 && (
          <Tooltip
            position={Position.RIGHT}
            content={
              <Pane display='flex' flexDirection='column' gap={10}>
                <Heading textAlign='center' justifyContent='center'>
                  Entrada
                </Heading>
                <Pane
                  display='flex'
                  alignItems='center'
                  textAlign='center'
                  gap={5}
                >
                  <TickCircleIcon color='success' />
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(inputPaidValue)}
                  </Text>
                </Pane>
                <Pane
                  display='flex'
                  alignItems='center'
                  textAlign='center'
                  gap={5}
                >
                  <BanCircleIcon color='warning' />
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(inputPendingValue)}
                  </Text>
                </Pane>
                <Pane
                  display='flex'
                  alignItems='center'
                  textAlign='center'
                  gap={5}
                >
                  <BanCircleIcon color='danger' />
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(inputExpiredValue)}
                  </Text>
                </Pane>
              </Pane>
            }
          >
            <Pill className='pill' color='blue' marginRight={5}>
              {inputCounter}
            </Pill>
          </Tooltip>
        )}
        {outputCounter > 0 && (
          <Tooltip
            position={Position.RIGHT}
            content={
              <Pane display='flex' flexDirection='column' gap={10}>
                <Heading textAlign='center'>Saída</Heading>
                <Pane
                  display='flex'
                  alignItems='center'
                  textAlign='center'
                  gap={5}
                >
                  <TickCircleIcon color='success' />
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(outputPaidValue)}
                  </Text>
                </Pane>
                <Pane
                  display='flex'
                  alignItems='center'
                  textAlign='center'
                  gap={5}
                >
                  <BanCircleIcon color='warning' />{' '}
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(outputPendingValue)}
                  </Text>
                </Pane>
                <Pane
                  display='flex'
                  alignItems='center'
                  textAlign='center'
                  gap={5}
                >
                  <BanCircleIcon color='danger' />{' '}
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(outputExpiredValue)}
                  </Text>
                </Pane>
              </Pane>
            }
          >
            <Pill className='pill' color='red' marginRight={5}>
              {outputCounter}
            </Pill>
          </Tooltip>
        )}
        {transferCounter > 0 && (
          <Tooltip
            position={Position.RIGHT}
            content={
              <Pane display='flex' flexDirection='column' gap={10}>
                <Heading textAlign='center'>Transferência</Heading>
                <Pane
                  display='flex'
                  alignItems='center'
                  textAlign='center'
                  gap={5}
                >
                  <TickCircleIcon color='success' />
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(transferPaidValue / 2)}
                  </Text>
                </Pane>
              </Pane>
            }
          >
            <Pill className='pill' color='green' marginRight={5}>
              {transferCounter / 2}
            </Pill>
          </Tooltip>
        )}
        {initialBalanceCounter > 0 && (
          <Tooltip
            position={Position.RIGHT}
            content={
              <Pane display='flex' flexDirection='column' gap={10}>
                <Heading textAlign='center'>Saldo Inicial</Heading>
                <Text>
                  Valor:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(initialBalancePaidValue)}
                </Text>
              </Pane>
            }
          >
            <Pill className='pill' color='#1D9D33' marginRight={5}>
              {initialBalanceCounter}
            </Pill>
          </Tooltip>
        )}
      </Pane>
    );
  };

  useEffect(() => {
    mutate('entriesCalendar');
  }, [endCalendarDate, filterType]);

  if (isValidatingEntriesCalendar || isLoadingEntriesCalendar) {
    <LoadingOverlay />;
  }

  return (
    <Pane
      width='100%'
      height='100%'
      display='flex'
      flexDirection='column'
      overflow='scroll'
      className='CalendarPage'
      backgroundColor='#d9e1ee'
      padding={20}
    >
      <Pane
        display='flex'
        justifyContent='space-between'
        className='calendarPane'
      >
        <Card>
          <Pane
            display='flex'
            flexDirection='column'
            width='190px'
            height='150px'
            background='white'
            border='1px solid #d3cdcd'
            elevation={3}
            marginBottom={12}
            padding={12}
          >
            <Heading size={400}>Filtros:</Heading>
            <RadioGroup
              size={16}
              value={filterType}
              options={options}
              onChange={(event) =>
                dispatch(
                  calendarValuesActions.setFilterType(event.target.value)
                )
              }
            />
          </Pane>
        </Card>
        <Card>
          <Pane
            display='flex'
            flexDirection='column'
            width='190px'
            height='150px'
            background='white'
            border='1px solid #d3cdcd'
            elevation={3}
            gap={8}
            marginBottom={12}
            padding={12}
          >
            <Heading size={400}>Legenda:</Heading>
            <Pane>
              <Pill className='pill' color='blue'></Pill>
              <Text> - Entrada</Text>
            </Pane>
            <Pane>
              <Pill className='pill' color='red'></Pill>
              <Text> - Saída</Text>
            </Pane>
            <Pane>
              <Pill className='pill' color='green'></Pill>
              <Text> - Transferência</Text>
            </Pane>
            <Pane>
              <Pill className='pill' color='#1D9D33'></Pill>
              <Text> - Saldo Inicial</Text>
            </Pane>
          </Pane>
        </Card>
      </Pane>
      {isValidatingEntriesCalendar && <LoadingOverlay />}
      {!isValidatingEntriesCalendar && !isLoadingEntriesCalendar && (
        <Calendar
          className='react-calendar'
          // onChange={onchange}
          tileContent={({ view, date }) => handleCalendar(view, date)}
          // onClickDay={(value) => console.log('calendar Click: ', value)}
          // defaultView='year'
          // onClickMonth={(value) =>
          //   console.log('month click: ', new Date(value).getMonth())
          // }
          // onDrillUp={({ activeStartDate, value, view }) => console.log(value)}
          // onDrillDown={({ activeStartDate, value, view }) => console.log(value)}
          // formatLongDate={(format, date) => console.log(format, date)}
          // view='century'
          locale='pt-BR'
          activeStartDate={new Date(endCalendarDate)}
          onActiveStartDateChange={({
            action,
            activeStartDate,
            value,
            view,
          }) => {
            if (
              action === 'prev' ||
              action === 'next' ||
              action === 'next2' ||
              action === 'prev2'
            ) {
              dispatch(
                calendarValuesActions.setCalendarStartDate(
                  firstDay(
                    activeStartDate.getMonth(),
                    activeStartDate.getFullYear()
                  )
                )
              );
              dispatch(
                calendarValuesActions.setCalendarEndDate(
                  lastDay(
                    activeStartDate.getMonth(),
                    activeStartDate.getFullYear()
                  )
                )
              );
              // console.log('Changed view to: ', activeStartDate, action);
            }
            if (action === 'drillDown' && view === 'month') {
              dispatch(
                calendarValuesActions.setCalendarStartDate(
                  firstDay(
                    activeStartDate.getMonth(),
                    activeStartDate.getFullYear()
                  )
                )
              );
              dispatch(
                calendarValuesActions.setCalendarEndDate(
                  lastDay(
                    activeStartDate.getMonth(),
                    activeStartDate.getFullYear()
                  )
                )
              );
            }
          }}
        />
      )}
      <style jsx='true' scoped={true}>
        {`
          .pill:hover {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }

          .react-calendar {
            height: fit-content;
            width: 100%;
            max-width: 100%;
            background-color: #f8f8f8;
            color: #222;
            border: none;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
            font-family: 'Ubuntu';
            outline: 0.6px solid #eff3fb !important;
            line-height: 4.5em;
          }
          .react-calendar__tile {
            text-align: left;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            max-height: 140px;
            min-height: 90px;
            padding: 0px 6px;
            background: none;
            line-height: 4.5em;
            outline: 0.6px solid #eff3fb !important;
          }

          button > abbr {
            line-height: 2.2em;
          }

          .react-calendar__navigation button {
            color: #464646;
            min-width: 44px;
            background: none;
            font-size: 15px;
          }

          .react-calendar__navigation {
            margin-top: 15px;
          }

          .react-calendar__navigation__arrow {
            transition: 0.2s ease-in-out;
            box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
              -6px -6px 10px rgba(255, 255, 255, 0.5),
              6px 6px 8px rgba(255, 255, 255, 0.075),
              6px 6px 10px rgba(0, 0, 0, 0.1);
            border-radius: 9px;
            margin: 0 9px !important;
          }

          .react-calendar__navigation__arrow:hover {
            background: white !important;
            box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
              -2px -2px 4px rgba(255, 255, 255, 0.4),
              2px 2px 2px rgba(255, 255, 255, 0.05),
              2px 2px 3px rgba(0, 0, 0, 0.1);
          }

          .react-calendar__navigation__label {
            margin: 0 12px !important;
            border-radius: 5px;
          }
          .react-calendar__navigation__label:hover {
            background: #f1f1f1 !important;
            margin: 0 12px !important;
            border-radius: 5px;
          }

          .react-calendar__navigation__label--active {
            background: #f1f1f1 !important;
            margin: 0 12px !important;
            border-radius: 5px;
          }
          .react-calendar__navigation__label:disabled {
            background: #f1f1f1 !important;
            margin: 0 12px !important;
            border-radius: 5px;
          }

          /* .react-calendar__navigation button:enabled:hover,
          .react-calendar__navigation button:enabled:focus {
            background-color: #fff;
          }
          .react-calendar__navigation button[disabled] {
              background-color: #f1f1f1;
           } 

          .react-calendar__tile--range {
            background: #f8f8fa;
            color: #6f48eb;
          }
          .react-calendar__tile--rangeStart {
            background: #6f48eb;
            color: white;
          }
          .react-calendar__tile--rangeEnd {
            background: #6f48eb;
            color: white;
          }

          .react-calendar__tile > abbr {
            top: -10px;
          }

          */
          abbr[title] {
            text-decoration: none;
          }
          /* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
          .react-calendar__month-view__days__day--weekend {
            background: #f5f5f5;
          }

          .react-calendar__tile:enabled:hover,
          .react-calendar__tile:enabled:focus {
            background: #ededed;
          }
          .react-calendar__tile--now,
          .react-calendar__tile--now:enabled:hover,
          .react-calendar__tile--now:enabled:focus {
            background: #324058;
            color: white;
          }

          .react-calendar__tile--now:enabled:hover {
            background: #32405833 !important;
            color: #565656;
          }

          .react-calendar__tile--hasActive:enabled:hover,
          .react-calendar__tile--hasActive:enabled:focus {
            background: #f8f8fa;
          }
          .react-calendar__tile--active {
            background: #96c68f;
            font-weight: bold;
            color: #888;
          }
          .react-calendar__tile--active:enabled:hover,
          .react-calendar__tile--active:enabled:focus {
            background: #96c68f;
            color: #888;
          }
          .react-calendar--selectRange .react-calendar__tile--hover {
            background-color: #f8f8fa;
          }
        `}
      </style>
    </Pane>
  );
}

export default CalendarPage;
