export default function HeaderSignComponent() {
  return (
    <div className="header">
      <style jsx="true">
        {`
          .header {
            min-height: 100px;
            width: 100%;
            border-bottom: 2px solid lightgray;
          }
        `}
      </style>
    </div>
  );
}
