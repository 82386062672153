import { React, useState } from 'react';

import {
  Card,
  ChevronLeftIcon,
  ChevronRightIcon,
  Heading,
  IconButton,
  Pane,
  RadioGroup,
} from 'evergreen-ui';

import { useDispatch, useSelector } from 'react-redux';
import { dashboardValuesActions } from '../../store/dashboardValues';
import { firstDay, lastDay } from '../../helpers/dateConverter';

function DashboardFilterBar() {
  const dispatch = useDispatch();
  const { filterType, dashboardYear, entryStatus } = useSelector(
    (state) => state.dashboardValues
  );

  const nextYear = () => {
    let tempYear = dashboardYear;
    tempYear++;
    dispatch(
      dashboardValuesActions.setDashboardStartDate(firstDay(0, tempYear))
    );
    dispatch(dashboardValuesActions.setDashboardEndDate(lastDay(11, tempYear)));
    dispatch(dashboardValuesActions.increaseDashboardYear());
  };
  const previousYear = () => {
    let tempYear = dashboardYear;
    tempYear--;
    dispatch(
      dashboardValuesActions.setDashboardStartDate(firstDay(0, tempYear))
    );
    dispatch(dashboardValuesActions.setDashboardEndDate(lastDay(11, tempYear)));
    dispatch(dashboardValuesActions.decreaseDashboardYear());
  };

  return (
    <Pane>
      <Card
        className='cardApiFilter'
        minHeight={50}
        display='flex'
        position='relative'
        alignItems='center'
        gap={100}
        padding={6}
        flexDirection='row'
        flexWrap='wrap'
        width='100%'
        backgroundColor='#f4f5f9'
        justifyContent='center'
        margin='auto'
      >
        <Pane
          display='flex'
          alignItems='center'
          justifySelf='center'
          justifyContent='center'
          width='fit-content'
        >
          <IconButton
            icon={ChevronLeftIcon}
            appearance='minimal'
            onClick={previousYear}
            size='large'
          />

          <Heading>{dashboardYear}</Heading>

          <IconButton
            icon={ChevronRightIcon}
            appearance='minimal'
            onClick={nextYear}
            size='large'
          />
        </Pane>

        <Pane paddingLeft={16} display='flex' flexDirection='row'>
          <RadioGroup
            value={entryStatus}
            size={12}
            options={[
              {
                label: 'Todos',
                value: 'Todos',
              },
              {
                label: 'Pagos',
                value: 'Pago',
              },
              {
                label: 'Pendentes',
                value: 'Pendente',
              },
            ]}
            onChange={(e) => {
              dispatch(dashboardValuesActions.setEntryStatus(e.target.value));
              // console.log(e.target.value);
              // mutate('entriesFilteredByDate');
            }}
          />
        </Pane>
        <Pane
          display='flex'
          alignItems='center'
          flexWrap='wrap'
          justifyContent='center'
        >
          <Pane paddingLeft={16}>
            <RadioGroup
              value={filterType}
              options={[
                {
                  label: 'Competência',
                  value: 'dateCompetencia',
                },
                {
                  label: 'Vencimento',
                  value: 'dateVencimento',
                },
              ]}
              onChange={(e) => {
                dispatch(dashboardValuesActions.setFilterType(e.target.value));
                // mutate('entriesFilteredByDate');
              }}
            />
          </Pane>
        </Pane>
      </Card>
    </Pane>
  );
}

export default DashboardFilterBar;
