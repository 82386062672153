import useSWR from 'swr';
import request from './request';

/*
  Axios call methods
*/

export const getCategories = async (companyId) => {
  return request({
    url: `Categories/Company/${companyId}`,
    method: 'GET',
  });
};

export const getCategory = async (id) => {
  return request({
    url: `Categories/${id}`,
    method: 'GET',
  });
};

export const putCategory = async (category) => {
  console.log('putCategory: ', category);
  return request({
    url: `Categories/${category.id}`,
    method: 'PUT',
    data: category,
  });
};

export const postCategory = async (category) => {
  console.log('postCategory: ', category);
  return request({
    url: `Categories/`,
    method: 'POST',
    data: category,
  });
};

export const deleteCategory = async (categoryId) => {
  console.log('deleteCategory: ', categoryId);
  return request({
    url: `Categories/`,
    method: 'DELETE',
    params: { id: categoryId },
  });
};

/*
  useQuery - SWR methods
*/

export const useQueryCategories = (companyId, options) => {
  const key = 'categories';
  const { data, error } = useSWR(
    () => key,
    companyId ? () => getCategories(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryCategory = (id, options) => {
  const key = 'category';
  const { data, error } = useSWR(
    () => key,
    id ? () => getCategory(id) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
