import React from 'react';

import {
  CaretDownIcon,
  CaretUpIcon,
  DoubleCaretVerticalIcon,
  Heading,
  IconButton,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  Table,
  Text,
  TrashIcon,
} from 'evergreen-ui';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReconciliatedStatementEntry from './ReconciliatedStatementEntry';
import { useSelector } from 'react-redux';
import {
  parseStatementDate,
  parseStatementDateToForm,
} from '../../helpers/ofxHelper';
import { toastNotification } from '../../shared/toastNotification';
import { mutate } from 'swr';
import { putManyEntry } from '../../services/api/entriesService';
import { putStatement } from '../../services/api/statementService';

const ReconciliatedStatementAccordion = ({
  entriesReconciliation,
  currentStatement,
  statementReconciliatedDateSorting,
  statementReconciliatedValueSorting,
  handleStatementReconciliatedDateSorting,
  handleStatementReconciliatedValueSorting,
}) => {
  const { selectedFilter } = useSelector((state) => state.reconciliationValues);

  const handleDisconciliation = (item) => {
    let statusResponse = false;
    let toastContent = '';

    const entriesToDisconciliate = entriesReconciliation.data
      .filter((entry) => entry.fitId === item.FITID)
      .map((entry) => {
        return { ...entry, fitId: null };
      });
    putManyEntry(entriesToDisconciliate)
      .then((response) => {
        statusResponse = 'success';
        toastContent = 'Desconciliação realizada com sucesso.';
      })
      .catch((error) => {
        statusResponse = 'warning';
        console.log('post err', error);
        toastContent = `Algo deu errado, tente novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('entriesReconciliation');
      });

    console.log(entriesToDisconciliate);
  };

  return (
    <Pane width='30%' marginX={5} marginBottom={0} paddingLeft={5}>
      <Heading marginY={5} textAlign='center'>
        Transações Conciliadas
      </Heading>
      <Pane textAlign='center'>
        <Table>
          <Table.Head height={40}>
            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleStatementReconciliatedDateSorting}
                cursor='pointer'
              >
                Data
                {statementReconciliatedDateSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : statementReconciliatedDateSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Descrição</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleStatementReconciliatedValueSorting}
                cursor='pointer'
              >
                Valor
                {statementReconciliatedValueSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : statementReconciliatedValueSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Pane overflowY='scroll' maxHeight={130}>
              {entriesReconciliation &&
                currentStatement.statementObject &&
                currentStatement.statementObject.data.transactions
                  .filter((transaction) => {
                    if (selectedFilter === 'todos') {
                      return transaction;
                    } else if (selectedFilter === 'entrada') {
                      return transaction.TRNAMT > 0;
                    } else if (selectedFilter === 'saida') {
                      return transaction.TRNAMT < 0;
                    }
                  })
                  .filter((statement) =>
                    entriesReconciliation.data.some(
                      (entry) =>
                        entry.fitId === statement.FITID &&
                        entry.bankAccountId === currentStatement.bankId
                    )
                  )
                  .sort((a, b) => {
                    if (statementReconciliatedDateSorting === 'asc') {
                      return (
                        new Date(parseStatementDateToForm(a.DTPOSTED)) -
                        new Date(parseStatementDateToForm(b.DTPOSTED))
                      );
                    } else if (statementReconciliatedDateSorting === 'desc') {
                      return (
                        new Date(parseStatementDateToForm(b.DTPOSTED)) -
                        new Date(parseStatementDateToForm(a.DTPOSTED))
                      );
                    } else {
                      return 0;
                    }
                  })
                  .sort((a, b) => {
                    if (statementReconciliatedValueSorting === 'asc') {
                      return a.TRNAMT - b.TRNAMT;
                    } else if (statementReconciliatedValueSorting === 'desc') {
                      return b.TRNAMT - a.TRNAMT;
                    } else {
                      return 0;
                    }
                  })
                  .map((item) => (
                    <Table.Row key={item.id} height={45}>
                      <Table.TextCell>
                        <Text>{parseStatementDate(item.DTPOSTED)}</Text>
                      </Table.TextCell>
                      <Table.TextCell>
                        <Text>{item.MEMO}</Text>
                      </Table.TextCell>
                      <Table.TextCell>
                        <Text color={item.TRNAMT > 0 ? 'blue' : 'red'}>
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(Math.abs(item.TRNAMT))}
                        </Text>
                      </Table.TextCell>
                      <Table.TextCell>
                        <Pane onClick={(e) => e.stopPropagation()}>
                          <Popover
                            position={Position.BOTTOM_LEFT}
                            animationDuration={1}
                            content={
                              <Menu>
                                <Menu.Group>
                                  <Menu.Item
                                    icon={TrashIcon}
                                    intent='danger'
                                    onClick={() => handleDisconciliation(item)}
                                  >
                                    Desconciliar
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <IconButton
                              appearance='minimal'
                              intent='info'
                              icon={MoreIcon}
                            />
                          </Popover>
                        </Pane>
                      </Table.TextCell>
                    </Table.Row>
                  ))}
            </Pane>
          </Table.Body>
        </Table>
      </Pane>
      {/* </AccordionDetails>
      </Accordion> */}
    </Pane>
  );
};

export default ReconciliatedStatementAccordion;
