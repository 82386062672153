import { Button, Heading, Pane, Select } from 'evergreen-ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reconciliationValuesActions } from '../../store/reconciliationValues';

const Filters = () => {
  const selectedFilter = useSelector(
    (state) => state.reconciliationValues.selectedFilter
  );
  const dispatch = useDispatch();

  const handleSelectedFilter = (filter) => {
    dispatch(reconciliationValuesActions.setSelectedFilter(filter));
    dispatch(reconciliationValuesActions.setStatementList([]));
    dispatch(reconciliationValuesActions.setEntryList([]));
  };
  return (
    <Pane>
      <Button
        appearance='primary'
        margin={4}
        onClick={() => handleSelectedFilter('entrada')}
        size={selectedFilter === 'entrada' ? 'medium' : 'small'}
        style={{
          backgroundColor: '#D6E0FF',
          color: '#2952CC',
          borderColor: '#6c87d8',
        }}
      >
        ENTRADA
      </Button>
      <Button
        appearance='primary'
        margin={4}
        intent='danger'
        style={{
          backgroundColor: '#F9DADA',
          color: '#7D2828',
          borderColor: '#A96767',
        }}
        onClick={() => handleSelectedFilter('saida')}
        size={selectedFilter === 'saida' ? 'medium' : 'small'}
      >
        SAÍDA
      </Button>
    </Pane>
  );
};

export default Filters;
