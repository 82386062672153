import { createSlice } from '@reduxjs/toolkit';
import { firstDay, lastDay } from '../helpers/dateConverter';

const initialCalendarValuesState = {
  filterType: 'dateVencimento',
  entryStatus: 'Todos',
  startCalendarDate: firstDay(new Date().getMonth(), new Date().getFullYear()),
  endCalendarDate: lastDay(new Date().getMonth(), new Date().getFullYear()),
  calendarYear: new Date().getFullYear(),
};

const calendarValuesSlice = createSlice({
  name: 'calendarValues',
  initialState: initialCalendarValuesState,
  reducers: {
    setCalendarStartDate(state, action) {
      state.startCalendarDate = action.payload;
    },
    setCalendarEndDate(state, action) {
      state.endCalendarDate = action.payload;
    },
    resetFilter(state) {
      state.startDate = firstDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.endDate = lastDay(new Date().getMonth(), new Date().getFullYear());
      state.startDateTemp = firstDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.endDateTemp = lastDay(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      state.filterYear = new Date().getFullYear();
      state.filterType = 'dateVencimento';
    },
    setFilterType(state, action) {
      state.filterType = action.payload;
    },
    setEntryStatus(state, action) {
      state.entryStatus = action.payload;
    },
    increaseCalendarYear(state, action) {
      state.calendarYear = state.calendarYear + 1;
    },
    decreaseCalendarYear(state, action) {
      state.calendarYear = state.calendarYear - 1;
    },
  },
});

export const calendarValuesActions = calendarValuesSlice.actions;

export default calendarValuesSlice.reducer;
