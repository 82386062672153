import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  IconButton,
  SelectField,
  CrossIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Switch,
} from 'evergreen-ui';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { useQueryBanks } from '../../services/api/banksService';
import { postEntry, useQueryEntries } from '../../services/api/entriesService';
import {
  getIntentColorByJournal,
  getNameByJournal,
} from '../../helpers/entriesHelper';
import styles from './EntryFormStatementComponent.module.css';

import DividerComponent from '../../custom/DividerComponent';

import { toastNotification } from '../../shared/toastNotification';
import { formatWithLimitedDecimals } from '../../helpers/moneyFormatter';
import { inputTransferValidation } from '../../helpers/formHelper';
import { convertDate } from '../../helpers/dateConverter';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { putStatement } from '../../services/api/statementService';
import { useDispatch, useSelector } from 'react-redux';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';

function TransferFormStatementComponent({
  isShown,
  setIsShown,
  journalEntry = {},
}) {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');
  const [isDisabled, setIsDisabled] = useState(true);

  /* API calls */

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { currentStatement, entryList, statementList } = useSelector(
    (state) => state.reconciliationValues
  );

  const dispatch = useDispatch();

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      statusPayment: true,
    },
  });

  setConfiguration({
    containerWidths: '100%',
    gutterWidth: '12',
  });

  useEffect(() => {
    if (isShown) {
      console.log('journalEntry', journalEntry);
      const entry = {
        ...journalEntry,
        dateCompetencia: journalEntry.dateCompetencia,
        dateVencimento: journalEntry.dateVencimento,
      };
      reset(entry);
      setValue(
        'value',
        journalEntry.value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      );
      // setValue(
      //   'fineValue',
      //   journalEntry.fineValue.toLocaleString('pt-BR', {
      //     minimumFractionDigits: 2,
      //   })
      // );
      // setValue(
      //   'discountValue',
      //   journalEntry.discountValue.toLocaleString('pt-BR', {
      //     minimumFractionDigits: 2,
      //   })
      // );
      // setValue(
      //   'interestValue',
      //   journalEntry.interestValue.toLocaleString('pt-BR', {
      //     minimumFractionDigits: 2,
      //   })
      // );
      // setValue(
      //   'interestValuePercent',
      //   formatWithLimitedDecimals(
      //     (Number(
      //       getValues('interestValue').replace(/\./g, '').replace(',', '.')
      //     ) /
      //       Number(getValues('value').replace(/\./g, '').replace(',', '.'))) *
      //       100
      //   )
      // );
    }
  }, [isShown]);

  useEffect(() => {
    inputTransferValidation(
      watch('bankAccountId'),
      watch('bankAccountIdDestination'),
      watch('dateCompetencia'),
      watch('value'),
      setIsDisabled
    );
  }, [
    watch('bankAccountId'),
    watch('bankAccountIdDestination'),
    watch('dateCompetencia'),
    watch('value'),
  ]);

  const buildFormHeader = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='space-between'>
        <Button
          appearance='primary'
          intent={getIntentColorByJournal(journalEntry)}
          height={48}
          marginRight={16}
        >
          {getNameByJournal(journalEntry)}
        </Button>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane>
        <Button
          form='transfer-form'
          type='submit'
          width='fit-content'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (entry) => {
    console.log('onSubmit', entry);
    let statusResponse = false;
    let toastContent = '';

    let entryToCreate = {
      companyId: companyId,
      journalEntryType: 5,
      dateCompetencia: entry.dateCompetencia,
      dateVencimento: entry.dateCompetencia,
      userUpdateId: !isLoadingVitaUser && vitaUser.data.id,
      userCreateId: !isLoadingVitaUser && vitaUser.data.id,
      description: entry.description,
      statusPayment: entry.statusPayment,
      fitId: journalEntry.fitId,
      isInput: journalEntry.isInput,
      paymentType: 1,
      value: Number(entry.value.replace(/\./g, '').replace(',', '.')),
      bankAccountId: entry.bankAccountId,
      bankAccountIdDestination: entry.bankAccountIdDestination,
    };

    postEntry(entryToCreate)
      .then((res) => {
        putStatement(currentStatement.id, true)
          .then((res) => {
            console.log('putStatement', res);
            statusResponse = 'success';
            toastContent = `${res.data.totalReconciliated} de ${res.data.totalStatements} lançamentos conciliados.`;
          })
          .catch((err) => {
            statusResponse = 'warning';
            console.log('post err', err);
            toastContent = `O total conciliado não foi atualizado corretamente.`;
          })
          .finally(() => {
            toastNotification(statusResponse, toastContent);
            mutate('statements');
          });
        console.log('res post entry: ', res);
        mutate('entriesFilteredByDate');
        mutate('entriesReconciliation');
        mutate('bankBalances');
        statusResponse = 'success';
        toastContent = 'Lançamento salvo com sucesso.';
        onClose();
        dispatch(reconciliationValuesActions.setEntryList([]));
        dispatch(reconciliationValuesActions.setStatementList([]));
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
      });
  };

  const onClose = () => {
    reset();
    setIsShown(false);
  };

  const handleInput = (event) => {
    let inputVal = event.target.value.replace(/\D/g, '');
    if (inputVal === '') {
      setValue('value', '');
      return;
    }
    inputVal = inputVal.padStart(3, '0');
    const wholeNum = inputVal.slice(0, -2);
    const decimal = inputVal.slice(-2);
    const formattedWholeNum = Number(wholeNum).toLocaleString();
    const formattedValue = `${formattedWholeNum},${decimal}`;

    // Convert the formatted value to a number with two decimal places
    const numericValue = Number(
      formattedValue.replace(/\./g, '').replace(',', '.')
    );
    setValue('value', formattedValue);
    console.log(numericValue); // Output the numeric value to the console
    console.log(watch('value')); // Output the numeric value to the console
  };

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={() => buildFormHeader()}
      footer={buildFormFooter}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div className={styles.dialogWrapper}>
        <form id='transfer-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField className={styles.formArea}>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('bankAccountId')}
                    label='Conta de Saída'
                    disabled={!journalEntry?.isInput}
                    defaultValue={
                      journalEntry?.isInput
                        ? undefined
                        : journalEntry?.bankAccountId
                    }
                    onChange={(e) => setValue('bankAccountId', e.target.value)}
                  >
                    <option value={undefined}></option>
                    {!isLoadingBanks &&
                      banks.data
                        .filter(
                          (bank) =>
                            bank.id !== journalEntry?.bankAccountIdDestination
                        )
                        .map((bank) => {
                          return (
                            <option key={bank.id} value={bank.id}>
                              {bank.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('bankAccountIdDestination')}
                    label='Conta de Entrada'
                    disabled={journalEntry?.isInput}
                    defaultValue={
                      journalEntry?.isInput
                        ? journalEntry.bankAccountId
                        : undefined
                    }
                    onChange={(e) =>
                      setValue('bankAccountIdDestination', e.target.value)
                    }
                  >
                    <option value={undefined}></option>

                    {!isLoadingBanks &&
                      banks.data
                        .filter(
                          (bank) => bank.id !== journalEntry?.bankAccountId
                        )
                        .map((bank) => {
                          return (
                            <option key={bank.id} value={bank.id}>
                              {bank.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('dateCompetencia')}
                    label='Data da Transferência'
                    id='dateCompetencia'
                    name='dateCompetencia'
                    type='date'
                    placeholder='--/--/--'
                    disabled
                    onChange={(e) => {
                      setValue('dateCompetencia', e.target.value);
                    }}
                    defaultValue={journalEntry?.dateCompetencia}
                  />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('description')}
                    label='Descrição'
                    id='description'
                    name='description'
                    placeholder=''
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={10} sm={11} md={11} lg={12}>
                  <TextInputField
                    {...register('value', {
                      required: true,
                      // min: 0,
                      // valueAsNumber: true,
                    })}
                    defaultValue={journalEntry?.value}
                    disabled
                    label='Valor (R$)'
                    type='text'
                    // min='0,00'
                    id='value'
                    name='value'
                    placeholder=''
                    // step='0.01'
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={2} sm={1} md={1} lg={1}>
                  <Heading size={400} marginBottom={16}>
                    Pago
                  </Heading>
                  <Switch {...register('statusPayment')} checked={true} />
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default TransferFormStatementComponent;
