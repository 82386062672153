import { Pane, Tab, Tablist } from 'evergreen-ui';
import React from 'react';
import AccountPlansComponent from './account-plans/AccountPlansComponent';
import CategoriesComponent from './categories/CategoriesComponent';
import DreGroupingComponent from './dre-grouping/DreGroupingComponent';

function CategoriesFamilyPage() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [tabs] = React.useState([
    'Agrupamentos',
    'Planos de Conta',
    'Categorias',
  ]);
  return (
    <Pane
      className='CategoriesPage'
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      overflowY='auto'
      padding={16}
    >
      <Tablist marginBottom={16} className='tabList'>
        {tabs.map((tab, index) => (
          <Tab
            className='individualTabs'
            key={tab}
            id={tab}
            onSelect={() => setSelectedIndex(index)}
            isSelected={index === selectedIndex}
            aria-controls={`panel-${tab}`}
          >
            {tab}
          </Tab>
        ))}
      </Tablist>
      <Pane
        padding={16}
        background='tint1'
        className='categoryFamilyPane'
      >
        <Pane
          key='dre-grouping'
          id={`panel-dre-grouping`}
          role='tabpanel'
          aria-labelledby='dre-grouping'
          aria-hidden={0 !== selectedIndex}
          display={0 === selectedIndex ? 'block' : 'none'}
        >
          <DreGroupingComponent />
        </Pane>
        <Pane
          key='account-plans'
          id={`panel-account-plans`}
          role='tabpanel'
          aria-labelledby='account-plans'
          aria-hidden={1 !== selectedIndex}
          display={1 === selectedIndex ? 'block' : 'none'}
        >
          <AccountPlansComponent />
        </Pane>
        <Pane
          key='categories'
          id={`panel-categories`}
          role='tabpanel'
          aria-labelledby='categories'
          aria-hidden={2 !== selectedIndex}
          display={2 === selectedIndex ? 'block' : 'none'}
        >
          <CategoriesComponent />
        </Pane>
      </Pane>
    </Pane>
  );
}

export default CategoriesFamilyPage;
