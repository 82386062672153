import { Heading, Pane, Select, Text } from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from 'recharts';
import { formatToMoney } from '../../helpers/moneyFormatter';

const DreResults = ({ dreResults, selectedDre }) => {
  const [maxValue, setMaxValue] = useState(0);
  const [maxPercent, setMaxPercent] = useState(0);

  console.log(dreResults);
  useEffect(() => {
    if (dreResults.length > 0) {
      const max = dreResults.reduce((prev, current) =>
        prev.Valor > current.Valor ? prev : current
      );
      const maxPercent = dreResults.reduce((prev, current) =>
        prev.Percentual > current.Percentual ? prev : current
      );
      setMaxValue(max.Valor);
      setMaxPercent(maxPercent.Percentual);
    }
  }, [dreResults]);
  const CustomTooltip = ({ props, payload }) => {
    return (
      <Pane
        backgroundColor='#9b9b9b'
        padding={10}
        display='flex'
        flexDirection='column'
        gap={10}
      >
        <Heading fontWeight='bold'>{payload?.[0]?.payload?.name}</Heading>
        <Pane>
          <Text fontWeight='bold'>Valor: </Text>
          <Text
            fontWeight='bold'
            color={payload[0]?.value < 0 ? '#F3485B' : '#028090'}
          >
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(payload[0]?.value)}
          </Text>
        </Pane>
        {selectedDre !== 'Receita Bruta' && (
          <Pane>
            <Text fontWeight='bold'>Percentual: </Text>
            <Text
              fontWeight='bold'
              color={payload[1]?.value < 0 ? '#F3485B' : '#028090'}
            >
              {formatToMoney(payload[1]?.value)}%
            </Text>
          </Pane>
        )}
      </Pane>
    );
  };
  return (
    <ResponsiveContainer width='100%' aspect={2}>
      <ComposedChart
        data={('dreResults', dreResults)}
        // width={300}
        // height='90%'
        margin={{
          top: 20,
          right: 36.8,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid
          stroke='#f5f5f5'
          strokeDasharray='1'
          horizontal='true'
          vertical=''
        />
        <XAxis dataKey='month' padding={{ left: 10, right: 10 }} />
        <YAxis
          domain={([dataMin, dataMax]) => {
            const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));
            return [Math.round(-absMax), Math.round(absMax)];
          }}
          ticks={[
            Math.round(maxValue),
            Math.round(maxValue / 2),
            0,
            Math.round(-maxValue / 2),
            Math.round(-maxValue),
          ]}
        />
        <YAxis
          yAxisId='right'
          orientation='right'
          domain={([dataMin, dataMax]) => {
            const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));
            return [Math.round(-absMax), Math.round(absMax)];
          }}
          ticks={[
            Math.round(maxPercent),
            Math.round(maxPercent / 2),
            0,
            Math.round(-maxPercent / 2),
            Math.round(-maxPercent),
          ]}
        />
        <Tooltip
          // contentStyle={{ background: '#9b9b9b' }}
          // itemStyle={{ fontWeight: 'bold' }}
          // labelStyle={{ fontWeight: 'bold' }}
          // formatter={(value) =>
          //   new Intl.NumberFormat('pt-BR', {
          //     style: 'currency',
          //     currency: 'BRL',
          //   }).format(value)
          // }
          content={<CustomTooltip />}
        />
        <Legend
          formatter={(value, entry, index) => {
            return entry.dataKey === 'Percentual' ? '% Receita Bruta' : value;
          }}
        />

        <Bar dataKey='Valor' barSize={20} fill='#028090'>
          {dreResults.map((entry, index) => {
            const color = entry.Valor < 0 ? '#F3485B' : '#028090';
            return <Cell key={`cell-${index}`} fill={color} />;
          })}
        </Bar>

        {selectedDre !== 'Receita Bruta' && (
          <Line
            yAxisId='right'
            type='monotone'
            dataKey='Percentual'
            stroke='white'
            strokeWidth={2}
            activeDot={{ r: 6, stroke: 'green', strokeWidth: 5 }}
            dot={<CustomizedDot />}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  if (value > 0) {
    return (
      <svg x={cx - 8} y={cy - 8}>
        <circle
          cx='8'
          cy='8'
          r='5'
          stroke='black'
          strokeWidth='1'
          fill='#4d8742'
        />
      </svg>
    );
  }

  if (value < 0) {
    return (
      <svg x={cx - 8} y={cy - 8}>
        <circle
          cx='8'
          cy='8'
          r='5'
          stroke='black'
          strokeWidth='1'
          fill='#cc5151'
        />
      </svg>
    );
  }
  return (
    <svg x={cx - 8} y={cy - 8}>
      <circle
        cx='8'
        cy='8'
        r='5'
        stroke='black'
        strokeWidth='1'
        fill='#9e9e9e'
      />
    </svg>
  );
};

export default DreResults;
