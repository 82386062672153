import {
  Badge,
  Button,
  Pane,
  Table,
  TextInput,
  TrashIcon,
  Heading,
  SelectField,
  SavedIcon,
} from 'evergreen-ui';
import React, { useState } from 'react';
import { mutate } from 'swr';
import { toastNotification } from '../../shared/toastNotification';
import {
  deleteUserRoles,
  postEditUserRoles,
  useQueryUserRolesWithUserId,
} from '../../services/api/userRolesService';
import UserRole from './UserRole';
import { useSelector } from 'react-redux';
import LoadingOverlay from '../../shared/LoadingOverlay';

const UserRoleList = ({}) => {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');
  const [selectedRole, setSelectedRole] = useState('');
  const [localSearch, setLocalSearch] = useState('');
  const [isShownDeleteDialog, setIsShownDeleteDialog] = useState(false);
  const [isShownUpdateDialog, setIsShownUpdateDialog] = useState(false);

  const { data: userRolesList, isLoading: isLoadingUserRolesList } =
    useQueryUserRolesWithUserId(
      { companyId: companyId, vitaUserId: userId },
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  if (isLoadingUserRolesList) {
    return <LoadingOverlay />;
  }

  const updateUser = (userRole) => {
    let statusResponse = '';
    let toastContent = '';

    const user = {
      adminId: userId,
      companyId: companyId,
      userRole: userRole.role,
      roleId: userRole.id,
    };

    console.log('userRole: ', userRole);
    postEditUserRoles(user)
      .then((res) => {
        console.log('res post invited user: ', res);
        mutate('userRolesCurrentRole');
        mutate('userRolesWithUserId');
        statusResponse = 'success';
        toastContent = 'Usuário alterado com sucesso.';
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = err.data;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        setIsShownUpdateDialog(false);
        mutate('banks');
      });
  };

  const removeUser = (userRole) => {
    let statusResponse = '';
    let toastContent = '';

    const user = {
      adminId: userId,
      companyId: companyId,
      userId: userRole.vitaUser,
      roleId: userRole.id,
    };

    console.log('userRoleDelete: ', user);
    deleteUserRoles(user)
      .then((res) => {
        console.log('res post deleted user: ', res);
        mutate('userRolesCurrentRole');
        mutate('userRolesWithUserId');
        statusResponse = 'success';
        toastContent = 'Usuário removido com sucesso.';
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = err.data;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        setIsShownDeleteDialog(false);
        // mutate('banks');
      });
  };

  return (
    <Pane
      display='flex'
      flexDirection='column'
      background='tint1'
      padding={16}
      borderRadius={5}
      marginTop={24}
      width='100%'
      height='fit-content'
    >
      <Heading alignSelf='center' size={700} margin={12}>
        Lista de Usuários
      </Heading>
      <TextInput
        placeholder='Filtrar'
        marginBottom={12}
        width='200px'
        onChange={(e) => setLocalSearch(e.target.value.toLowerCase())}
      />
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Nome</Table.TextHeaderCell>
          <Table.TextHeaderCell>Sobrenome</Table.TextHeaderCell>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell>Nível de Acesso</Table.TextHeaderCell>
          <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body maxHeight={250}>
          {!isLoadingUserRolesList &&
            userRolesList?.data
              ?.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }

                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              })
              .map((userRole) => {
                if (
                  userRole.name.toLowerCase().includes(localSearch) ||
                  userRole.lastName.toLowerCase().includes(localSearch) ||
                  userRole.email.toLowerCase().includes(localSearch) ||
                  userRole.userRole.toLowerCase().includes(localSearch)
                )
                  return (
                    <UserRole
                      key={userRole.id}
                      userRole={userRole}
                      updateUser={updateUser}
                      removeUser={removeUser}
                      isShownDeleteDialog={isShownDeleteDialog}
                      setIsShownDeleteDialog={setIsShownDeleteDialog}
                      isShownUpdateDialog={isShownUpdateDialog}
                      setIsShownUpdateDialog={setIsShownUpdateDialog}
                    />
                  );
              })}
        </Table.Body>
      </Table>
    </Pane>
  );
};

export default UserRoleList;
