import { React, useEffect, useState } from 'react';

import {
  Pane,
  Button,
  Popover,
  Position,
  Menu,
  ArrowTopRightIcon,
  ArrowBottomRightIcon,
  SwapHorizontalIcon,
} from 'evergreen-ui';
import BankCarousel from '../bank-carousel/BankCarousel';
import BalanceCardComponent from '../balance-cards/BalanceCardComponent';
import EntriesTableComponent from './EntriesTableComponent';
import { useQueryEntries } from '../../services/api/entriesService';
import { useSelector } from 'react-redux';
import { mutate } from 'swr';
import { useQueryUserRolesCurrentRole } from '../../services/api/userRolesService';
import LoadingOverlay from '../../shared/LoadingOverlay';

function EntryJournalsPage() {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const { data: currentRole, isLoading: isLoadingCurrentRole } =
    useQueryUserRolesCurrentRole(
      { companyId: companyId, vitaUserId: userId },
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );
  // Filtered values states

  const [inputBalance, setInputBalance] = useState(0);
  const [outputBalance, setOutputBalance] = useState(0);
  const [transferInBalance, setTransferInBalance] = useState(0);
  const [transferOutBalance, setTransferOutBalance] = useState(0);

  if (isLoadingCurrentRole) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Pane
        className='EntryJournalsPage'
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        overflowY='scroll'
      >
        {currentRole?.data.userRole !== 'LANÇADOR' && (
          <Pane
            className='box1'
            display='flex'
            gap={10}
            padding={16}
            flexWrap='wrap'
            width='100%'
            height='fit-content'
            flexDirection='column'
          >
            <Pane
              className='maincards'
              // display='flex'
              // width='100%'
              // justifyContent='center'
              // flexDirection='row'
              gap={8}
            >
              <BalanceCardComponent />
              <BankCarousel />
            </Pane>
          </Pane>
        )}

        <Pane
          className='box2'
          display='flex'
          gap={10}
          flexDirection='column'
          flexWrap='nowrap'
          backgroundColor='white'
        >
          <EntriesTableComponent />
        </Pane>
      </Pane>
    </>
  );
}

export default EntryJournalsPage;
