import React, { useEffect } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useQueryUserRolesCurrentRole } from '../services/api/userRolesService';
import LoadingOverlay from '../shared/LoadingOverlay';
import { mutate } from 'swr';
import NotFoundPage from '../components/NotFoundPage';
import AccessDeniedPage from '../components/AccessDeniedPage';

const PrivateRoute = ({ children, roles }) => {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    mutate('userRolesCurrentRole');
  }, [companyId, userId]);

  const { data: currentRole, isLoading: isLoadingCurrentRole } =
    useQueryUserRolesCurrentRole(
      { companyId: companyId, vitaUserId: userId },
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  if (isLoadingCurrentRole) {
    return <LoadingOverlay />;
  }

  return !isLoadingCurrentRole &&
    currentRole?.data &&
    roles.includes(currentRole.data.userRole) ? (
    children
  ) : (
    <AccessDeniedPage />
  );
};

export default PrivateRoute;
