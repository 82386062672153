export const parseOFXToJSON = (ofxData) => {
  //   const headerPattern =
  //     /OFXHEADER:\s*(\d+)\s+DATA:\s*(.+)\s+VERSION:\s*(\d+)\s+SECURITY:\s*(.+)\s+ENCODING:\s*(.+)\s+CHARSET:\s*(\d+)\s+COMPRESSION:\s*(.+)\s+OLDFILEUID:\s*(.+)\s+NEWFILEUID:\s*(.+)/;
  //   const signOnMsgPattern =
  //     /<SONRS>[\s\S]*?<DTSERVER>([^<]*)[\s\S]*?<LANGUAGE>([^<]*)/;
  //   const bankMsgPattern =
  //     /<BANKMSGSRSV1>[\s\S]*?<BANKTRANLIST>[\s\S]*?<DTSTART>([^<]*)[\s\S]*?<DTEND>([^<]*)/;
  //   const statementPattern =
  //     /<STMTRS>[\s\S]*?<CURDEF>([^<]*)[\s\S]*?<BANKID>([^<]*)[\s\S]*?<ACCTID>([^<]*)[\s\S]*?<ACCTTYPE>([^<]*)/;
  const transactionsPattern =
    /<STMTTRN>[\s\S]*?<TRNTYPE>([^<]*)[\s\S]*?<DTPOSTED>([^<]*)[\s\S]*?<TRNAMT>([^<]*)[\s\S]*?<FITID>([^<]*)[\s\S]*?<MEMO>([^<]*)/g;
  //   const ledgerBalancePattern =
  //     /<LEDGERBAL>[\s\S]*?<BALAMT>([^<]*)[\s\S]*?<DTASOF>([^<]*)/;

  //   const headerMatches = ofxData.match(headerPattern);
  //   const signOnMsgMatches = ofxData.match(signOnMsgPattern);
  //   const bankMsgMatches = ofxData.match(bankMsgPattern);
  //   const statementMatches = ofxData.match(statementPattern);
  const transactionsMatches = ofxData.match(transactionsPattern);
  //   const ledgerBalanceMatches = ofxData.match(ledgerBalancePattern);

  const header = {
    OFXHEADER: ofxData.match(/OFXHEADER:\s*(\d+)\s/)
      ? ofxData.match(/OFXHEADER:\s*(\d+)\s/)[1].trim()
      : '',
    DATA: ofxData.match(/DATA:\s*(.+)\s/)
      ? ofxData.match(/DATA:\s*(.+)\s/)[1].trim()
      : '',
    VERSION: ofxData.match(/VERSION:\s*(\d+)\s/)
      ? ofxData.match(/VERSION:\s*(\d+)\s/)[1].trim()
      : '',
    SECURITY: ofxData.match(/SECURITY:\s*(.+)\s/)
      ? ofxData.match(/SECURITY:\s*(.+)\s/)[1].trim()
      : '',
    ENCODING: ofxData.match(/ENCODING:\s*(.+)\s/)
      ? ofxData.match(/ENCODING:\s*(.+)\s/)[1].trim()
      : '',
    CHARSET: ofxData.match(/CHARSET:\s*(\d+)\s/)
      ? ofxData.match(/CHARSET:\s*(\d+)\s/)[1].trim()
      : '',
    COMPRESSION: ofxData.match(/COMPRESSION:\s*(.+)\s/)
      ? ofxData.match(/COMPRESSION:\s*(.+)\s/)[1].trim()
      : '',
    OLDFILEUID: ofxData.match(/OLDFILEUID:\s*(.+)\s/)
      ? ofxData.match(/OLDFILEUID:\s*(.+)\s/)[1].trim()
      : '',
    NEWFILEUID: ofxData.match(/NEWFILEUID:\s*(.+)/)
      ? ofxData.match(/NEWFILEUID:\s*(.+)/)[1].trim()
      : '',
  };

  const signOnMsg = {
    DTSERVER: ofxData.match(/<DTSERVER>([^<]*)/)
      ? ofxData.match(/<DTSERVER>([^<]*)/)[1].trim()
      : '',
    LANGUAGE: ofxData.match(/<LANGUAGE>([^<]*)/)
      ? ofxData.match(/<LANGUAGE>([^<]*)/)[1].trim()
      : '',
    FID: ofxData.match(/<FID>([^<]*)/)
      ? ofxData.match(/<FID>([^<]*)/)[1].trim()
      : '',
    ORG: ofxData.match(/<ORG>([^<]*)/)
      ? ofxData.match(/<ORG>([^<]*)/)[1].trim()
      : '',
  };

  const bankMsg = {
    DTSTART: ofxData.match(/<DTSTART>([^<]*)/)
      ? ofxData.match(/<DTSTART>([^<]*)/)[1].trim()
      : '',
    DTEND: ofxData.match(/<DTEND>([^<]*)/)
      ? ofxData.match(/<DTEND>([^<]*)/)[1].trim()
      : '',
  };

  const statement = {
    CURDEF: ofxData.match(/<CURDEF>([^<]*)/)
      ? ofxData.match(/<CURDEF>([^<]*)/)[1].trim()
      : '',
    BANKID: ofxData.match(/<BANKID>([^<]*)/)
      ? ofxData.match(/<BANKID>([^<]*)/)[1].trim()
      : '',
    ACCTID: ofxData.match(/<ACCTID>([^<]*)/)
      ? ofxData.match(/<ACCTID>([^<]*)/)[1].trim()
      : '',
    ACCTTYPE: ofxData.match(/<ACCTTYPE>([^<]*)/)
      ? ofxData.match(/<ACCTTYPE>([^<]*)/)[1].trim()
      : '',
  };

  const transactions = [];
  for (const match of transactionsMatches) {
    const transaction = {
      TRNTYPE: match.match(/<TRNTYPE>([^<]*)/)
        ? match.match(/<TRNTYPE>([^<]*)/)[1].trim()
        : '',
      DTPOSTED: match.match(/<DTPOSTED>([^<]*)/)
        ? match.match(/<DTPOSTED>([^<]*)/)[1].trim()
        : '',
      TRNAMT: match.match(/<TRNAMT>([^<]*)/)
        ? match
            .match(/<TRNAMT>([^<]*)/)[1]
            .trim()
            .replace(',', '.')
        : '',
      FITID: match.match(/<FITID>([^<]*)/)
        ? match.match(/<FITID>([^<]*)/)[1].trim()
        : '',
      CHECKNUM: match.match(/<CHECKNUM>([^<]*)/)
        ? match.match(/<CHECKNUM>([^<]*)/)[1].trim()
        : '',
      MEMO: match.match(/<MEMO>([^<]*)/)
        ? match.match(/<MEMO>([^<]*)/)[1].trim()
        : '',
    };
    transactions.push(transaction);
  }

  const ledgerBalance = {
    BALAMT: ofxData.match(/<BALAMT>([^<]*)/)
      ? ofxData.match(/<BALAMT>([^<]*)/)[1].trim()
      : '',
    DTASOF: ofxData.match(/<DTASOF>([^<]*)/)
      ? ofxData.match(/<DTASOF>([^<]*)/)[1].trim()
      : '',
  };

  return {
    header,
    data: {
      signOnMsg,
      bankMsg,
      statement,
      transactions,
      ledgerBalance,
    },
  };
};

// Helper function to format date as DD/MM/YYYY
const formatDate = (dateString) => {
  const date = dateString.substr(0, 8); // Extract the date part (YYYYMMDD)
  const year = date.substr(0, 4);
  const month = date.substr(4, 2);
  const day = date.substr(6, 2);
  return `${day}/${month}/${year}`;
};

// Helper function to convert values to BRL using Intl.NumberFormat
const formatToBRL = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};
