import useSWR from 'swr';
import request from './request';

/*
  Axios call methods
*/

export const getEntries = async (companyId) => {
  return request({
    url: `JournalEntries/Company/${companyId}`,
    method: 'GET',
  });
};

export const getEntriesBankBalance = async (companyId) => {
  return request({
    url: `JournalEntries/Company/${companyId}/BanksBalance`,
    method: 'GET',
  });
};

export const getEntriesFiltered = async (companyId, start, end) => {
  return request({
    url: `JournalEntries/Company/${companyId}/Filtered?startDate=${start}T00%3A00%3A00Z&endDate=${end}T23%3A59%3A59Z`,
    method: 'GET',
  });
};

export const getEntriesFilteredByDate = async (companyId, start, end, type) => {
  return request({
    url: `JournalEntries/Company/${companyId}/FilteredByDate?startDate=${start}T00%3A00%3A00Z&endDate=${end}T23%3A59%3A59Z&entryType=${type}`,
    method: 'GET',
  });
};

export const getEntry = async (entryId) => {
  return request({
    url: `JournalEntries/${entryId}`,
    method: 'GET',
  });
};

export const putEntry = async (entry) => {
  console.log('putEntry: ', entry);
  return request({
    url: `JournalEntries/${entry.id}`,
    method: 'POST',
    data: entry,
  });
};

export const putManyEntry = async (entryList) => {
  console.log('putEntry: ', entryList);
  return request({
    url: `JournalEntries/UpdateMany`,
    method: 'POST',
    data: entryList,
  });
};

export const postEntry = async (entry) => {
  console.log('postEntry: ', entry);
  return request({
    url: `JournalEntries/`,
    method: 'POST',
    data: entry,
  });
};

export const deleteEntry = async (entry) => {
  console.log('deleteEntry: ', entry);
  return request({
    url: `JournalEntries?id=${entry}`,
    method: 'DELETE',
  });
};

export const deleteManyEntries = async (entryList) => {
  console.log('deleteManyEntry: ', entryList);
  return request({
    url: `JournalEntries/DeleteMany`,
    method: 'DELETE',
    data: entryList,
  });
};

/*
  useQuery - SWR methods
*/

export const useQueryEntries = (companyId, options) => {
  const key = 'entries';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId ? () => getEntries(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryEntriesBankBalance = (companyId, options) => {
  const key = 'entriesBankBalance';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId ? () => getEntriesBankBalance(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryEntriesFiltered = (
  companyId,
  start,
  end,
  type,
  options
) => {
  const key = 'entriesFiltered';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId ? () => getEntriesFiltered(companyId, start, end, type) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryEntriesFilteredByDate = (
  companyId,
  start,
  end,
  type,
  options
) => {
  const key = 'entriesFilteredByDate';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId
      ? () => getEntriesFilteredByDate(companyId, start, end, type)
      : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryEntriesFilteredByYear = (
  companyId,
  start,
  end,
  type,
  options
) => {
  const key = 'entriesFilteredByYear';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId
      ? () => getEntriesFilteredByDate(companyId, start, end, type)
      : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryEntriesCalendar = (
  companyId,
  start,
  end,
  type,
  options
) => {
  const key = 'entriesCalendar';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId
      ? () => getEntriesFilteredByDate(companyId, start, end, type)
      : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryEntriesReconciliation = (
  companyId,
  start,
  end,
  type,
  options
) => {
  const key = 'entriesReconciliation';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    companyId && start && end
      ? () => getEntriesFilteredByDate(companyId, start, end, type)
      : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export const useQueryEntry = (entryId) => {
  const key = 'entry';
  const { data, error, mutate } = useSWR(
    () => key,
    entryId ? () => getEntry(entryId) : null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
