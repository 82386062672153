import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  pageTab: true,
  formButton: false,
};

const createUserSlice = createSlice({
  name: 'createUser',
  initialState: initialFormState,
  reducers: {
    setTabDisabled(state, action) {
      state.pageTab = action.payload;
    },
    setButtonDisabled(state, action) {
      state.formButton = action.payload;
    },
  },
});

export const createUserActions = createUserSlice.actions;

export default createUserSlice.reducer;
