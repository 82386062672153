import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  userId: '',
  userName: '',
  userEmail: '',
  companyId: '',
  companyName: '',
  userCreatedAt: '',
  userLastLogin: '',
};

const loginValuesSlice = createSlice({
  name: 'loginValues',
  initialState: initialFormState,
  reducers: {
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setUserEmail(state, action) {
      state.userEmail = action.payload;
    },
    setCompanyId(state, action) {
      state.companyId = action.payload;
    },
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
    setUserCreatedAt(state, action) {
      state.userCreatedAt = action.payload;
    },
    setUserLastLogin(state, action) {
      state.userLastLogin = action.payload;
    },
    resetLoginValues(state) {
      state.userId = '';
      state.userName = '';
      state.userEmail = '';
      state.companyId = '';
      state.companyName = '';
      state.userCreatedAt = '';
      state.userLastLogin = '';
    },
  },
});

export const loginValuesActions = loginValuesSlice.actions;

export default loginValuesSlice.reducer;
