import { configureStore } from '@reduxjs/toolkit';
import dateFilterReducer from './dateFilter';
import createUserReducer from './createUser';
import balanceValuesReducer from './balanceValues';
import dreValuesReducer from './dreValues';
import bankValueReducer from './bankValue';
import dashboardValuesReducer from './dashboardValues';
import calendarValuesReducer from './calendarValues';
import reconciliationValuesReducer from './reconciliationValues';
import entryValuesReducer from './entryValues';
import loginValuesReducer from './loginValues';

const store = configureStore({
  reducer: {
    dateFilter: dateFilterReducer,
    createUser: createUserReducer,
    balanceValues: balanceValuesReducer,
    dreValues: dreValuesReducer,
    bankValue: bankValueReducer,
    dashboardValues: dashboardValuesReducer,
    calendarValues: calendarValuesReducer,
    reconciliationValues: reconciliationValuesReducer,
    entryValues: entryValuesReducer,
    loginValues: loginValuesReducer,
  },
});

export default store;
