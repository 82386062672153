import useSWR from 'swr';
import request from './request';

/*
  Axios call methods
*/

export const getAccountPlans = async (companyId) => {
  return request({
    url: `AccountPlans/Company/${companyId}`,
    method: 'GET',
  });
};

export const getAccountPlan = async (id) => {
  return request({
    url: `AccountPlans/${id}`,
    method: 'GET',
  });
};

export const putAccountPlan = async (accountPlan) => {
  console.log('putAccountPlan: ', accountPlan);
  return request({
    url: `AccountPlans/${accountPlan.id}`,
    method: 'PUT',
    data: accountPlan,
  });
};

export const postAccountPlan = async (accountPlan) => {
  console.log('postAccountPlan: ', accountPlan);
  return request({
    url: `AccountPlans/`,
    method: 'POST',
    data: accountPlan,
  });
};

export const deleteAccountPlan = async (accountPlan) => {
  console.log('deleteAccountPlan: ', accountPlan);
  return request({
    url: `AccountPlans?id=${accountPlan}`,
    method: 'DELETE',
  });
};
/*
  useQuery - SWR methods
*/

export const useQueryAccountPlans = (companyId, options) => {
  const key = 'accountPlans';
  const { data, error } = useSWR(
    () => key,
    companyId ? () => getAccountPlans(companyId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useQueryAccountPlan = (id, options) => {
  const key = 'accountPlan';
  const { data, error } = useSWR(
    () => key,
    id ? () => getAccountPlan(id) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
