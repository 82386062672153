import { createSlice } from '@reduxjs/toolkit';

const initialBankValueState = {
  selectedIndex: 0,
  banksList: [],
};

const bankValueSlice = createSlice({
  name: 'bankValue',
  initialState: initialBankValueState,
  reducers: {
    setSelectedIndex(state, action) {
      state.selectedIndex = action.payload;
    },
    setBanksList(state, action) {
      state.banksList = action.payload;
    },
  },
});

export const bankValueActions = bankValueSlice.actions;

export default bankValueSlice.reducer;
