import React from 'react';
import { Card, Heading, Pane } from 'evergreen-ui';
import { formatToMoney } from '../../helpers/moneyFormatter';
import BalanceResultComponent from './BalanceResultComponent';
import TransferResultComponent from './TransferResultComponent';
import { convertDate2 } from '../../helpers/dateConverter';
import { useSelector } from 'react-redux';

function BalanceCardComponent() {
  const startDate = useSelector(
    (state) => state.dateFilter.startDate
  );
  const endDate = useSelector(
    (state) => state.dateFilter.endDate
  );

  const input = useSelector(
    (state) => state.balanceValues.input
  );
  const output = useSelector(
    (state) => state.balanceValues.output
  );

  const transferInput = useSelector(
    (state) => state.balanceValues.transferInput
  );
  const transferOutput = useSelector(
    (state) => state.balanceValues.transferOutput
  );

  const totalBalance = () => {
    return (
      input +
      transferInput -
      output -
      transferOutput
    ).toFixed(2);
  };

  return (
    <Pane
      background='white'
      display='flex'
      justifyContent='space-between'
      position='relative'
      flexWrap='wrap'
      width='100%'
      maxWidth='100%'
      minWidth='60%'
      height='100%'
      border='none'
      boxShadow='none'
      outline='none'
      borderRadius='4px'
      padding={6}
    >
      <Pane
        display='flex'
        textAlign='center'
        alignItems='center'
        justifyItems='center'
        margin='auto'
      >
        <Heading size={500} fontWeight={500}>
          {`${convertDate2(startDate)} - ${convertDate2(
            endDate
          )}`}
        </Heading>
      </Pane>
      <Card
        background='white'
        position='relative'
        display='flex'
        justifyContent='space-between'
        flexWrap='wrap'
        width='100%'
        maxWidth='100%'
        minWidth='20%'
        border='none'
        boxShadow='none'
        outline='none'
        padding={6}
      >
        <Pane display='flex' flexDirection='column' flex='auto'>
          <Pane
            display='flex'
            paddingTop={16}
            paddingX={24}
            gap={12}
          >
            <Heading
              size={600}
              color='#767676'
              fontWeight='400'
            >
              Balanço
            </Heading>
          </Pane>
          <Pane display='flex' width='100%' flexWrap='wrap'>
            <BalanceResultComponent />
          </Pane>
        </Pane>
        <Pane display='flex' flexDirection='column' flex='auto'>
          <Pane
            display='flex'
            paddingTop={16}
            paddingX={24}
            gap={12}
          >
            <Heading
              size={600}
              color='#767676'
              fontWeight='400'
            >
              Transferências
            </Heading>
          </Pane>
          <Pane display='flex' width='100%' flexWrap='wrap'>
            <TransferResultComponent />
          </Pane>
        </Pane>

        <Card
          display='flex'
          flexDirection='column'
          paddingY={15}
          paddingX={24}
          background='#F5F5F5'
          // justifyContent='center'
          flex={1}
        >
          <Heading size={600} color='#767676' fontWeight='400'>
            Saldo
          </Heading>
          <Heading size={800} whiteSpace='nowrap'>
            R${' '}
            {formatToMoney(
              (
                input +
                transferInput -
                output -
                transferOutput
              ).toFixed(2)
            )}
          </Heading>
        </Card>
      </Card>
    </Pane>
  );
}

export default BalanceCardComponent;
