import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import {
  dreClassificationArray,
  monthsArray,
  indexToMonthObject,
  lancamentoReceitaLiquida,
  lancamentoMargemBruta,
  lancamentoEbitda,
  lancamentoEbit,
  lancamentoLucroLiquido,
  totalCpv,
  totalDepreciacoesAmortizacoes,
  totalDescontoFaturamento,
  totalDespesasFinanceiras,
  totalDespesasGerais,
  totalDespesasNaoOperacionais,
  totalInvestimentos,
  totalReceitaBruta,
  totalReceitasFinanceiras,
  totalReceitasNaoOperacionais,
  formatObjects,
  resetData,
  fluxoCriacaoDre,
  populateNamesArrays,
} from '../../helpers/dreHelper';

import {
  Heading,
  MinusIcon,
  Pane,
  PlusIcon,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  Card,
  Checkbox,
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
  Menu,
  Button,
  Popover,
  Position,
  StatusIndicator,
} from 'evergreen-ui';

import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  ColumnResizeMode,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getGroupedRowModel,
  getPaginationRowModel,
  GroupingState,
  useReactTable,
} from '@tanstack/react-table';
import LoadingOverlay from '../../shared/LoadingOverlay';
import {
  useQueryEntries,
  useQueryEntriesFilteredByDate,
} from '../../services/api/entriesService';
import { formatToMoney } from '../../helpers/moneyFormatter';
import DreFilterBar from './DreFilterBar';
import { useDispatch, useSelector } from 'react-redux';
import { mutate } from 'swr';
import { Tooltip, Zoom } from '@mui/material';

function DrePage() {
  const [editedJournal, setEditedJournal] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [grouping, setGrouping] = useState([
    // 'classificacao',
    // 'dreGroup',
    // 'accountPlan',
    // 'category',
  ]);

  const companyId = localStorage.getItem('companyId');

  const startDreDate = useSelector((state) => state.dateFilter.startDreDate);
  const endDreDate = useSelector((state) => state.dateFilter.endDreDate);
  const dreYear = useSelector((state) => state.dateFilter.dreYear);
  const filterType = useSelector((state) => state.dreValues.filterType);
  const entryStatus = useSelector((state) => state.dreValues.entryStatus);
  const dreArray = useSelector((state) => state.dateFilter.dreArray);

  const realValuesCheckbox = useSelector(
    (state) => state.dreValues.realValuesCheckbox
  );
  const percentValuesCheckbox = useSelector(
    (state) => state.dreValues.percentValuesCheckbox
  );

  const uncheckedMonths = useSelector(
    (state) => state.dreValues.uncheckedMonths
  );

  const [columnVisibility, setColumnVisibility] = useState({
    // dreGroup: false,
    // accountPlan: false,
    // category: false,
    // description: false,
    // janeiro: true,
    // fevereiro: true,
    // marco: true,
    // abril: true,
    // maio: true,
    // junho: true,
    // julho: true,
    // agosto: true,
    // setembro: true,
    // outubro: true,
    // novembro: true,
    // dezembro: true,
    // janeiroPercent: false,
    // fevereiroPercent: false,
    // marcoPercent: false,
    // abrilPercent: false,
    // maioPercent: false,
    // junhoPercent: false,
    // julhoPercent: false,
    // agostoPercent: false,
    // setembroPercent: false,
    // outubroPercent: false,
    // novembroPercent: false,
    // dezembroPercent: false,
    // media: false,
    // mediaPercent: false,
  });

  const [columnResizeMode, setColumnResizeMode] = useState();

  const {
    data: journalEntriesFilteredByDate,
    isLoading: isLoadingJournalEntriesFilteredByDate,
    isValidating: isValidatingJournalEntriesFilteredByDate,
  } = useQueryEntriesFilteredByDate(
    companyId,
    startDreDate,
    endDreDate,
    filterType,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    if (!isValidatingJournalEntriesFilteredByDate) {
      populateNamesArrays(
        journalEntriesFilteredByDate.data,
        filterType,
        entryStatus,
        uncheckedMonths,
        setEditedJournal
      );
    }
    return () => {
      resetData();
    };
  }, [
    isValidatingJournalEntriesFilteredByDate,
    entryStatus,
    uncheckedMonths,
    realValuesCheckbox,
    percentValuesCheckbox,
  ]);

  useEffect(() => {
    mutate('entriesFilteredByDate');
  }, [dreYear, filterType]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('description', {
        header: 'Descrição',
        id: 'description',
        enableHiding: false,
        size: 100,
        minSize: 100,
        maxSize: 100,
        cell: ({ row, getValue }) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${row.depth * 0.7}rem`,
              cursor: `${row.getCanExpand() ? 'pointer' : 'default'}`,
            }}
            onClick={row.getToggleExpandedHandler()}
          >
            {row.getCanExpand() ? (
              row.getIsExpanded() ? (
                <MinusIcon
                  key={row.id}
                  size={10}
                  color='rgb(91, 91, 91)'
                  cursor='pointer'
                  // onClick={row.getToggleExpandedHandler()}
                />
              ) : (
                <PlusIcon
                  key={row.id}
                  size={10}
                  color='rgb(91, 91, 91)'
                  cursor='pointer'
                  // onClick={row.getToggleExpandedHandler()}
                />
              )
            ) : (
              ''
            )}{' '}
            {row.depth > 0 ? (
              <Tooltip
                title={getValue()}
                enterDelay={800}
                leaveDelay={0}
                TransitionComponent={Zoom}
                arrow
                placement='top'
              >
                <span>{getValue()}</span>
              </Tooltip>
            ) : (
              getValue()
            )}
          </div>
        ),
      }),
      columnHelper.accessor('janeiro', {
        header: 'Janeiro',
        id: 'janeiro',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      }),
      {
        accessorKey: 'janeiroPercent',
        header: 'Janeiro %',
        id: 'janeiroPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      columnHelper.accessor('fevereiro', {
        header: 'Fevereiro',
        id: 'fevereiro',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      }),
      {
        accessorKey: 'fevereiroPercent',
        header: 'Fevereiro %',
        id: 'fevereiroPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      columnHelper.accessor('marco', {
        header: 'Março',
        id: 'marco',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      }),
      {
        accessorKey: 'marcoPercent',
        header: 'Março %',
        id: 'marcoPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      columnHelper.accessor('abril', {
        header: 'Abril',
        id: 'abril',
        size: 30,
        minSize: 30,
        maxSize: 30,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      }),
      {
        accessorKey: 'abrilPercent',
        header: 'Abril %',
        id: 'abrilPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      columnHelper.accessor('maio', {
        header: 'Maio',
        id: 'maio',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      }),
      {
        accessorKey: 'maioPercent',
        header: 'Maio %',
        id: 'maioPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      {
        accessorKey: 'junho',
        header: 'Junho',
        id: 'junho',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      },
      {
        accessorKey: 'junhoPercent',
        header: 'Junho %',
        id: 'junhoPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      {
        accessorKey: 'julho',
        header: 'Julho',
        id: 'julho',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      },
      {
        accessorKey: 'julhoPercent',
        header: 'Julho %',
        id: 'julhoPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      {
        accessorKey: 'agosto',
        header: 'Agosto',
        id: 'agosto',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      },
      {
        accessorKey: 'agostoPercent',
        header: 'Agosto %',
        id: 'agostoPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      {
        accessorKey: 'setembro',
        header: 'Setembro',
        id: 'setembro',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      },
      {
        accessorKey: 'setembroPercent',
        header: 'Setembro %',
        id: 'setembroPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      {
        accessorKey: 'outubro',
        header: 'Outubro',
        id: 'outubro',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      },
      {
        accessorKey: 'outubroPercent',
        header: 'Outubro %',
        id: 'outubroPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      {
        accessorKey: 'novembro',
        header: 'Novembro',
        id: 'novembro',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      },
      {
        accessorKey: 'novembroPercent',
        header: 'Novembro %',
        id: 'novembroPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      {
        accessorKey: 'dezembro',
        header: 'Dezembro',
        id: 'dezembro',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      },
      {
        accessorKey: 'dezembroPercent',
        header: 'Dezembro %',
        id: 'dezembroPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      },
      columnHelper.accessor('media', {
        header: 'Média',
        id: 'media',
        size: 30,
        minSize: 30,
        maxSize: 30,
        enableHiding: false,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      }),
      columnHelper.accessor('mediaPercent', {
        header: 'Média %',
        id: 'mediaPercent',
        size: 50,
        minSize: 50,
        maxSize: 50,
        enableHiding: false,
        cell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
        aggregatedCell: (item) => {
          return `${formatToMoney(item.getValue())}%`;
        },
      }),
      columnHelper.accessor('acumulado', {
        header: 'Acumulado',
        id: 'acumulado',
        size: 50,
        minSize: 50,
        maxSize: 50,
        enableHiding: false,
        enableResizing: true,
        cell: (item) => {
          return formatToMoney(item.getValue());
        },
        aggregatedCell: (item) => {
          return formatToMoney(item.getValue());
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: editedJournal,
    columns,
    columnResizeMode,

    state: {
      columnVisibility,
      expanded: expanded,
    },
    initialState: {
      pagination: {
        pageSize: 1000,
        pageIndex: 0,
      },
    },
    // defaultColumn: {
    //   size: 180,
    //   minSize: 50,
    //   maxSize: 180,
    // },
    getSubRows: (row) => row.subRows,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Pane overflowX='auto'>
      {isValidatingJournalEntriesFilteredByDate && <LoadingOverlay />}
      <Pane
        display='flex'
        marginTop={22}
        marginBottom={22}
        justifyContent='center'
      >
        <Heading
          // size={800}
          fontSize='3.5vmin'
          fontWeight='bold'
          color='rgba(81, 81, 81,0.8)'
        >
          Declaração de Resultado do Exercício
        </Heading>
      </Pane>
      <Pane>
        <DreFilterBar tableProps={table} entries={editedJournal} />
      </Pane>
      <Pane
        display='flex'
        flexDirection='column'
        gap={2}
        width='100%'
        marginTop={8}
        paddingX={6}
        // paddingY={2}
        borderRadius={10}
        overflowY='auto'
        boxShadow='none'
      >
        {/* <Table border='none' boxShadow='none' overflowX='scroll'> */}
        <table width='100%'>
          <thead style={{ backgroundColor: 'rgb(235, 235, 235)' }}>
            {
              // Loop over the header rows
              table.getHeaderGroups().map((headerGroup) => (
                // Apply the header row props
                <tr
                  key={headerGroup.id}
                  style={{ backgroundColor: '#f4f5f9' }}
                  // backgroundColor='#f4f5f9'
                  // marginBottom={2}
                  // border='none'
                  // height='auto'
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((header) => {
                      return (
                        <th
                          {...{
                            key: header.id,
                            // colSpan: header.colSpan,
                            style: {
                              width: header.column.columnDef.maxSize,
                              fontSize: '11px',
                              padding: '6px',
                              textAlign: 'center',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          // Apply the header cell props
                          // key={header.id}
                          // padding={0}
                          // alignItems='center'
                          // display='inline-block'
                          // width='20px'
                          // width={header.column.getSize()}
                        >
                          {/* <Heading
                            display='flex'
                            justifyContent='center'
                            style={{
                              fontSize: '10px',
                              width: '',
                            }}
                            // onClick={
                            //   header.id === 'description'
                            //     ? table.getToggleAllRowsExpandedHandler()
                            //     : null
                            // }
                          > */}
                          {header.isPlaceholder ? null : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                          {/* </Heading> */}
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody>
            {table.getRowModel().rows?.map((row) => {
              return (
                <tr
                  key={row.id}
                  borderRadius={0}
                  style={{
                    height: '30px',
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <>
                        <td
                          {...{
                            key: cell.id,
                            style: {
                              width: cell.column.columnDef.maxSize,
                              textAlign: 'center',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              padding: '6px',
                              border: 'px solid lightgray',
                              background:
                                cell.row.depth === 0
                                  ? 'rgb(205, 205, 205)'
                                  : cell.row.depth === 1
                                  ? 'rgb(215, 215, 215)'
                                  : cell.row.depth === 2
                                  ? 'rgb(225, 225, 225)'
                                  : cell.row.depth === 3
                                  ? 'rgb(235, 235, 235)'
                                  : 'white',
                            },
                          }}
                          // key={cell.id}
                          // style={{
                          //   display: 'flex',
                          //   justifyContent:
                          //     cell.column.columnDef.accessorKey !== 'description'
                          //       ? 'center'
                          //       : 'normal',
                          //   whiteSpace: 'nowrap',
                          //   overflow: 'hidden',
                          //   textOverflow: 'ellipsis',
                          //   // padding: 0,
                          //   fontSize: '12px',
                          //   fontWeight: 'bold',
                          //   border: 'px solid lightgray',
                          //   background:
                          //     cell.row.depth === 0
                          //       ? 'rgb(225, 225, 225)'
                          //       : cell.row.depth === 4
                          //       ? 'white'
                          //       : 'rgb(235, 235, 235)',
                          // }}
                          // width={cell.column.getSize()}
                        >
                          {/* <Heading
                          key={cell.id}
                          display='flex'
                          justifyContent='center'
                          style={{
                            fontSize: '12px',
                            // width:
                            //   cell.column.columnDef
                            //     .size,
                          }}
                        > */}
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                          {/* </Heading> */}
                        </td>
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* </Table> */}
      </Pane>
    </Pane>
  );
}

export default DrePage;
