import request from './request';
import useSWR from 'swr';

export const postVitaUser = async (userObject) => {
  return request({
    url: 'vitaUser',
    timeout: 10000,
    method: 'POST',
    data: userObject,
  });
};

export const postEmployee = async (userObject) => {
  return request({
    url: 'vitaUser/newEmployee',
    timeout: 10000,
    method: 'POST',
    data: userObject,
  });
};

export const putVitaUser = async (userObject) => {
  return request({
    url: `VitaUser/${userObject.id}`,
    timeout: 10000,
    method: 'PUT',
    data: userObject,
  });
};

export const signTerm = async (userId) => {
  return request({
    url: `VitaUser/signTerm`,
    timeout: 10000,
    method: 'POST',
    data: userId,
  });
};

export const getVitaUserByIdentityId = async (authId) => {
  // console.log('get VitaUserByIdentityId :', authId);
  return request({
    url: `VitaUser/Identity/${authId}`,
    method: 'GET',
  });
};

export const getVitaUser = async (id) => {
  return request({
    url: `VitaUser/${id}`,
    method: 'GET',
  });
};

export const useQueryVitaUserByIdentityId = (authId, options) => {
  // console.log('useQuery VitaUserByIdentityId: ', authId);
  const key = 'vitaUserByIdentityId';
  const { data, error, mutate, isValidating } = useSWR(
    () => key,
    authId ? () => getVitaUserByIdentityId(authId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isValidating,
    isError: error,

    mutate,
  };
};

export const useQueryVitaUser = (authId, options) => {
  const key = 'vitaUser';
  const { data, error, mutate } = useSWR(
    () => key,
    authId ? () => getVitaUserByIdentityId(authId) : null,
    options ?? null
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
