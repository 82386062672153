import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  IconButton,
  SelectField,
  CrossIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Switch,
} from 'evergreen-ui';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { useQueryCategories } from '../../services/api/categoriesService';
import { useQueryBanks } from '../../services/api/banksService';
import { postEntry } from '../../services/api/entriesService';
import {
  getIntentColorByJournal,
  getNameByJournal,
} from '../../helpers/entriesHelper';
import styles from './EntryFormStatementComponent.module.css';

import DividerComponent from '../../custom/DividerComponent';

import { toastNotification } from '../../shared/toastNotification';
import { inputEntryValidation } from '../../helpers/formHelper';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import { convertDate, convertDate2 } from '../../helpers/dateConverter';
import { useDispatch, useSelector } from 'react-redux';
import { putStatement } from '../../services/api/statementService';
import { reconciliationValuesActions } from '../../store/reconciliationValues';

function EntryFormStatementComponent({
  isShown,
  setIsShown,
  journalEntry = {},
}) {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const { currentStatement, entryList, statementList } = useSelector(
    (state) => state.reconciliationValues
  );

  const dispatch = useDispatch();

  /* Dialog settings  */

  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  /* API calls */

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: categories = [], isLoading: isLoadingCategories } =
    useQueryCategories(
      // company.data.id,
      companyId,
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({});

  setConfiguration({
    containerWidths: '100%',
    gutterWidth: '12',
  });

  useEffect(() => {
    if (isShown) {
      console.log('journalEntry', journalEntry);
      const entry = {
        ...journalEntry,
        dateCompetencia: journalEntry.dateCompetencia,
        dateVencimento: journalEntry.dateVencimento,
      };
      reset(entry);
      setValue(
        'value',
        journalEntry.value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      );
      // setValue(
      //   'fineValue',
      //   journalEntry.fineValue.toLocaleString('pt-BR', {
      //     minimumFractionDigits: 2,
      //   })
      // );
      // setValue(
      //   'discountValue',
      //   journalEntry.discountValue.toLocaleString('pt-BR', {
      //     minimumFractionDigits: 2,
      //   })
      // );
      // setValue(
      //   'interestValue',
      //   journalEntry.interestValue.toLocaleString('pt-BR', {
      //     minimumFractionDigits: 2,
      //   })
      // );
      // setValue(
      //   'interestValuePercent',
      //   formatWithLimitedDecimals(
      //     (Number(
      //       getValues('interestValue').replace(/\./g, '').replace(',', '.')
      //     ) /
      //       Number(getValues('value').replace(/\./g, '').replace(',', '.'))) *
      //       100
      //   )
      // );
    }
  }, [isShown]);

  useEffect(() => {
    inputEntryValidation(
      watch('dateCompetencia'),
      journalEntry?.dateVencimento,
      journalEntry?.bankAccountId,
      watch('categoryId'),
      journalEntry?.value,
      setIsDisabled
    );
  }, [watch('dateCompetencia'), watch('categoryId')]);

  const handleInstallments = (multiplier) => {
    const tempDate = new Date(getValues('dateCompetencia'));

    tempDate.setMonth(tempDate.getMonth() + multiplier);

    // console.log(convertDate2(tempDate.toISOString()));
    if (getValues('dateCompetencia'))
      return convertDate2(tempDate.toISOString());
  };

  const buildFormHeader = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='space-between'>
        <Button
          appearance='primary'
          intent={getIntentColorByJournal(journalEntry)}
          height={48}
          marginRight={16}
        >
          {getNameByJournal(journalEntry)}
        </Button>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane>
        <Button
          form='entry-statement-form'
          type='submit'
          width='fit-content'
          disabled={isDisabled}
          isLoading={isLoading}
          // onClick={() => {
          //   console.log('click');
          // }}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (entry) => {
    setIsLoading(true);
    console.log('onSubmit', entry);
    let statusResponse = false;
    let toastContent = '';

    let entryToCreate = {
      companyId: companyId,
      journalEntryType: 1,
      isInput: journalEntry.isInput,
      userUpdateId: !isLoadingVitaUser && vitaUser.data.id,
      userCreateId: !isLoadingVitaUser && vitaUser.data.id,
      dateCompetencia: entry.dateCompetencia,
      dateVencimento: journalEntry.dateVencimento,
      description: entry.description,
      statusPayment: entry.statusPayment,
      paymentType: 1,
      fitId: journalEntry.fitId,
      discountValue: !!entry.discountValue
        ? Number(entry.discountValue.replace(/\./g, '').replace(',', '.'))
        : 0,
      fineValue: !!entry.fineValue
        ? Number(entry.fineValue.replace(/\./g, '').replace(',', '.'))
        : 0,
      interestValue: !!entry.interestValue
        ? Number(entry.interestValue.replace(/\./g, '').replace(',', '.'))
        : 0,
      value: Number(entry.value.replace(/\./g, '').replace(',', '.')),
      categoryId: entry.categoryId,
      bankAccountId: entry.bankAccountId,
      bankAccountIdDestination: entry.bankAccountIdDestination,
      repetitionsCount: entry.repetitionsCount,
      repetitionsType: 'string',
    };

    postEntry(entryToCreate)
      .then((res) => {
        putStatement(currentStatement.id, true)
          .then((res) => {
            console.log('putStatement', res);
            statusResponse = 'success';
            // toastContent = `${res.data.totalReconciliated} de ${res.data.totalStatements} lançamentos conciliados.`;
          })
          .catch((err) => {
            statusResponse = 'warning';
            console.log('post err', err);
            toastContent = `O total conciliado não foi atualizado corretamente.`;
          })
          .finally(() => {
            toastNotification(statusResponse, toastContent);
            mutate('statements');
          });
        console.log('res post entry: ', res);
        mutate('entriesFilteredByDate');
        mutate('entriesReconciliation');
        mutate('bankBalances');
        statusResponse = 'success';
        toastContent = 'Lançamento salvo com sucesso.';
        onClose();
        dispatch(reconciliationValuesActions.setEntryList([]));
        dispatch(reconciliationValuesActions.setStatementList([]));
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
      });
  };

  const onClose = () => {
    reset();
    setIsOpenAccordion(false);
    setIsShown(false);
    setIsDisabled(false);
    setIsLoading(false);
  };

  const openAdvancedOptions = () => {
    setIsOpenAccordion((prevState) => !prevState);
  };

  const handleInput = (event, type) => {
    const floatInterest = Number(
      `${watch('interestValue').slice(0, -3).replace('.', '')}.${watch(
        'interestValue'
      ).slice(-2)}`
    );

    const floatDiscount = Number(
      `${watch('discountValue').slice(0, -3).replace('.', '')}.${watch(
        'interestValue'
      ).slice(-2)}`
    );

    const floatFine = Number(
      `${watch('fineValue').slice(0, -3).replace('.', '')}.${watch(
        'interestValue'
      ).slice(-2)}`
    );

    let inputVal = event.target.value.replace(/\D/g, '');
    let value = journalEntry.value;

    if (inputVal === '') {
      setValue(type, '');
      return;
    }

    inputVal = inputVal.padStart(3, '0');

    const wholeNum = inputVal.slice(0, -2);
    const decimal = inputVal.slice(-2);
    const floatValue = Number(`${wholeNum}.${decimal}`);
    const formattedWholeNum = Number(wholeNum).toLocaleString();
    const formattedValue = `${formattedWholeNum},${decimal}`;

    console.log(formattedValue);

    if (type === 'interestValue') {
      let finalValue = value - floatValue - floatFine + floatDiscount;
      // let interestPercent = (floatValue / value) * 100;
      // console.log('interestPercent', interestPercent);
      console.log('finalValue', finalValue.toLocaleString());
      setValue('interestValue', formattedValue);
      setValue(
        'value',
        new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
          finalValue
        )
      );
      // setValue('interestValuePercent', interestPercent);
    }
    if (type === 'discountValue') {
      let finalValue = value - floatInterest - floatFine + floatValue;
      console.log('finalValue', finalValue.toLocaleString());
      setValue('discountValue', formattedValue);
      setValue(
        'value',
        new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
          finalValue
        )
      );
    }
    if (type === 'fineValue') {
      let finalValue = value - floatInterest - floatValue + floatDiscount;

      console.log('finalValue', finalValue.toLocaleString());
      setValue('fineValue', formattedValue);
      setValue(
        'value',
        new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
          finalValue
        )
      );
    }

    // Convert the formatted value to a number with two decimal places
    const numericValue = Number(
      formattedValue.replace(/\./g, '').replace(',', '.')
    );
  };

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={() => buildFormHeader()}
      footer={buildFormFooter}
      // shouldCloseOnOverlayClick={formState.isDirty ? onOverlayClick() : true}
      shouldCloseOnEscapePress={!formState.isDirty}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div className={styles.dialogWrapper}>
        <form id='entry-statement-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField className={styles.formArea}>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={6} lg={4}>
                  <TextInputField
                    {...register('dateCompetencia')}
                    label='Data de competência'
                    id='dateCompetencia'
                    name='dateCompetencia'
                    type='date'
                    // onChange={(e) => {
                    //   setValue('dateVencimento', e.target.value);
                    // }}
                    // defaultValue={convertDate(
                    //   journalEntry?.dateVencimento.substring(0, 10)
                    // )}
                    defaultValue={journalEntry?.dateVencimento}
                  />
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <TextInputField
                    {...register('dateVencimento')}
                    label='Data de vencimento'
                    id='dateVencimento'
                    name='dateVencimento'
                    type='date'
                    disabled
                    value={journalEntry?.dateVencimento}
                    // onChange={(e) => {
                    //   setValue('dateVencimento', e.target.value);
                    // }}
                    // defaultValue={convertDate(
                    //   journalEntry?.dateVencimento.substring(0, 10)
                    // )}
                  />
                </Col>

                <Col sm={12} md={12} lg={4}>
                  <SelectField
                    {...register('bankAccountId')}
                    label='Conta'
                    disabled
                    value={journalEntry?.bankAccountId}
                    onChange={(e) => setValue('bankAccountId', e.target.value)}
                  >
                    <option value={undefined}></option>
                    {!isLoadingBanks &&
                      banks.data
                        .sort((a, b) => {
                          const nameA = a.name;
                          const nameB = b.name;

                          if (nameA < nameB) {
                            return -1;
                          }

                          if (nameA > nameB) {
                            return 1;
                          }

                          return 0;
                        })
                        .map((bank) => {
                          return (
                            <option key={bank.id} value={bank.id}>
                              {bank.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <SelectField
                    {...register('categoryId')}
                    label='Categoria'
                    defaultValue={undefined}
                    onChange={(e) => setValue('categoryId', e.target.value)}
                  >
                    <option value={undefined}></option>
                    {!isLoadingCategories &&
                      categories.data
                        .filter((category) => {
                          return category.isInput === journalEntry?.isInput;
                        })
                        .sort((a, b) => {
                          const nameA = a.name;
                          const nameB = b.name;

                          if (nameA < nameB) {
                            return -1;
                          }

                          if (nameA > nameB) {
                            return 1;
                          }

                          return 0;
                        })
                        .map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('description')}
                    label='Descrição'
                    id='description'
                    name='description'
                    placeholder=''
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={10} sm={11} md={11} lg={11}>
                  <TextInputField
                    {...register('value', {
                      required: true,
                      // min: 0,
                      // valueAsNumber: true,
                    })}
                    value={journalEntry?.value.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                    })}
                    label='Valor (R$)'
                    type='text'
                    // min='0,00'
                    id='value'
                    name='value'
                    placeholder=''
                    disabled
                    // step='0.01'
                    onChange={(e) => handleInput(e, 'value')}
                  />
                </Col>
                <Col xs={2} sm={1} md={1} lg={1}>
                  <Heading size={400} marginBottom={16}>
                    Pago
                  </Heading>
                  <Switch {...register('statusPayment')} checked={true} />
                </Col>
              </Row>
              <Pane display='flex' flexWrap='nowrap'>
                <DividerComponent padding='0px' color='#e8e8e8' />
                {isOpenAccordion ? (
                  <IconButton
                    icon={ChevronUpIcon}
                    type='button'
                    onClick={openAdvancedOptions}
                  />
                ) : (
                  <IconButton
                    icon={ChevronDownIcon}
                    type='button'
                    onClick={openAdvancedOptions}
                  />
                )}
              </Pane>
              <div
                style={{
                  display: `${isOpenAccordion ? 'initial' : 'none'}`,
                }}
              >
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <TextInputField
                      {...register('discountValue')}
                      label='Desconto'
                      type='text'
                      defaultValue='0,00'
                      id='discountValue'
                      name='discountValue'
                      placeholder=''
                      disabled={!watch('value')}
                      onChange={(e) => {
                        handleInput(e, 'discountValue');
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <TextInputField
                      {...register('fineValue')}
                      label='Multa'
                      type='text'
                      defaultValue='0,00'
                      id='fineValue'
                      name='fineValue'
                      placeholder=''
                      disabled={!watch('value')}
                      onChange={(e) => handleInput(e, 'fineValue')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <TextInputField
                      {...register('interestValue')}
                      label='Juros'
                      type='text'
                      defaultValue='0,00'
                      id='interestValue'
                      name='interestValue'
                      placeholder=''
                      // disabled={!watch('value')}
                      onChange={(val) => {
                        // let v =
                        //   (val.target.value /
                        //     Number(
                        //       `${watch('value')
                        //         .slice(0, -3)
                        //         .replace('.', '')}.${getValues('value').slice(
                        //         -2
                        //       )}`
                        //     )) *
                        //   100;
                        // setValue(
                        //   'interestValuePercent',
                        //   v.toLocaleString('pt-BR', {
                        //     maximumFractionDigits: 2,
                        //   })
                        // );
                        handleInput(val, 'interestValue');
                      }}
                    />
                  </Col>
                  {/* <Col xs={3} sm={3} md={4}>
                    <TextInputField
                      {...register('interestValuePercent')}
                      label='%'
                      type='number'
                      min='0'
                      step='0.01'
                      id='interestValuePercent'
                      name='interestValuePercent'
                      value={watch('interestValuePercent')}
                      placeholder=''
                      disabled={!watch('value')}
                      onChange={(val) => {
                        let v =
                          (val.target.value / 100) *
                          Number(
                            `${watch('value')
                              .slice(0, -3)
                              .replace('.', '')}.${getValues('value').slice(
                              -2
                            )}`
                          );
                        setValue(
                          'interestValue',
                          v.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
                        );
                        setValue('interestValuePercent', val.target.value);
                      }}
                    />
                  </Col> */}
                </Row>
              </div>
            </Container>
          </FormField>
        </form>
      </div>

      {/* <div> Rodapé </div> */}
    </Dialog>
  );
}

export default EntryFormStatementComponent;
